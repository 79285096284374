import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Carousel from "react-elastic-carousel";
class MaintancesPhotos extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      photos: [],
    };
  }

  componentDidMount() {
    this.loadPhotos();
  }

  loadPhotos = async () => {
    this.setState({ loading: true });
    await this.setState({ photos: this.props.values, loading: false });
  };
  render() {
    const { photos } = this.state;
    return (
      <Carousel>
        {photos.map((img) => (
          <img src={img.link} alt={img.name} width="600" height="450"/>
        ))}
      </Carousel>
    );
  }
}
export default withRouter(MaintancesPhotos);
