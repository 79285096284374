const INITIAL_STATE = {
  editData: false,
  loading: false,
  disabled: false,

  cpfCnpj: "",
  name: "",
  tradingName: "",
  email: "",
  zipcode: "",
  street: "",
  streetNumber: "",
  neighborhood: "",
  city: "",
  state: "",

  phone: "",

  phoneId: null,

  logo: {},
  logoURL: null,
  logoToDelete: {},

  images: Array(5)
    .fill()
    .map((value, i) => {
      return { id: i };
    }),
  imagesToDelete: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "INITIAL_STATE":
      URL.revokeObjectURL(state.logo);

      state.images.forEach((c) => URL.revokeObjectURL(c));

      state.imagesToDelete.forEach((c) => URL.revokeObjectURL(c));

      return {
        ...INITIAL_STATE,
        images: Array(5)
          .fill()
          .map((value, i) => {
            return { id: i };
          }),
        logo: {},
      };

    case "HANDLE_CHANGE":
      return { ...state, [action.payloadType]: action.payload };

    case "FETCH_PROVIDER_PERSONAL_DATA_STARTED":
      return { ...state, loading: true };

    case "FETCH_PROVIDER_PERSONAL_DATA_SUCCESS":
      let phone;
      if (action.payload.Phones[0]?.ddd && action.payload.Phones[0]?.number) {
        phone =
          action.payload.Phones[0]?.ddd + action.payload.Phones[0]?.number;
      } else phone = "";

      return {
        ...state,
        ...action.payload,
        city: action.payload.city,
        phone,
        phoneId: action.payload.Phones[0]?.id,
        loading: false,
        disabled: true,
      };

    case "FETCH_CEP_SUCCESS":
      return { ...state, ...action.payload };

    case "UPDATE_PROVIDER_DATA_STARTED":
      return { ...state, loading: true };

    case "UPDATE_PROVIDER_DATA_FAILURE":
      return { ...state, loading: false };

    case "UPDATE_IMAGES_STARTED":
      return { ...state, loading: true };

    case "UPDATE_IMAGES_FAILURE":
      return { ...state, loading: false };

    case "UPLOAD_LOGO_SUCCESS":
      return { ...state, logoURL: action.payload };

    case "UPLOAD_IMAGES_SUCCESS":
      const newImages = [...state.images.filter(img => !img.URL && img.uploaded), ...action.payload]
      return { ...state, images: newImages };

    case "DELETE_IMAGES_SUCCESS":
      return { ...state, imagesToDelete: [] };
    case "UPDATE_PROVIDER_IMAGES_SUCCESS":
      return { ...state, loading: false };
    default:
      return state;
  }
};
