import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'react-bootstrap'
const LoadingSpinner = (props) => {
  return(
    <>
    {props.loading && 
      <div className="div-loading">
        <Spinner animation="border" role="status" variant="success" />
        <FontAwesomeIcon icon={props.icon} />
      </div>
    }
    </>
  )
}

export default LoadingSpinner