import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { faCar, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css"
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import routes from "../routes";
import { getHistoricMaintances, getVehicles } from '../../../../utils/request'

export default function SideNavClient() {
  const [expanded, setExpanded] = useState(false)
  const [pathSelected, setPathSelected] = useState(localStorage.getItem('selectedPath'))
  const [evaluated, setEvaluated] = useState(0)
  const userId = localStorage.getItem("ID_USUARIO")

  useEffect(() => {
    const getCount = async () => {
      const res = await getVehicles(userId)
      const vehicles = res.payload
  
      let count = 0
      for (let i = 0; i < vehicles.length; i++) {
        let maintances = await getHistoricMaintances(vehicles[i].licensePlate)
        count += maintances.payload.evaluatedCount
      }
  
      setEvaluated(count === undefined ? 0 : count)
    }
    getCount()
  }, [userId])

  

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/client") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <div
      style={{
        marginLeft: expanded ? 155 : 0,
        padding: "0px 40px 0px 50px"
      }}
    >
      <div className='main-panel'>
        <Switch>{getRoutes(routes)}</Switch>
      </div>
      <Route render={({ location, history }) => (
        <React.Fragment >
          <SideNav
            expanded={expanded}
            onToggle={expanded => {
              if (expanded) {
                localStorage.setItem("EXPANDED", 2);
              } else {
                localStorage.setItem("EXPANDED", 1);
              }
              setExpanded(expanded);
            }}
            onSelect={selected => {
              if (selected !== "fixed") {
                const to = '/' + selected;
                if (location.pathname !== to) {
                  localStorage.setItem("selectedPath", selected)
                  setPathSelected(selected)
                  history.push(to);
                }
              }
            }}
          >
          <SideNav.Nav defaultSelected={pathSelected} className="sidenav-client">
            <NavItem eventKey="fixed">
              <NavIcon>
                <SideNav.Toggle
                  id="side-toggle"
                  onClick={() => {
                    if (expanded) {
                      setExpanded(false);
                      localStorage.setItem("EXPANDED", 1);
                    } else {
                      setExpanded(true);
                      localStorage.setItem("EXPANDED", 2);
                    }
                  }}
                />
              </NavIcon>
            </NavItem>

            <NavItem eventKey="client/dashboard" title="Visão Geral">
              <NavIcon>
                <i className="fa fa-fw fa-home iconeMenu" />
              </NavIcon>
              <NavText style={{ color: "white" }}>Visão Geral</NavText>
            </NavItem>

            <NavItem eventKey="client/maintances" title="Minhas Manutenções">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faTools}
                  className="iconeMenu"
                  style={{ marginLeft: !expanded && evaluated > 0 ? '19px' : ''}}
                  aria-hidden="false"
                />
                <span id='badge-manutencao' style={{ display: expanded || evaluated === 0 ? 'none' : ''}}>
                  {evaluated}
                </span>
              </NavIcon>
              <NavText style={{ color: "white" }}>Minhas Manutenções
                <span id='badge-manutencao' style={{ display: evaluated === 0 ? 'none' : ''}}>
                  {evaluated}
                </span>
              </NavText>
            </NavItem>

              <NavItem eventKey="client/vehicles" title="Meus Veículos">
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faCar}
                    className="iconeMenu"
                    aria-hidden="false"
                  />
                </NavIcon>
                <NavText style={{ color: "white" }}>Meus Veículos</NavText>
              </NavItem>
            </SideNav.Nav>
          </SideNav>
        </React.Fragment>
      )}
      />
    </div>
  );
}
