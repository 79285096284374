import React from "react";
import FormSignUp from "./FormSignUp";
import services from "../../services/api";
import { onlyNumbers } from "../../utils/normalize";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { useState } from "react";

const BundleSignUp = ({ history }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async values => {
    const { email, password, name, phone, licensePlate, model, brand } = values

    setLoading(true)

    try {

      const res = await services.api.post(services.config.endpoint + "users", {
        name,
        email,
        password,
        phone: onlyNumbers(phone)
      });
  
      await services.api.post(services.config.endpoint + "vehicles", {
        licensePlate,
        brand,
        model,
        userId: res.data.id
      });

      localStorage.setItem("NOME_USUARIO", res.data.name);
      localStorage.setItem("ID_USUARIO", res.data.id);
      
    } catch(err) {
      console.log(err);
      return toastr.warning('Ocorreu um erro ao salvar o usuário. Por favor, tente novamente')

    } finally{
      setLoading(false)
    }


    toastr.success("Cadastro realizado com sucesso");

    setTimeout(() => history.push("/client/dashboard") , 1000);
  }

  return <FormSignUp onSubmit={handleSubmit} loading={loading} />;
}

export default withRouter(BundleSignUp);
