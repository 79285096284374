import { onlyNumbers } from "./normalize"

const validate = values => {
    const errors = {}

    // PersonalData
    if (!values.name) {
        errors.name = 'Obrigatório!'
    } else if(values.name.trim().length<3){
        errors.name = "Nome inválido"
    }

    if (!values.phone) {
        errors.phone = 'Obrigatório!'
    } else if (onlyNumbers(values.phone).length < 10) {
        errors.phone = 'Número pequeno para um Telefone'
    }

    if (!values.email) {
        errors.email = 'Obrigatório!'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'E-mail Invalido'
    } else if (values.emailDuplicado) {
        errors.email = 'E-mail já cadastrado'
    }

    if (!values.password) {
        errors.password = 'Obrigatório!'
    } else if (values.password.length < 6) {
        errors.password = 'Senha menor que 6 carácteres!'
    }

    if(!values.password2){
        errors.password2 = 'Obrigatório!'
    } else if(values.password2 && values.password2 !== values.password){
          errors.password2 = 'As Senhas não coincídem'
    }


    // VehicleData
    if(!values.brand){
        errors.brand = 'Obrigatório!'
    }
    if(!values.model){
        errors.model = 'Obrigatório!'
    }
    if(!values.licensePlate){
        errors.licensePlate = 'Obrigatório!'
    } else if(values.licensePlate.length < 7){
        errors.licensePlate = 'Placa incompleta'
    } else if(!!values.LicensePlateExist){
        errors.licensePlate = `Placa já está cadastrada ${window.location.href.includes('newvehicle') ? 'para você ou ' : ''}para outro usuário`
    }

    return errors
}

export default validate