import React from 'react';
import './styles.css';

import { isMobile } from '../../utils/validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LoginFooter({ active }) {

  function mobileFooter() {
    return (
      active ? null :
        <div className="login-footer-mobile-container">
          <CopyRight />
        </div>
    );
  };

  function desktopFooter() {
    return (
      <div className="login-footer-desktop-container">
        <CopyRight />
      </div>
    );
  };

  const CopyRight = () => <span><FontAwesomeIcon icon="copyright" /> 2020 Work Inteligência</span>

  return isMobile() ? mobileFooter() : desktopFooter();
};