import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const List = props => {
    const { vehicles, handleTrash, loading } = props
    return (
        <>
            {vehicles.length > 0 ? vehicles.map(v => {
                let color;

                do {
                    color = "#" + ((1 << 24) * Math.random() | 0).toString(16)
                } while (color === '#fcfcfc' || color === '#ffffff');
                return (
                    <div key={v.id} style={{ display: 'flex', padding: '10px', boxShadow: '1px 1px 5px 0px #000000', marginBottom: '15px', width: '98%', borderRadius: '5px' }}>
                        <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'center', float: 'left' }}>
                            <div id="div-circle" style={{ backgroundColor: `${color}` }}>
                                <span id="span-circle"><strong>{v.model.charAt([0])}</strong></span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <span><strong>{v.licensePlate}</strong></span>
                            <span>{v.model}</span>
                        </div>
                        <div style={{ marginLeft: '10px', float: 'right' }}>
                            <FontAwesomeIcon onClick={() => handleTrash(v)} icon={faTrash} className="fa fa-trash" style={{ color: 'red', width: '30px', height: '100%' }} />
                        </div>
                    </div>
                )
            }
            ) :
                <div style={{ display: 'block' }}>
                    {loading === false ?
                        <h5><center>Nenhum veículo cadastrado</center></h5>
                        : ''
                    }
                </div>

            }
        </>
    )
}

export default memo(List)