import React from 'react';
import { reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import MyConfig from './MyConfig'
import { putMyConfig } from '../../../../../utils/request'
import { onlyNumbers } from '../../../../../utils/normalize'
import { useState } from 'react';

function Index({ history }) {
  const [loading, setLoading] = useState(false)

  async function submit(values) {
    if(!values.branchId) return toastr.warning('Por favor, selecione o ramo de atuação')
    if (parseInt(values.branchId) === 1) values.services = []
    if (parseInt(values.branchId) !== 1 && !values.services.length) return toastr.warning('Por favor, selecione ao menos 1 serviço para o seu ramo de atuação')
    
    if(values.openAt && values.closesAt) {

      const validOpen = onlyNumbers(values.openAt)
      const validCloses = onlyNumbers(values.closesAt)
      if (
        !validOpen ||
        validOpen.length < 4 ||
        !validCloses ||
        validCloses.length < 4
      ) {
        return toastr.warning('Informe seu Horário de Funcionamento corretamente')
      }
  
      if (
        validOpen.substring(0, 2) >= 24 ||
        validOpen.substring(0, 2) < 0 ||
        validOpen.substring(2, 4) < 0 ||
        validOpen.substring(2, 4) > 59 ||
        validCloses.substring(0, 2) >= 24 ||
        validCloses.substring(0, 2) < 0 ||
        validCloses.substring(2, 4) < 0 ||
        validCloses.substring(2, 4) > 59
      ) return toastr.warning('Informe seu Horário de Funcionamento corretamente')
  
      if (validOpen > validCloses) return toastr.warning('Por favor, insira o horário corretamente.')
    }
    

    setLoading(true)

    const response = await putMyConfig(values)

    setLoading(false)

    if (response.type === 'PUT_MYCONFIG_SUCCESS') {
      toastr.success('Dados atualizados com sucesso')

      localStorage.setItem('selectedPath', 'provider/home')

      history.push('home')
    }
    else return toastr.error('Ocorreu algum problema ao tentar atualizar os dados', response.message)
  }
  return (
    <MyConfig
      onSubmit={submit}
      initialValues={{ services: [] }}
      history={history}
      loading={loading}
      setLoading={setLoading}
    />
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'myConfigForm'
})(withRouter(Index))