import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb'
import CardForm from '../../../../components/CardForm'
import AlertModal from '../../../../../components/alertModal/AlertModal'
import { useDispatch, useSelector } from 'react-redux'
import { Field, change, reduxForm } from 'redux-form'
import './styles.css'
import { getSegments, getBranchs, getServiceTypes, getProvider } from '../../../../../utils/request'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { faCog } from '@fortawesome/free-solid-svg-icons';

import Segments from './Segments'
import Infos from './Infos'
import Services from './Services'

function MyConfig({ handleSubmit, history, loading, setLoading }) {
  const [showSeg, setShowSeg] = useState(true)
  const [showInfos, setShowInfos] = useState(false)
  const [showBranches, setShowBranches] = useState(false)
  const [showServ, setShowServ] = useState(false)
  const [segs, setSegs] = useState([])
  const [branches, setBranches] = useState([])
  const [servicesDB, setServicesDB] = useState([])
  const [edit, setEdit] = useState(false)
  const [modal, setModal] = useState(false)

  const dispatch = useDispatch()
  const { services, segments, branchId } = useSelector(state => state.form.myConfigForm.values)
  const companyId = localStorage.getItem('ID_EMPRESA')
  const sourceId = localStorage.getItem('SOURCE_ID')

  useEffect(() => {
    loadPage()
  }, [])

  async function loadPage() {
    setLoading(true)
    const respSeg = await getSegments().then(resp => {

      if (resp.type === 'LOAD_SEGMENTS_FAILURE') return []

      return resp.payload.map(item => {
        return { ...item, checked: false }
      })
    }
    )
    setSegs(respSeg)

    await getBranchs().then(resp => setBranches(resp.payload))

    await getServiceTypes().then(resp => setServicesDB(resp.payload))

    const resp = await getProvider(sourceId, companyId)
    
    if(resp.payload.length <= 0) return

    const { Segments, openAt, closesAt, site, branchId, Services } = resp.payload

    dispatch([
      change('myConfigForm', 'segments', Segments),
      change('myConfigForm', 'companyId', companyId),
      change('myConfigForm', 'openAt', openAt),
      change('myConfigForm', 'closesAt', closesAt),
      change('myConfigForm', 'site', site),
      change('myConfigForm', 'branchId', branchId),
      change('myConfigForm', 'services', Services),
      change('myConfigForm', 'sourceId', sourceId)
    ])
        
    setSegs(respSeg.map(s => {
      for (let i = 0; i < Segments.length; i++) {
        if (s.id == Segments[i].id) return { ...Segments[i], checked: true }
      }
      return s
    }))

    setLoading(false)
  }

  function serviceSelected(id) {
    for (let i = 0; i < services.length; i++) {
      if (services[i].id == id) return
    }

    servicesDB.forEach(item => {
      if (item.id == id) {
        
        return dispatch( change( 'myConfigForm', 'services', [...services, item].sort((a, b) => a.id - b.id) ) )
      }
    })

  }

  function deleteServ(id) {
    if (!edit) return

    dispatch( change( 'myConfigForm', 'services', services.filter( item => item.id != id ) ) )
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <span id='required-fields-span'>* Campos Obrigatórios</span>
        <BreadCrumb
          path={['home', null]}
          data={['Início', 'Minhas Configurações']}
        />
        <CardForm
          show={showSeg}
          title="Meus Segmentos"
          onClick={() => setShowSeg(!showSeg)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Segments
              data={segs || []}
              segments={segments || []}
              edit={edit}
              onClick={item => {
                const segmt = segs.map(seg => seg.id === item.id ? item : seg)
                setSegs(segmt)
                dispatch(change('myConfigForm', 'segments', segmt.filter(s => s.checked === true)))
              }}
              setData={data => setSegs(data)}
              form="myConfigForm"
            />
            <button
              type="button"
              className="myconfig-temp-buttons btn-mycfg-success"
              onClick={() => {
                setShowSeg(false)
                setShowInfos(true)
                setShowBranches(false)
                setShowServ(false)
              }}
              style={{ alignSelf: 'flex-end' }}
            >
              Prosseguir
        </button>
          </div>
        </CardForm>

        <CardForm
          show={showInfos}
          title="Meus Horários e Outras Informações"
          onClick={() => setShowInfos(!showInfos)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Infos
              form="myConfigForm"
              edit={edit}
            />
            <button
              type="button"
              className="myconfig-temp-buttons btn-mycfg-success"
              onClick={() => {
                setShowSeg(false)
                setShowInfos(false)
                setShowBranches(true)
                setShowServ(false)
              }}
              style={{ alignSelf: 'flex-end' }}
            >
              Prosseguir
        </button>
          </div>
        </CardForm>

        <CardForm
          show={showBranches}
          title="Meu Ramo de Atuação"
          onClick={() => setShowBranches(!showBranches)}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Field
                component="select"
                disabled={!edit}
                className="form-control foco-input"
                name="branchId"
                style={{ width: 150, cursor: !edit ? 'not-allowed' : 'pointer' }}
                onChange={event => {
                  if (event.target.value === "1") {
                    dispatch(change('myConfigForm', 'services', []))
                  }
                }}
              >
                <option value="">Selecione</option>
                {
                  branches.map(item => (
                    <option key={item.id} value={item.id}>{item.description}</option>
                  ))
                }
              </Field>
            <button
              type="button"
              className="myconfig-temp-buttons btn-mycfg-success"
              onClick={() => {
                setShowSeg(false)
                setShowInfos(false)
                setShowBranches(false)
                setShowServ(true)
              }}
              style={{ alignSelf: 'flex-end' }}
            >
              Prosseguir
        </button>
          </div>
        </CardForm>

        {parseInt(branchId) !== 1 && !!branchId &&
          <CardForm
            show={showServ}
            title="Meus Serviços Realizados"
            onClick={() => setShowServ(!showServ)}
          >
            <Services
              servicesDB={servicesDB.filter(item => {
                for (let i = 0; i < services.length; i++) {
                  if (item.id == services[i].id) return false
                }
                return true
              })}
              edit={edit}
              onAdd={serviceSelected}
              onRemove={deleteServ}
              services={services}
            />
          </CardForm>
        }

        <div className="myconfig-footer">
          <button
            type="button"
            className="myconfig-temp-buttons btn-mycfg-danger"
            onClick={() => setModal(true)}
          >Voltar</button>

          <button
            type="button"
            disabled={edit}
            title={edit ?
              'Você já pode editar suas Configurações'
              :
              'Clique para poder editar suas Configurações'}
            onClick={() => setEdit(true)}
            className="myconfig-temp-buttons btn-mycfg-info"
          >Editar Configurações</button>

          <button
            disabled={!edit}
            title={edit ?
              'Clique para salvar suas alterações'
              :
              'Clique no botão "Editar Configurações"'}
            type="submit"
            className="myconfig-temp-buttons btn-mycfg-success"
          >Salvar</button>

        </div>
      </form>

      <AlertModal
        show={modal}
        animation={true}
        message="Deseja realmente cancelar as alterações? Esta ação não salvará as informações alterada em tela."
        onHide={() => setModal(false)}
        onCancel={() => setModal(false)}
        onSubmit={() => {
          setModal(false)
          history.push('/provider/home')
        }}
      />

      <LoadingSpinner loading={loading} icon={faCog}/>
    </>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'myConfigForm'
})(MyConfig)