import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb';
import { DashBoardCard, CardStar } from './DashBoardCard';
import { useDispatch } from 'react-redux';
import './styles.css';
import { faThumbsUp, faThumbsDown, faWrench, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';
import { getCountersEvaluationDash } from '../../../../../utils/request';
import { handleChange } from '../Evaluated/redux/actions/evaluated';
import { toastr } from 'react-redux-toastr';

export default function DashBoardProvider({ history }) {
    const [counters, setCounters] = useState({
        amountRecommendation: '0', //Quantidade de Clientes que indicariam
        amountNotRecommendation: '0', //Quantidade de Clientes que não indicariam
        ratingRecommendation: '0', //Porcentagem de Cliente que indicariam
        ratingNotRecommendation: '0', //Porcentagem de Cliente que não indicariam
        ratingProductService: '0', //Média de estrelas de Produto/Serviço
        ratingPrice: '0', //Média de estrelas de Preço
    });

    const {
        amountRecommendation,
        amountNotRecommendation,
        ratingRecommendation,
        ratingNotRecommendation,
        ratingProductService,
        ratingPrice
    } = counters;

    const dispatch = useDispatch();

    useEffect(() => {
        async function getCounter() {
            const counter = await getCountersEvaluationDash(localStorage.getItem('CNPJ'), localStorage.getItem('SOURCE_ID'));

            if (counter.type === 'LOAD_COUNTER_FAILURE') return toastr.warning(counter.message);

            const { payload } = counter;

            payload.ratingRecommendation = formatValueAfterDot(payload.ratingRecommendation);
            payload.ratingNotRecommendation = formatValueAfterDot(payload.ratingNotRecommendation);
            payload.ratingProductService = formatValueAfterDot(payload.ratingProductService);
            payload.ratingPrice = formatValueAfterDot(payload.ratingPrice);

            setCounters(payload);
        };

        getCounter();
    }, []);

    function formatValueAfterDot(value) {
        if (!value) return value

        if (value.split('.')[1] <= 0) return value.split('.')[0]
        return value
    };

    async function handleEvaluated(value, type) {
        await dispatch(handleChange(value, type))
        history.push('evaluated')
    };

    return (
        <>
            <BreadCrumb data={['Início']} path={[null]} />
            <br />
            <Col className='admin-main-content-cards'>
                <DashBoardCard
                    onClick={() => handleEvaluated('sim', 'indica')}
                    title='Clientes que indicariam'
                    counter={amountRecommendation}
                    icon={faThumbsUp}
                    circleColor='#28a745'
                    fontColor='#28a745'
                    percent={String(ratingRecommendation).replace('.', ',')}
                    message='dos clientes avaliados lhe indicariam'
                />

                <DashBoardCard
                    onClick={() => handleEvaluated('não', 'indica')}
                    title='Clientes que não indicariam'
                    counter={amountNotRecommendation}
                    icon={faThumbsDown}
                    circleColor='#ff0000'
                    fontColor='#ff0000'
                    percent={String(ratingNotRecommendation).replace('.', ',')}
                    message='dos clientes avaliados não lhe indicariam'
                />

                <CardStar
                    onClick={() => history.push('evaluated')}
                    title='Produto/Serviço'
                    counter={String(ratingProductService).replace('.', ',')}
                    icon={faWrench}
                    circleColor='#ffa834'
                    Value_Rating={ratingProductService}
                />

                <CardStar
                    onClick={() => history.push('evaluated')}
                    title='Preço'
                    counter={String(ratingPrice).replace('.', ',')}
                    icon={faDollarSign}
                    circleColor='#673ab7'
                    Value_Rating={ratingPrice}
                />
            </Col>
        </>
    );
};