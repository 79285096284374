import React from "react";
import { Col } from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faWrench, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import "../../mobile.css";
import { getVehicles, countMaintances, countWorkshop } from '../../../utils/request'

class Dashboard extends React.Component {
  state = {
    qtdManutencao: 0,
    qtdOficina: 0,
    vehicles: [],
    loading: true
  };

  componentDidMount = async () => {
    await this.loadVehicles()
    this.countMaintancesAndWorkshop()
  }

  loadVehicles = async () => {
    const response = await getVehicles(localStorage.getItem('ID_USUARIO'))
    await this.setState({ vehicles: response.payload })
  }

  countMaintancesAndWorkshop = async () => {
    const { vehicles } = this.state
    let qtdMaintances = 0
    let qtdWorkshop = 0
    for (let i = 0; i < vehicles.length; i++) {
      const maintances = await countMaintances(vehicles[i].licensePlate)
      const workshop = await countWorkshop(vehicles[i].licensePlate)
      qtdMaintances = qtdMaintances + maintances.payload
      qtdWorkshop = qtdWorkshop + workshop.payload
    }
    this.setState({ qtdManutencao: qtdMaintances, qtdOficina: qtdWorkshop, loading: false })
  }

  render() {
    const { qtdManutencao, qtdOficina, vehicles, loading } = this.state;
    return (
      <div style={{ marginTop: '10%' }}>
        <Col xs={12} sm={12} md={12} lg={12} className="espacamentoCard" onClick={() => this.props.history.push("/client/maintances")}>
          {/* CARD MANUTENCOES */}
          <Button className="painelCardManutencoes">
            <Col md={2}>
              <FontAwesomeIcon
                icon={faWrench}
                className="iconedashboard"
                aria-hidden="false"
              />
            </Col>
            <Col md={10} className="textCard">
              <strong style={{ fontSize: "55px" }}>{loading ? <span className="fa fa-spinner fa-pulse fa-1x" /> : qtdManutencao}</strong>
              <div className="informacoesCard">
                <p>Manutenções</p>
              </div>
            </Col>
          </Button>
        </Col>

        {/* CARD OFICINAS */}
        <Col xs={12} sm={12} md={12} lg={12} className="espacamentoCard" >
          <Button className="painelCardOficinas">
            <Col md={2}>
              <FontAwesomeIcon
                icon={faToolbox}
                className="iconedashboard"
                aria-hidden="false"
              />
            </Col>
            <Col md={10} className="textCard">
              <strong style={{ fontSize: "55px" }}>{loading ? <span className="fa fa-spinner fa-pulse fa-1x" /> : qtdOficina}</strong>
              <div className="informacoesCard">
                <p>Oficinas</p>
              </div>
            </Col>
          </Button>
        </Col>

        {/* CARD VEICULOS */}
        <Col xs={12} sm={12} md={12} lg={12} className="espacamentoCard" onClick={() => this.props.history.push("/client/vehicles")}>
          <Button className="painelCardVeiculos">
            <Col md={2}>
              <FontAwesomeIcon
                icon={faCar}
                className="iconedashboard"
                aria-hidden="false"
              />
            </Col>
            <Col md={10} className="textCard" >
              <strong style={{ fontSize: "55px" }}>{vehicles.length ?? <span className="fa fa-spinner fa-pulse fa-1x" />}</strong>
              <div className="informacoesCard">
                <p>Veículos</p>
              </div>
            </Col>
          </Button>
        </Col>
      </div>
    );
  }
}

export default withRouter(Dashboard)
