import React from "react";
import DropPhotoContainer from "./DropPhotoContainer";
import { toastr } from "react-redux-toastr";
import { useState } from "react";
import AlertModal from "../../../../../components/alertModal/AlertModal";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, addImagesToDelete } from "./redux/action";
import crypto from "crypto";

export default function MyPhotos() {
  const [showRemoveLogoModal, setShowRemoveLogoModal] = useState(false);
  const [showRemovePhotosModal, setShowRemovePhotosModal] = useState(false);
  const [photoIndexToRemove, setPhotoIndexToRemove] = useState(null);

  const { logo, images, imagesToDelete } = useSelector(
    (state) => state.providerPersonalData
  );
  const dispatch = useDispatch();

  /// VALIDAÇÕES DE FOTO ( QUANTIDADE E TAMANHO )
  const handleValidateFiles = async (files, quantity, width, height) => {
    if (files.length > quantity) {
      toastr.warning(
        `Selecione somente ${quantity} ${quantity === 1 ? "imagem" : "imagens"}`
      );
      return false;
    }

    for (let i = 0; i < quantity; i++) {
      if (files[i].size > 5000000) {
        toastr.warning(
          "Por favor, adicione uma imagem menor, o limite máximo é de 5MB."
        );

        return false;
      }

      let validateImage = await addImageProcess(
        URL.createObjectURL(files[i])
      ).then((imgHeight, imgWidth) => {
        if (imgWidth < width || imgHeight < height) {
          toastr.warning(
            `Por favor, adicione uma imagem com o tamanho minimo de ${width}x${height}px.`
          );

          return false;
        }

        return true;
      });

      URL.revokeObjectURL(files[i]);

      if (!validateImage) return false;

      return true;
    }
  };

  const addImageProcess = (objectURL) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img.height, img.width);
      img.onerror = reject;
      img.src = objectURL;
    });
  };

  const createFileObject = (file, index) => ({
    id: index,
    file: file,
    name: `${crypto.randomBytes(16).toString("hex")}-${file.name}`,
    preview: URL.createObjectURL(file),
    uploaded: false,
  });

  const handleUploadLogo = async (files) => {
    let validateFiles = await handleValidateFiles(files, 1, 300, 150);

    if (!validateFiles) return;

    const file = createFileObject(files[0], 0);

    // CASO TENHA ALTERADO O LOGO, E NÃO REMOVIDO
    if (!!logo?.uploaded) {
      dispatch(handleChange(logo, "logoToDelete"));
    }
    dispatch(handleChange(file, "logo"));
  };

  const handleUploadPhotos = async (files, index) => {
    let validateFiles = await handleValidateFiles(files, 1, 800, 600);

    if (!validateFiles) return;
    const file = createFileObject(files[0], index);

    let newPhotos = [...images];

    if (index === images.length) {
      newPhotos.push(file);
    } else {
      const objectToModify = newPhotos[index];

      if (objectToModify.uploaded) {
        handlePhotosToDelete(objectToModify);
      }

      newPhotos[index] = file;
    }

    dispatch(handleChange(newPhotos, "images"));
  };

  const handlePhotosToDelete = (photo) => {
    const arrayToModify = [...imagesToDelete];

    arrayToModify.push(photo);

    dispatch(handleChange(arrayToModify, "imagesToDelete"));
  };

  const handleRemoveLogo = () => {
    if (!!logo?.uploaded) {
      dispatch(handleChange(logo, "logoToDelete"));
    }
    dispatch(handleChange({}, "logo"));
    dispatch(handleChange(null, "logoURL"));
    setShowRemoveLogoModal(false);
  };

  const handleRemovePhotos = () => {
    let newPhotos = [...images];

    if (newPhotos[photoIndexToRemove].uploaded) {
      dispatch(
        addImagesToDelete(newPhotos[photoIndexToRemove], imagesToDelete)
      );
    }

    newPhotos[photoIndexToRemove] = { id: photoIndexToRemove };

    dispatch(handleChange(newPhotos, "images"));
    setShowRemovePhotosModal(false);
  };

  const handleSetPhotoToRemove = (index) => {
    setPhotoIndexToRemove(index);
    setShowRemovePhotosModal(true);
  };

  const imagesArrayWithOutImages = new Array(5 - images.length).fill();

  return (
    <div>
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label>
          <strong>Logo: </strong>
        </label>
        <small>
          A imagem deverá ter um tamanho mínimo de{" "}
          <span style={{ textDecoration: "underline" }}>300x150px</span> e um
          tamanho máximo de 5 MB
        </small>
        <div style={{ width: "fit-content" }}>
          <DropPhotoContainer
            image={logo}
            style={{ width: "fit-content" }}
            onRemove={() => setShowRemoveLogoModal(true)}
            onUpload={handleUploadLogo}
          />
        </div>
      </div>

      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label>
          <strong>Fotos: </strong>
        </label>
        <small>
          As imagens deverão ter um tamanho mínimo de{" "}
          <span style={{ textDecoration: "underline" }}>800x600px</span> e um
          tamanho máximo de 5 MB
        </small>

        <div className="container-provider-photos">
          {images.map((photo, index) => (
            <div key={index}>
              <DropPhotoContainer
                image={photo}
                onRemove={() => handleSetPhotoToRemove(index)}
                onUpload={(files) => handleUploadPhotos(files, index)}
              />
            </div>
          ))}
          {imagesArrayWithOutImages.map((photo, index) => (
            <div key={index}>
              <DropPhotoContainer
                image={photo}
                onRemove={() => {}}
                onUpload={(files) => handleUploadPhotos(files, images.length)}
              />
            </div>
          ))}
        </div>
      </div>

      <AlertModal
        message="Deseja realmente excluir a foto?"
        show={showRemoveLogoModal}
        onCancel={() => setShowRemoveLogoModal(false)}
        onHide={() => setShowRemoveLogoModal(false)}
        onSubmit={handleRemoveLogo}
      />

      <AlertModal
        message="Deseja realmente excluir a foto?"
        show={showRemovePhotosModal}
        onCancel={() => setShowRemovePhotosModal(false)}
        onHide={() => setShowRemovePhotosModal(false)}
        onSubmit={handleRemovePhotos}
      />
    </div>
  );
}
