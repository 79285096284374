import React from 'react';
import { Col } from "react-bootstrap";
import '../../../Provider/pages/Evaluated/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rating from '@material-ui/lab/Rating';

export default function EvalutionModal({ wouldIndicate, ratingProductService, ratingPrice, comments }) {
  return (
    <div style={{ width: '550px' }}>
      <Col xs={12} sm={12} md={12} lg={12} id='colModalBody'>
        <Col id='cardEvaluation'>
          {
            wouldIndicate ?
              <>
                <FontAwesomeIcon icon="thumbs-up" style={{ fontSize: '60px', color: 'green' }} />
                <span id='spanIndica' style={{ color: 'green' }}>Indicaria</span>
              </>
              :
              <>
                <FontAwesomeIcon icon="thumbs-down" style={{ fontSize: '60px', color: 'red' }} />
                <span id='spanIndica' style={{ color: 'red' }}>Não indicaria</span>
              </>
          }
        </Col>
        <Col id='cardEvaluation'>
          <h2 id='spanNumber'>{ratingProductService}</h2>
          <Rating value={ratingProductService} readOnly
            name="Rating_Price" defaultValue={2} size="medium" />
          <span>Produto/Serviço</span>
        </Col>
        <Col id='cardEvaluation'>
          <h2 id='spanNumber'>{ratingPrice}</h2>
          <Rating value={ratingPrice} readOnly
            name="Rating_Price" defaultValue={2} size="medium" />
          <span>Preço</span>
        </Col>
      </Col>
      <Col md={12} style={{ marginTop: '15px' }}>
        <p>{comments}</p>
      </Col>
    </div>
  );
};