import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import CryptoJS from 'crypto-js'
import { reduxForm, Field } from 'redux-form'
import { useSelector } from "react-redux";

import InputPassword from "../../../../components/passwordInput/PasswordInput";
import Logo from '../../../../assets/img/sobre-rodas.png'
import { validatePasswords } from "../../../../utils/validation";
import { loginUser, updateUser } from '../../../../utils/request'
import validate from './validatePassword'
import { maxLength } from '../../../../utils/normalize'

import './styles.css'

let FirstClientAccess = ({ history, location }) => {

    const { syncErrors } = useSelector(state => state.form.firstClientAccess)
    const styles = syncErrors?.styles;

    const [id, setId] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [redirect, setRedirect] = useState('')

    useEffect(() => {
        checkURL();
    })

    const checkURL = async () => {
        const params = new URLSearchParams(location.search)
        let userId = params.get('id')
        let emailParams = params.get('email')
        let redirect = params.get('redirect')
        if (userId && emailParams) {
            localStorage.clear()
            while (userId.toString().includes(' ') || emailParams.toString().includes(' ')) {
                userId = userId.replace(' ', '+')
                emailParams = emailParams.replace(' ', '+')
            }
            let id = await CryptoJS.AES.decrypt(userId, "userId");
            let email = await CryptoJS.AES.decrypt(emailParams, "userEmail")
            id = id.toString(CryptoJS.enc.Utf8)
            email = email.toString(CryptoJS.enc.Utf8)
            if (id && email) {
                setEmail(email)
                setId(parseInt(id))
                setRedirect(redirect)
            }
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const validate = validatePasswords(password, password2)
        if (!validate) {
            return toastr.error('As senhas não são iguais. Valide as senhas e tente novamente.')
        }
        try {
            await updateUser(id, { "password": password })
            toastr.success("Senha salva com sucesso!");
        } catch (err) {
            console.log(err)
            return toastr.error('Houve um erro ao salvar senha, tente novamente!')
        }

        try {
            const resp = await loginUser(email, password);
            if (resp.message) {
                toastr.error(resp.message)
                return setTimeout(() => history.push(`/client`), 2000);
            }
            localStorage.setItem("NOME_USUARIO", resp.payload.name);
            localStorage.setItem("ID_USUARIO", resp.payload.id);
            if (redirect !== "") {
                setTimeout(() => history.push(`/client/${redirect}`), 2000);
            } else {
                setTimeout(() => history.push("/client/dashboard"), 2000);
            }
        } catch (err) {
            console.log(err);
            toastr.error('Erro ao tenta logar usuário')
        }
    }

    return (
        <>
            <div className="Login-container">
                <div className="Login">
                    <div className="Login-card">
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                            <img className="img" src={Logo} alt="Sobre Rodas" />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                                marginBottom: "20px",
                                alignItems: "center"
                            }}
                        >
                            <span style={{ marginTop: "10px" }}><strong>Cadastrar senha de acesso</strong></span>
                        </div>
                        <form>
                            <div>
                                <label>
                                    <strong>Senha</strong>
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                    style={styles}
                                    name="password"
                                    component={InputPassword}
                                    normalize={value => maxLength(value, 18)}
                                    onChange={event => setPassword(event.target.value.replace(/\s/g, ""))}
                                />
                            </div>
                            <div>
                                <label>
                                    <strong>Confirmar senha</strong>
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <Field
                                    style={styles}
                                    name="password2"
                                    component={InputPassword}
                                    normalize={value => maxLength(value, 18)}
                                    onChange={event => setPassword2(event.target.value.replace(/\s/g, ""))}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    textAlign: "center"
                                }}
                            >
                                <button
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={
                                        !password ||
                                        !password2 ||
                                        password.length < 6
                                    }
                                    id="sign-in"
                                >
                                    Cadastrar senha
                     </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

FirstClientAccess = reduxForm({
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    form: 'firstClientAccess',
    validate,
})(FirstClientAccess)

export default FirstClientAccess;
