import React, { Component } from "react";
import { toastr } from 'react-redux-toastr'

import './index.css'
import List from './list'
import AlertModal from '../../../components/alertModal/AlertModal'
import { getVehicles, deleteVehicle } from '../../../utils/request'

class Vehicles extends Component {

  state = {
    vehicles: [],
    vehicle: {},
    modal: false,
    loading: true
  }

  componentDidMount() {
    this.getVehicles(localStorage.getItem('ID_USUARIO'))
  }

  getVehicles = async (User_id) => {
    const response = await getVehicles(User_id)
    this.setState({ vehicles: response.payload || [], loading: false })
  }

  handleTrash = props => {
    this.setState({ modal: true, vehicle: props })
  }

  onHideModal = () => {
    this.setState({ modal: false, vehicle: {} })
  }

  handleSubmit = async () => {
    const { vehicle } = this.state
    if (!vehicle) return toastr.error('Houve alguma falha ao carregar os dados do veículo')

    const response = await deleteVehicle(vehicle.id)

    if (response.status) {
      toastr.success('Veículo excluído com sucesso!')
      this.getVehicles(localStorage.getItem('ID_USUARIO'))
      this.onHideModal()
    } else {
      toastr.error('Erro ao tentar excluir o veículo')
    }
  }

  render() {
    const { vehicles, modal, loading } = this.state
    return (
      <div style={{ position: 'relative' }}>
        <center style={{marginTop: '15px'}}><h3><b>Meus Veículos</b></h3></center>
        <div id="list-vehicle">
        {loading ? <span id='loading' className="fa fa-spinner fa-pulse fa-1x" /> : ''}
          <List vehicles={vehicles} handleTrash={this.handleTrash} loading={loading}/>
        </div>
        <div className="div-new-vehicle container">
          <button className="button-new-vehicle" onClick={() => this.props.history.push('/client/newvehicle')}><strong>+ Novo Veículo</strong></button>
        </div>
        <AlertModal
          animation={true}
          show={modal}
          onHide={this.onHideModal}
          message="Deseja realmente excluir o veículo?"
          onCancel={this.onHideModal}
          onSubmit={this.handleSubmit} 
        />
      </div>
    )
  }
}

export default Vehicles;
