import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'

import WizardForm from './WizardForm'

import service from '../../../../../../services/api'
import { vehiclesByLicensePlate } from '../../../../../../utils/request'

function NewVehicle({ history }) {
    const [loading, setLoading] = useState(false)

    async function checkVehicle(License_Plate) {
        const response = await vehiclesByLicensePlate(License_Plate);
        let check;
        try {
            if (response.payload.length > 0) {
                return (check = true);
            }
        } catch {
            return (check = false);
        }
        return check;
    }

    async function submit(values) {
        const { licensePlate, brand, model } = values
        setLoading(true)

        const check = await checkVehicle(licensePlate);

        if (check) return toastr.warning("Veículo já cadastrado");

        try {
            await service.api.post(service.config.endpoint + `vehicles`, {
                licensePlate,
                brand,
                model,
                userId: localStorage.getItem("ID_USUARIO")
            })
            history.push("vehicles");
            return toastr.success("Veículo cadastrado com sucesso!");
        } catch (err) {
            console.log(err)
            toastr.error("Ocorreu um erro ao cadastrar o veículo. Por favor, tente novamente");
        } finally{
            setLoading(false)
        }
    }

    return <WizardForm onSubmit={submit} loading={loading} />
}

export default withRouter(NewVehicle)