import local from './local.js';
import development from './development.js';
import production from './production.js';
import staging from './staging.js';

export default function ConfigureAppEnvironment() {
  const hostname = window && window.location && window.location.hostname;

  const customEnvironment = process.env.REACT_APP_CUSTOM_ENVIRONMENT;

  if (!customEnvironment) {
    switch (hostname) {
      case 'sobrerodas.carrook.com.br':
        return development;
      case 'www.sobrerodas.carrook.com.br':
        return development;
      case 'sobrerodas.club':
        return production;
      case 'www.sobrerodas.club':
        return production;
      case 'staging.sobrerodas.club':
        return staging;
      case 'www.staging.sobrerodas.club':
        return staging;
      default:
        return local;
    }
  } else {
    switch (customEnvironment) {
      case 'development':
        return development;
      case 'production':
        return production;
      case 'staging':
        return staging;
      default:
        return local;
    }
  }
}
