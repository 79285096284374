import React, { useState, useEffect } from 'react'
import Survey from '../../../components/evaluation/Evaluation'
import EvaluationModal from '../maintances/EvaluationModal';
import './styles.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange, create, loadEvaluation, closeModal } from '../../../components/evaluation/reduxEvaluation/evaluationAction'
import AlertModal from '../../../components/alertModal/AlertModal'
// import { getUserOsDigital } from '../../../utils/request'
import { toastr } from "react-redux-toastr";

function SatisfactionSurvey(props) {
    const [sale, setSale] = useState({})
    const [newSurvey, setNewSurvey] = useState(true)
    const [confirmModal, setConfirmModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [satisfactionSurvey, setSatisfactionSurvey] = useState([])
    const { loadEvaluation, handleChange, closeModal } = props

    useEffect(() => {
        const getSale = () => {
            let localStorageSale = localStorage.getItem('SALE')
            if (localStorageSale) {
                localStorage.removeItem('SALE')
                setSale(JSON.parse(localStorageSale))
            }
            else props.history.push('maintances')
        }
        getSale()
    }, [props.history])



    useEffect(() => {
        if (sale.evaluated) {
            setNewSurvey(false)
            loadEvaluation(sale.satisfactionSurvey)
            setSatisfactionSurvey(sale.satisfactionSurvey)
            handleChange('disabled', "disabled")
        }
        // verificar depois 
        if (sale.evaluated) {
            setNewSurvey(false)
            loadEvaluation(sale.satisfactionSurvey)
            setSatisfactionSurvey(sale.satisfactionSurvey)
            handleChange('disabled', "disabled")
        }
        else closeModal()


    }, [sale, loadEvaluation, handleChange, closeModal, satisfactionSurvey])

    const submit = async () => {
        let { Rating_Product_Service, Rating_Price, wouldIndicate } = props.evaluationReducer
        // const { User_id } = sale
        // const resUsuario = await getUserOsDigital(User_id)

        if (Rating_Product_Service || Rating_Price || wouldIndicate || !wouldIndicate) {
            let obj = {
                Cpf_Cnpj: sale.Company.cpfCnpj,
                idManutencao: sale.id,
                Type: sale.type
            }
            props.create(obj, props.evaluationReducer)
            // props.create(obj, props.evaluationReducer, resUsuario.payload.Cpf)
        }
        else {
            return toastr.warning("Por favor, informe todos os campos.");
        }
    }

    const validate = () => {
        const { Rating_Product_Service, Rating_Price, wouldIndicate } = props.evaluationReducer
        if (Rating_Product_Service === 0 || Rating_Price === 0 || wouldIndicate === null) {
            return toastr.warning("Por favor, informe todos os campos.");
        } else {
            setConfirmModal(true)
        }
    }

    const checkCancel = () => {
        const { Rating_Product_Service, Rating_Price, wouldIndicate, Comment } = props.evaluationReducer
        if (newSurvey && (Rating_Product_Service !== 0 || Rating_Price !== 0 || wouldIndicate !== null || Comment !== "")) {
            setCancelModal(true)
        } else {
            props.history.goBack()
        }
    }

    const renderNotRated = () => {
        return (
            <div className='container-survey-mobile'>
                <section>
                    <header>
                        <h4><strong>Avaliação</strong></h4>
                        <h6><strong>Data: </strong>{new Date().toLocaleDateString()}</h6>
                        <h6><strong>Nome: </strong>{sale?.Company?.name}</h6>
                    </header>

                </section>
                <div style={{ position: 'fixed', paddingRight: '15px', bottom: '85px', top: '165px', overflow: 'auto' }}>
                    <Survey data={sale?.satisfactionSurvey} newSurvey={newSurvey} />
                    <footer>
                        <button disabled={!newSurvey} className='btn btn-success' onClick={validate} >Avaliar</button>
                        <button className='btn btn-danger' onClick={checkCancel}>Voltar</button>
                    </footer>
                </div>

                <AlertModal
                    animation={true}
                    show={confirmModal}
                    onHide={() => setConfirmModal(false)}
                    message="Deseja confirmar a avaliação?"
                    onCancel={() => setConfirmModal(false)}
                    onSubmit={submit}
                />

                <AlertModal
                    animation={true}
                    show={cancelModal}
                    onHide={() => setCancelModal(false)}
                    message="Deseja cancelar a avaliação?"
                    onCancel={() => setCancelModal(false)}
                    onSubmit={() => props.history.goBack()}
                />

            </div>
        );
    };

    const renderRated = () => {
        return (
            <div className='container-survey-mobile'>
                <section>
                    <header>
                        <h4><strong>{sale?.Company?.name}</strong></h4>
                        <h6><strong>Venda: {sale?.code}</strong> (Realizada em {sale?.date && new Date(sale.date).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")
                            .split("/").reverse().join("/")})</h6>
                        <h6><strong>CNPJ: </strong>{sale?.Company?.cpfCnpj}</h6>
                    </header>

                </section>
                <div style={{ position: 'fixed', paddingRight: '15px', bottom: '85px', top: '165px', overflow: 'auto' }}>
                    <EvaluationModal satisfactionSurvey={sale.satisfactionSurvey} />
                    <footer>
                        <button className='btn btn-danger' onClick={checkCancel}>Voltar</button>
                    </footer>
                </div>

                <div>
                    <AlertModal
                        animation={true}
                        show={confirmModal}
                        onHide={() => setConfirmModal(false)}
                        message="Deseja confirmar a avaliação?"
                        onCancel={() => setConfirmModal(false)}
                        onSubmit={submit}
                    />

                    <AlertModal
                        animation={true}
                        show={cancelModal}
                        onHide={() => setCancelModal(false)}
                        message="Deseja cancelar a avaliação?"
                        onCancel={() => setCancelModal(false)}
                        onSubmit={() => props.history.goBack()}
                    />
                </div>


            </div>

        )
    }

    return sale.satisfactionSurvey ? renderRated() : renderNotRated()
}

const mapStateToProps = state => ({
    evaluationReducer: state.evaluationReducer,
})

const mapDispatchToProps = dispatch => bindActionCreators({ handleChange, create, loadEvaluation, closeModal }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SatisfactionSurvey))