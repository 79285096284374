import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faSlidersH, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faMap } from '@fortawesome/free-regular-svg-icons'

import { getCompaniesByCity } from '../../../store/actions/provider'
import './styles.css'

import List from '../../../desktop/pages/Companies/List'
import Maps from '../../../components/maps'
import Header from '../../components/Header'
import SearchLocationModal from '../../../desktop/components/search-location-modal'
import Filters from './Filters'

const HeaderOptions = ({ title, icon, onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <span>{title}</span>
    </div>
  )
}


const Companies = () => {
  const [selectCityIsOpened, setSelectCityIsOpen] = useState(false)
  const [mapIsOpened, setMapIsOpened] = useState(false)
  const [filterIsOpened, setFilterIsOpened] = useState(false)

  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { selectedCity, companiesFiltered, more, totalCount } = provider

  useEffect(() => {
    dispatch(getCompaniesByCity())
  }, [selectedCity, dispatch])

  useEffect(() => {
    if (more) {
      setFilterIsOpened(true)
    }
  }, [])

  const handleSelectedCity = city => {
    dispatch({ type: 'HANDLE_CHANGE_CITY', payload: city })
    setSelectCityIsOpen(false)
  }

  return (
    <div id='companies-mobile-page'>
      <header className='companies-mobile-page-header'>
        <Header />
        <center>
          <span
            style={{ fontSize: '15px', marginLeft: 15, marginTop: 10 }}
          >Encontramos <strong>{totalCount}</strong> prestadores na cidade <strong>{selectedCity?.Name}</strong>, utilize o filtro para refinar a sua busca.</span>
        </center>

        <section>
          <HeaderOptions onClick={() => setSelectCityIsOpen(true)} icon={faMapMarkerAlt} title='Cidade' />
          <HeaderOptions onClick={() => setFilterIsOpened(true)} icon={faSlidersH} title='Filtro' />
          <HeaderOptions onClick={() => setMapIsOpened(true)} icon={faMap} title='Mapa' />
        </section>
      </header>

      <main className='companies-mobile-page-main'>
        <List list={companiesFiltered} selectedCity={selectedCity} />
      </main>

      {mapIsOpened &&
        <div className='maps-container' style={{ position: 'fixed', padding: 0 }}>
          <FontAwesomeIcon onClick={() => setMapIsOpened(false)} icon={faTimes} style={{ cursor: 'pointer' }} />
          <Maps className="maps-container-map" list={companiesFiltered} city={selectedCity.Name} states={selectedCity.States.Initial} />
        </div>
      }

      {selectCityIsOpened &&
        <SearchLocationModal
          show={selectCityIsOpened}
          onHide={() => setSelectCityIsOpen(false)}
          onCancel={() => setSelectCityIsOpen(false)}
          onSelectCity={handleSelectedCity}
        />
      }

      {filterIsOpened &&
        <div className='filters-container'>
          <Filters
            onCancel={() => setFilterIsOpened(false)}
            onsubmit={() => setFilterIsOpened(false)}
          />
        </div>
      }
    </div>
  )
}

export default Companies