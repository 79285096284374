import React, { memo } from 'react';
import { faTools, faCashRegister, faWrench, faCamera, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'


function renderIcon(branch) {
  switch (branch) {
    case "Autopeças":
      return { icon: faCashRegister, style: { background: '#0c59cf' } }
    case "Auto Center":
      return { icon: faTools, style: { background: '#ffa834' } }
    case "Oficina":
      return { icon: faWrench, style: { background: '#72bb53' } }
    default:
      return { icon: faCamera, style: { background: '#72bb53' } }
  }
}


function List({ list }) {
  return (
    !list.length ?
      <h3 style={{ marginLeft: 10 }}>Sem resultado</h3>
      :
      list.map(item => (
        <div className="companies-list-item" onClick={() => window.open(window.location.origin + `/companies/details/${item.sourceId}/${item.id}`, '_blank')} key={item.id}>
          <div className="companies-icon">
            {!item.logo
              ? <FontAwesomeIcon {...renderIcon(item.Branches ? item.Branches.description : '')} />
              : <img src={item.logo} alt={item.tradingName} />
            }
          </div>
          <section>
            <header>
              <span title={item.tradingName}><strong>{item.tradingName}</strong></span>
            </header>
            <div>
              <FontAwesomeIcon icon={faStar} style={{ color: '#fec63d' }} />
              <strong>
                {item.amountOfSatisfactionSurvey && item.amountOfSatisfactionSurvey !== 0
                  ? <>
                    <span style={{ color: '#fec63d' }}>{item.rating}</span>
                    <span style={{ color: '#c0c0c0', fontWeight: 'normal', marginLeft: 5 }}>({item.amountOfSatisfactionSurvey} Avaliações)</span>
                  </>
                  : <span style={{ color: '#fec63d' }}>Novo</span>
                }
              </strong>
            </div>
            <div>
              <span style={{ color: '#7a7a7a', fontSize: 10, textTransform: 'uppercase' }}>{item.street}, nº {item.streetNumber}</span>
              <br />
              <span
                style={{ color: '#7a7a7a', fontSize: 10, textTransform: 'uppercase' }}
              >{item.neighborhood} - {item.city}, {item.state}</span>
            </div>
            <footer>
              <span>{item.Branches ? item.Branches.description : '' }</span>
            </footer>
          </section>
        </div >
      )
      )
  )
}




export default memo(List);