import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { validadeEmail, isMobile } from '../../utils/validation'
import services from '../../services/api'

export default class ForgotPassword extends Component {

    state = {
        email: '',
        loading: false,
        success: false
    }

    handleSubmit = async () => {
        await this.setState({ loading: true })
        let validade;
        try {
            validade = await this.validade()

            if (validade.data.length === 0) {
                this.setState({ loading: false })
                return toastr.warning("Email não localizado na base de dados")
            }
        }
        catch (err) {
            console.log(err.response.data.message);
            return toastr.warning(err.response.data.message)
        }

        const [user] = validade.data

        try {
            await services.api.post(services.config.endpoint + 'email/restore-password', {
                Email: this.state.email,
                User_id: user.id,
                Name: user.name.split(' ')[0],
                Location: window.location.origin
            })

            this.setState({ success: true })
            toastr.success('E-mail enviado com sucesso!')
        }
        catch (err) {
            toastr.error('Erro ao tentar enviar o e-mail', err.response.data.message)
        }

        this.setState({ loading: false })

    }

    validade = async () => {
        try {
            const response = await services.api.get(services.config.endpoint + `users?email=${this.state.email}`)

            return response
        } catch (err) {
            throw err
        }
    }

    renderMain() {
        const { email, loading } = this.state
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: '20px', alignItems: 'center' }}>
                    <span style={{ marginTop: '10px' }}>Informe o e-mail para redenifir a senha</span>
                </div>
                <div>
                    <label><strong>E-mail</strong><span style={{ color: 'red' }}>*</span></label>
                    <input
                        className="form-control foco-input"
                        onChange={event => this.setState({ email: event.target.value.replace(/\s/g, '').toLowerCase() })}
                        value={email}
                    />
                </div>
                {isMobile() ?
                    <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                        <button style={{ marginBottom: '5px' }} onClick={() => this.handleSubmit()} id="forgot-pw" disabled={!validadeEmail(email) || loading}>Enviar E-Mail<span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginLeft: '5px' }} /></button>
                        <button onClick={() => this.props.handleOption(0)} id="btn-voltar">Voltar</button>
                    </div>
                    :
                    <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center', flexDirection: 'row', textAlign: 'center' }}>
                        <button onClick={() => this.props.handleOption(0)} id="btn-voltar">Voltar</button>
                        <button onClick={() => this.handleSubmit()} id="forgot-pw" disabled={!validadeEmail(email) || loading}>Enviar E-Mail<span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginLeft: '5px' }} /></button>
                    </div>
                }
            </>
        )
    }

    renderSuccess() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'green', flexDirection: 'row', textAlign: 'center', marginBottom: '20px', alignItems: 'center', width: '280px', borderRadius: '15px' }}>
                    <span style={{ color: 'white', borderRadius: '100%', height: '45px', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center', width: '20%' }}><FontAwesomeIcon style={{ width: '35px', height: '35px' }} icon={faCheck} /></span>
                    <span style={{ color: 'white', fontSize: '20px', width: '60%' }}><strong>E-mail enviado!</strong></span>
                </div>
                <button className='btn btn-link' onClick={() => this.props.handleOption(0)}>Voltar a tela de login</button>
            </div>
        )
    }

    render = () => (
        <>{this.state.success ? this.renderSuccess() : this.renderMain()}</>
    )

}