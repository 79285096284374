import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import PasswordInput from '../../../components/passwordInput/PasswordInput'
import services from '../../../services/api'
import { toastr } from 'react-redux-toastr'
import decrypt from '../../../utils/decrypt'
import Logo from '../../../assets/img/sobre-rodas.png'

export default function ProviderNewPassword({ location, history }) {
  const [companyId, setCompanyId] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyTradingName, setCompanyTradingName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [source, setSource] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [loading, setLoading] = useState(false)

  const [messageConfirmPassword, setMessageConfirmPassword] = useState(null)

  const verifyEqualsPassword = () => {
    if (confirmPassword !== password) {
      setMessageConfirmPassword('As senhas não coincidem, por favor, verifique.')
    }
  }

  useEffect(() => {
    const checkURL = async () => {
      const params = new URLSearchParams(location.search) // classe para pegar queryString da URL
      let id = params.get('id')
      let sourceId = params.get('sourceId')
      try {

        id = decrypt(id)
        if (!await isCompanyId(id, sourceId)) throw new Error()

      } catch (err) {
        history.push('/provider/login')
      }


      setCompanyId(id)
      setSource(sourceId)
    }

    checkURL()
  }, [location.search, history])



  const isCompanyId = async (id, sourceId) => {
    try {
      if (sourceId === '1') {
        const company = await services.api.get(services.config.endpoint + `companies/by-id/${id}`)

        if (!company.data) return false

        setCompanyEmail(company.data.email)
        setCompanyTradingName(company.data.tradingName)
        setCnpj(company.data.cpfCnpj)
      } else if (sourceId === '2') {

        const company = await services.api.get(services.config.endpoint + `company/${id}`)
        setCompanyEmail(company.data.data.Email)
        setCompanyTradingName(company.data.data.Trading_Name)
        setCnpj(company.data.data.Cpf_Cnpj)
      }

    } catch (err) {
      return false

    }

    return true
  }

  const updatePassword = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      if (source === '1') {
        await services.api.put(services.config.endpoint + `companies/${source}/${companyId}`, {
          password,
          newPassword: true
        })

      } else if (source === '2') {
        await services.api.post(services.config.endpoint + `companies/update-pass-os/${companyId}/${cnpj}`, {
          companyEmail,
          password,
          newPassword: true
        })
      }

      toastr.success('Senha alterada com sucesso')

      localStorage.setItem('ID_EMPRESA', companyId)
      localStorage.setItem('NOME_EMPRESA', companyTradingName)
      localStorage.setItem('CNPJ', cnpj)
      localStorage.setItem('SOURCE_ID', source)
      window.location.href = '/provider/home'

    } catch (err) {
      toastr.warning('Ocorreu um erro ao atualizar os dados', 'Por favor, tente novamente')
    } finally {
      setLoading(false)
    }
  }


  return (
    <div className='container-provider'>
      <form onSubmit={updatePassword} className='provider-login-step-3'>
        <div>
          <center>
            <img src={Logo} alt="Sobre Rodas" />
            <h4>Área do Prestador</h4>
            <br />
            <small>Insira sua nova senha abaixo</small>
          </center>
        </div>
        <div>
          <small><strong>Senha</strong></small>
          <PasswordInput
            value={password}
            placeholder='Digite a senha.'
            onChange={e => setPassword(e.target.value)}
            maxLength="18"
          />

          <small><strong>Confirme a Senha</strong></small>
          <PasswordInput
            value={confirmPassword}
            placeholder='Confirme a senha.'
            onChange={e => setConfirmPassword(e.target.value)}
            onFocus={() => setMessageConfirmPassword(null)}
            onBlur={verifyEqualsPassword}
            meta={{ touched: !!messageConfirmPassword, error: messageConfirmPassword }}
            maxLength="18"
          />
        </div>
        <br />

        <button
          type='submit'
          className='btn btn-success'
          disabled={password === '' || confirmPassword === '' || password !== confirmPassword || loading}
        >
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginRight: 5 }} />
          Alterar Senha
        </button>

      </form>
      <div className='provider-login-div-footer'>
        <Footer />
      </div>
    </div>
  )
}