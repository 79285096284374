import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Col } from "react-bootstrap";
import ClickOutside from '../../../components/clickOutSide/ClickOutSide';
import AlertModal from '../../../components/alertModal/AlertModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faTools, faCar } from "@fortawesome/free-solid-svg-icons";
import routes from "../../routes";
import "../../mobile.css";
import { getHistoricMaintances, getVehicles } from '../../../utils/request'


class MenuMobile extends React.Component {

  constructor() {
    super();
    this.state = {
      expanded: false,
      pathSelected: localStorage.getItem('selectedPath'),
      user: localStorage.getItem("ID_USUARIO"),
      userName: localStorage.getItem('NOME_USUARIO')?.split(" ")[0],
      modal: false,
      open: false,
      color: 'black',
      evaluated: 0
    }
  }

  componentDidMount() {
    this.getCount(this.state.user)
  }

  getCount = async (user) => {
    const res = await getVehicles(user)
    const vehicles = res.payload

    let count
    for (let i = 0; i < vehicles.length; i++) {
      let maintances = await getHistoricMaintances(vehicles[i].licensePlate)
      count = maintances.payload.evaluatedCount
    }

    this.setState({ evaluated: count === undefined ? 0 : count})
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/client") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  redirecionarHome(location) {
    this.setState({ pathSelected: location })
    this.props.history.push("/client/dashboard")
  }

  redirecionarManuencoes(location) {
    this.setState({ pathSelected: location })
    this.props.history.push("/client/maintances");
  }

  redirecionarVeiculos(location) {
    this.setState({ pathSelected: location })
    this.props.history.push("/client/vehicles")
  }


  handleModal = async (e) => {
    this.setState({ modal: false })
    if (e.target.name === 'Sim') {
      localStorage.clear()
      this.props.history.push("/client/login")
    }
  }


  render() {
    const { userName, modal, open, evaluated } = this.state;

    return (
      <div>
        <div className="header">
          {/* <div id='div-aviso-message' style={{ display: evaluated === 0 ? 'none' : '' }}>
            <Button id="danger" className='btn-aviso' onClick={() => this.props.history.push('maintances')} fill>Você possui {evaluated === 1 ? '1 avaliação pendente.' : `${evaluated} avaliações pendentes.`} </Button>
          </div> */}
          <ClickOutside onClickOutside={() => { this.setState({ open: false }) }}>
            <div className="perfilbtn" onMouseEnter={() => this.setState({ open: true })} onClick={() => this.setState({ open: true })}>
              <p id="nomeUsuarioLogado">Olá,<u href='/#' style={{ color: 'white', marginLeft: '2%' }}> {userName}</u></p>
            </div>
            {open && <div id="Itens" className="Itens" onMouseLeave={() => this.setState({ open: false })}>
              <span onClick={() => this.setState({ modal: true })}>
                <i className="fa fa-sign-out"></i>
                            Sair
                        </span>
            </div>
            }
          </ClickOutside>
        </div>
        <div style={{ backgroundColor: '#fcfcfc', height: 'calc(100% - 80px)' }}>
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>

        <Route render={({ location, history }) => (
          <div className="menu"
            onSelect={(selected) => {
              if (selected !== 'fixed') {
                const to = '/' + selected;
                if (location.pathname !== to) {
                  localStorage.setItem("selectedPath", selected)
                  history.push(to);
                }
              }
            }}>
            <Col xs={2} sm={2} md={2} lg={2} id="btn-menu" style={{ color: window.location.pathname === '/client/dashboard' ? '#000722' : '#101421cc' }} onClick={() => this.redirecionarHome("client/dashboard")} eventkey="client/dashboard">
              <FontAwesomeIcon
                icon={faHome}
                aria-hidden="false"
                style={{ fontSize: "35px" }}
              />
              <span style={{ fontSize: "12px", fontWeight: "500" }}>Início</span>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} id="btn-menu" style={{ padding: 'initial', color: window.location.pathname === '/client/maintances' ? '#000722' : '#101421cc' }} onClick={() => this.redirecionarManuencoes("client/maintances")} eventkey="client/maintances" >

              <FontAwesomeIcon
                icon={faTools}
                aria-hidden="false"
                style={{ fontSize: "35px" }}
              />
              <div style={{ position: 'absolute', left: '70%', top: '0' }}>
                <span id='badge-manutencao' style={{ display: evaluated === 0 ? 'none' : '' }}>
                  {evaluated}
                </span>
              </div>
              <span style={{ fontSize: "12px", fontWeight: "500" }}>
                Minhas Manutenções
            </span>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} id="btn-menu" style={{ color: window.location.pathname === '/client/vehicles' ? '#000722' : '#101421cc' }} onClick={() => this.redirecionarVeiculos("client/vehicles")} eventkey="client/vehicles">
              <FontAwesomeIcon
                icon={faCar}
                aria-hidden="false"
                style={{ fontSize: "35px" }}
              />
              <span style={{ fontSize: "12px", fontWeight: "500" }}>
                Meus Veículos
            </span>
            </Col>
          </div>
        )}
        />

        <AlertModal show={modal}
          animation={false}
          message='Deseja realmente sair ?'
          onHide={() => this.setState({ modal: false })}
          onCancel={(e) => this.handleModal(e)}
          onSubmit={(e) => this.handleModal(e)}
        />
      </div >
    );
  }
}

export default withRouter(MenuMobile)
