import Dashboard from "./pages/dashboard/Dashboard";
import Maintances from "./pages/maintances"
import Vehicles from "./pages/vehicles"
import NewVehicle from './pages/vehicles/newVehicle'
import SatisfactionSurvey from './pages/satisfaction-survey'

const dashboardRoutes = [
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        layout: "/client"
    },
    {
        path: "/maintances",
        name: "Minhas Manutencoes",
        component: Maintances,
        layout: "/client"
    },
    {
        path: "/newvehicle",
        name: "Novo Veículo",
        component: NewVehicle,
        layout: "/client"
    },
    {
        path: "/vehicles",
        name: "Veiculos",
        component: Vehicles,
        layout: "/client"
    },
    {
        path: "/satisfaction-survey",
        name: "Pesquisa de Satisfação",
        component: SatisfactionSurvey,
        layout: "/client"
    },

];

export default dashboardRoutes;