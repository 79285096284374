import React from 'react';
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rating from '@material-ui/lab/Rating';

export default function EvalutionModal({ satisfactionSurvey }) {
  return (
    <div style={{ width: '550px' }}>
      <Col xs={12} sm={12} md={12} lg={12} id='colModal'>
        <Col id='card-satisfaction'>
          {
            satisfactionSurvey?.wouldIndicate || satisfactionSurvey.Would_Indicate ?
              <>
                <FontAwesomeIcon icon="thumbs-up" style={{ fontSize: '40px', color: 'green' }} />
                <span id='spanIndica' style={{ color: 'green' }}>Indicaria</span>
              </>
              :
              <>
                <FontAwesomeIcon icon="thumbs-down" style={{ fontSize: '40px', color: 'red' }} />
                <span id='spanIndica' style={{ color: 'red' }}>Não indicaria</span>
              </>
          }
        </Col>
        <Col id='card-satisfaction'>
          <h5 id='spanNumber'>{satisfactionSurvey?.ratingProductService || satisfactionSurvey?.Rating_Product_Service}</h5>
          <Rating value={satisfactionSurvey?.ratingProductService || satisfactionSurvey?.Rating_Product_Service} readOnly
            name="Rating_Price" defaultValue={0} size="medium" />
          <span>Produto/Serviço</span>
        </Col>
        <Col id='card-satisfaction'>
          <h5 id='spanNumber'>{satisfactionSurvey?.ratingPrice || satisfactionSurvey?.Rating_Price}</h5>
          <Rating value={satisfactionSurvey?.ratingPrice || satisfactionSurvey?.Rating_Price} readOnly
            name="Rating_Price" defaultValue={0} size="medium" />
          <span>Preço</span>
        </Col>
      </Col>
      <Col md={12} style={{ marginTop: '15px' }}>
        <p>{satisfactionSurvey?.comments || satisfactionSurvey?.Comment}</p>
      </Col>
    </div>
  );
};