import services from '../services/api'

export const countMaintances = async License_Plate => {
    try {
        const response = await services.api.get(services.config.endpoint + `maintances/sales?License_Plate=${License_Plate}&SalesStatus_id=${5}`)
        return { type: 'HANDLE_COUNT_MAINTANCES', payload: response.data.qcSales.length + response.data.osSales.length }
    } catch (err) {
        return { type: 'HANDLE_COUNT_MAINTANCES', payload: 0, message: err.response.data.message }
    }
}

export const countWorkshop = async License_Plate => {
    try {
        const response = await services.api.get(services.config.endpoint + `maintances/sales?License_Plate=${License_Plate}&SalesStatus_id=${5}`)

        const uniques = (value, index, self) => {
            return self.indexOf(value) === index
        }

        let objQC = []

        for (let i = 0; i < response.data.qcSales.length; i++) {
            objQC.push(response.data.qcSales[i].Company.OfCnpjCpf)
        }
        const countQC = objQC.filter(uniques)

        let objOS = []

        for (let i = 0; i < response.data.osSales.length; i++) {
            objOS.push(response.data.osSales[i].Company.Cpf_Cnpj)
        }
        const countOS = objOS.filter(uniques)

        return { type: 'HANDLE_COUNT_WORKSHOP', payload: countQC.length + countOS.length }
    } catch (err) {
        return { type: 'HANDLE_COUNT_WORKSHOP', payload: 0 }
    }
}

export const getHistoricMaintances = async License_Plate => {
    try {
        const response = await services.api.get(services.config.endpoint + `maintances/sales?License_Plate=${License_Plate}&SalesStatus_id=${5}`)
        return { type: 'HANDLE_HISTORIC_MAINTANCES', payload: response.data }
    } catch (err) {
        console.log(err);
        return { type: 'HANDLE_HISTORIC_MAINTANCES_FAILURE', payload: [] }
    }
}

export const getItemsMaintances = async (Orcodigo, OfCnpjCpf, Sales_id) => {
    try {
        const response = await services.api.get(services.config.endpoint + `maintances/items?Orcodigo=${Orcodigo}&OfCnpjCpf=${OfCnpjCpf}&Sales_id=${Sales_id}`)
        return { type: 'HANDLE_ITENS_MAINTANCES', payload: response.data }
    } catch (err) {
        return { type: 'HANDLE_ITENS_MAINTANCES', payload: [] }
    }
}

export const getVehicles = async userId => {
    try {
        const response = await services.api.get(services.config.endpoint + `vehicles?userId=${userId}`)
        return { type: 'HANDLE_VEHICLES_BY_ID', payload: response.data }
    } catch (err) {
        return { type: 'HANDLE_VEHICLES_BY_ID', payload: [], message: err.response.data.message }
    }
}

export const vehiclesByLicensePlate = async licensePlate => {
    try {
        const response = await services.api.get(services.config.endpoint + `vehicles?licensePlate=${licensePlate}`)
        return { type: 'HANDLE_VEHICLES_BY_LICENSE_PLATE', payload: response.data }
    } catch (err) {
        return { type: 'HANDLE_VEHICLES_BY_LICENSE_PLATE', payload: [], message: err.response.data.message }
    }
}

export const getCompany = async Company_id => {
    try {
        const response = await services.api.get(services.config.endpoint + `company/address/os-digital/${Company_id}`)
        return { type: 'HANDLE_COMPANY', payload: response.data }
    } catch (err) {
        return { type: 'HANDLE_COMPANY', payload: [], message: err.response.data.message }
    }
}


export const deleteVehicle = async vehicleId => {
    try {
        await services.api.delete(services.config.endpoint + `vehicles/${vehicleId}`);
        return { type: 'HANDLE_DELETE_VEHICLE', status: true }
    } catch (err) {
        return { type: 'HANDLE_DELETE_VEHICLE', status: false }
    }
}

export const createVehicle = async (licensePlate, brand, model, userId) => {
    try {
        const response = await services.api.post(services.config.endpoint + `vehicles`, {
            licensePlate,
            brand,
            model,
            userId
        });
        return {
            type: 'HANDLE_CREATE_VEHICLE', payload: response.data
        }
    } catch (err) {
        return { type: 'HANDLE_CREATE_VEHICLE', payload: [] }
    }
}

export const loadSurvey = async idManutencao => {
    try {
        const response = await services.api.get(services.config.endpoint + `satisfaction-survey/?salesCode=${idManutencao}`);
        return { type: 'HANDLE_COMPANY', payload: response.data[0] }
    } catch (err) {
        return { type: 'HANDLE_COMPANY', payload: [], message: err.response.data.message }
    }
}


// export const getUserOsDigital = async User_id => {
//     try {
//         const response = await services.api.get(services.config.endpoint + `user/os-digital/${User_id}`)
//         return { type: 'GET_USER', payload: response.data.status ? response.data.data : [] }
//     } catch (err) {
//         return { type: 'GET_USER', payload: [], message: err.response.data.message }
//     }
// }

export const searchVehicleByLicensePlate = async License_Plate => {
    try {
        const response = await services.api.get(services.config.endpoint + `/search/plate/${License_Plate}`)
        return { type: 'GET_PLATE', payload: response.data }
    } catch (err) {
        return { type: 'GET_PLATE', payload: null, message: err.response.data.message }
    }
}

export const getServiceTypes = async () => {
    try {
        const res = await services.api.get(services.config.endpoint + 'services')
        return {
            type: 'LOAD_SERVICE_TYPES_SUCCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_SERVICE_TYPES_FAILURE',
            payload: [],
            message: err?.response?.data?.message
        }
    }
}

export const getCounters = async () => {
    try {
        const res = await services.api.get(services.config.endpoint + 'counters')
        return {
            type: 'LOAD_COUNTERS_SUCCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_COUNTERS_FAILURE',
            payload: {},
            message: err?.response?.data?.message
        }
    }
}

// export const getCompanyOsDigital = async Company_id => {
//     try {
//         const response = await services.api.get(services.config.endpoint + `company/${Company_id}`)
//         return { type: 'GET_COMPANY', payload: response.data.status ? response.data.data : [] }
//     } catch (err) {
//         return { type: 'GET_COMPANY', payload: [], message: err.response.data.message }
//     }
// }


// export const getEvaluationCompany = async companyCpfCnpj => {
//     try {
//         const response = await services.api.get(services.config.endpoint + `satisfaction-survey/by-company/${companyCpfCnpj}`)
//         return { type: 'GET_EVALUATION', payload: response.data }
//     } catch (err) {
//         return { type: 'GET_EVALUATION', payload: null, message: err.response.data.message }
//     }
// }

export const getSegments = async () => {
    try {
        const res = await services.api.get(services.config.endpoint + 'segments')
        return {
            type: 'LOAD_SEGMENTS_SUCCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_SEGMENTS_FAILURE',
            payload: {},
            message: err?.response?.data?.message
        }
    }
}

export const getBranchs = async () => {
    try {
        const res = await services.api.get(services.config.endpoint + 'branches')
        return {
            type: 'LOAD_BRANCH_SUCCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_BRANCH_FAILURE',
            payload: {},
            message: err?.response?.data?.message
        }
    }
}


export const getCountersEvaluation = async (cnpj, sourceId) => {
    try {
        const res = await services.api.get(services.config.endpoint + `companycounters/by-company/${cnpj}/${sourceId}`)
        return {
            type: 'LOAD_COUNTERS_SUCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_COUNTER_FAILURE',
            payload: {},
            message: err?.response?.data?.message
        }
    }
}


// rota de counters para o dashboard 

export const getCountersEvaluationDash = async (cnpj, sourceId) => {
    try {
        const res = await services.api.get(services.config.endpoint + `companycounters/by-company/to-dash/${cnpj}/${sourceId}`)
        return {
            type: 'LOAD_COUNTERS_DASH_SUCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_COUNTER_DASH_FAILURE',
            payload: {},
            message: err?.response?.data?.message
        }
    }
}

export const getProvider = async (sourceId, companyId) => {
    try {
        const res = await services.api.get(services.config.endpoint + `companies/config-data/${sourceId}/${companyId}`)
        return {
            type: 'LOAD_PROVIDER_SUCCESS',
            payload: res.data
        }
    } catch (err) {
        return {
            type: 'LOAD_PROVIDER_FAILURE',
            payload: [],
            message: err?.response?.data?.message
        }
    }
}

export const putMyConfig = async data => {
    try {
        const resp = await services.api.put(services.config.endpoint + `companies/config-data/${data.sourceId}/${data.companyId}`, {
            ...data,
            site: data.site ?? ''
        })
        return {
            type: 'PUT_MYCONFIG_SUCCESS',
            payload: resp.data
        }
    } catch (err) {
        return {
            type: 'PUT_MYCONFIG_FAILURE',
            payload: [],
            message: err?.response?.data?.message
        }
    }
}

// export const getFilesQueridoCarro = async (companyCpfCnpj) => {
//     try {
//         const res = await services.api.get(services.config.endpoint + `files?companyCpfCnpj=${companyCpfCnpj}`)
//         return {
//             type: 'LOAD_FILES_QC_SUCCESS',
//             payload: res.data
//         }
//     } catch (err) {
//         return {
//             type: 'LOAD_FILES_QC_FAILURE',
//             payload: {},
//             message: err?.response?.data?.message
//         }
//     }
// }

// export const getProvidersById = async (sourceId, id) => {
//     try {
//         const response = await services.api.get(services.config.endpoint + `providers/by-id/${sourceId}/${id}`)
//         return { type: 'GET_PROVIDER_BY_ID', payload: response.data.length > 0 ? response.data[0] : {} }
//     } catch (err) {
//         return { type: 'GET_PROVIDER_BY_ID', payload: {}, message: err?.response?.data?.message }
//     }
// }

export const getTopServices = async () => {
    try {
        const response = await services.api.get(services.config.endpoint + 'services/topservices')
        return { type: 'GET_TOP_SERVICES_SUCCESS', payload: response.data }
    } catch (err) {
        return { type: 'GET_TOP_SERVICES_LOAD_FILES_QC_FAILURE', payload: [], message: err?.response?.data?.message }
    }
}

export const getModelsOSDigital = async () => {
    try {
        const response = await services.api.get(services.config.endpoint + 'models/os-digital')
        return { type: 'GET_MODELS', payload: response.data }
    } catch (err) {
        return { type: 'GET_MODELS', payload: [], message: err.response.data.message }
    }
}


export const getBrandsOSDigital = async () => {
    try {
        const response = await services.api.get(services.config.endpoint + 'brands/os-digital')
        const data = response.data.map(b => {
            const Brand = b.Brand.toUpperCase()
            return { Brand }
        }).sort((a, b) => (a.Brand > b.Brand) ? 1 : -1)
        return { type: 'GET_BRANDS', payload: data }
    } catch (err) {
        return { type: 'GET_BRANDS', payload: [], message: err.response.data.message }
    }
}

export const getBrandsByDescriptionOSDigital = async (brand) => {
    try {
        const response = await services.api.get(services.config.endpoint + `brands/by-description/os-digital/${brand}`)
        const data = response.data.map(b => {
            const Model = b.Model.toUpperCase()
            return { ...b, Model }
        }).sort((a, b) => (a.Model > b.Model) ? 1 : -1)
        return { type: 'GET_BRANDS_DESCRIPTION', payload: data }
    } catch (err) {
        return { type: 'GET_BRANDS_DESCRIPTION', payload: [], message: err.response.data.message }
    }
}


export const updateUser = async (id, user) => {
    try {
        const response = await services.api.put(services.config.endpoint + `users/${id}`, 
            user
        );
        return { type: 'UPDATE_USER', payload: response.data }
    } catch (err) {
        return { type: 'UPDATE_USER', payload: 0, message: err.response.data.message }
    }
}


export const loginUser = async (email, password) => {
    try {
        const response = await services.api.post(services.config.endpoint + "users/login", {
            email: email,
            password: password
        });
        return { type: 'LOGIN_USER', payload: response.data }
    } catch (err) {
        return { type: 'LOGIN_USER', payload: 0, message: err.response.data.message }
    }

}

