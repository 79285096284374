import React, { Component } from "react";
import CryptoJS from "crypto-js";
import { toastr } from "react-redux-toastr";

import Input from "../../components/passwordInput/PasswordInput";
import services from "../../services/api";

export default class NewPassword extends Component {
  state = {
    user: {},
    userID: "",
    loading: false,
    password1: "",
    password2: "",
    notMatch: false
  };

  componentDidMount() {
    this.checkURL();
  }

  checkURL = async () => {
    const params = new URLSearchParams(this.props.location.search); // classe para pegar queryString da URL
    let userID = params.get("id");
    let time = params.get("time");

    if (userID && time) {
      /// TROCANDO ESPAÇO EM BRANCO POR + DO HASH
      while (userID.toString().includes(" ") || time.toString().includes(" ")) {
        userID = userID.replace(" ", "+");
        time = time.replace(" ", "+");
      }

      time = CryptoJS.AES.decrypt(time, "reqTime"); // MÉTODO PARA DECRIPTAR string hasheada

      time = time.toString(CryptoJS.enc.Utf8);
      time = parseFloat(time);

      let timeNow = new Date().getTime();

      if (timeNow > time) {
        // CHECAR SE O LINK PARA REDEFINIR SENHA FOI CLICADO EM ATÉ 1 HORA
        toastr.warning("Link expirado");
        return this.props.handleOption(0);
      }
      try {
        const response = await this.getUser(userID);

        await this.setState({ user: response.data });
      } catch (err) {
        toastr.error(
          "Erro ao carregar os dados do usuário",
          err.response.data.message
        );
        return this.props.handleOption(0);
      }
    } else {
      return this.props.handleOption(0);
    }
  };

  getUser = async id => {
    try {
      const response = await services.api.get(services.config.endpoint + "users/by-id", {
              headers: {
                id: id
              }
          });
      return response;
    } catch (err) {
      throw err;
    }
  };

  update = async () => {
    this.setState({ loading: true });
    const { user, password1, password2 } = this.state;

    if (password1 !== password2) {
      this.setState({ loading: false, notMatch: true });
      return;
    }

    if (password1 === user.password) {
      this.setState({ loading: false });
      return toastr.warning("Esta já é sua senha atual");
    }

    try {
      await services.api.put(services.config.endpoint + `users/${user.id}`, {
        password: password1
      });

      toastr.success("Senha alterada com sucesso!");
      this.props.handleOption(0);
    } catch (err) {
      toastr.error("Erro ao tentar alterar a senha", err.response.data.message);
    }

    this.setState({ loading: false });
  };

  render() {
    const { loading, password1, password2, notMatch } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <span>Redefina sua senha abaixo</span>
        </div>
        <div style={{ marginTop: "15px" }}>
          <div>
            <label>
              <strong>Nova senha</strong>
            </label>
            <Input
              className="form-control foco-input"
              value={password1}
              onChange={event =>
                this.setState({
                  password1: event.target.value,
                  notMatch: false
                })
              }
              maxLength="18"
            />
          </div>
          <div>
            <label>
              <strong>Confirme a nova senha</strong>
            </label>
            <Input
              className="form-control foco-input"
              value={password2}
              onChange={event =>
                this.setState({
                  password2: event.target.value,
                  notMatch: false
                })
              }
              maxLength="18"
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {notMatch ? (
            <span
              style={{ color: "red", position: "absolute", fontSize: "13px" }}
            >
              Senhas não coincidem
            </span>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px"
          }}
        >
          <button
            onClick={() => this.update()}
            id="sign-in"
            disabled={loading || password1.length < 6 || password2.length < 6}
          >
            <span
              className={loading ? "fa fa-spinner fa-pulse fa-1x" : ""}
              style={{ marginRight: "5px" }}
            />
            Alterar Senha
          </button>
        </div>
      </>
    );
  }
}
