import React, { useState } from 'react';
import './styles.css';

import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../../components/ProgressBar';

export default function CustomerEvaluation({ rating }) {
  const [checkFive, setCheckFive] = useState(false);
  const [checkFour, setCheckFour] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkOne, setCheckOne] = useState(false);

  const [filteredRatings, setFilteredRatings] = useState([]);

  function handleFilter(Average_Ratings) {
    let inFilter;
    for (let i = 0; i < filteredRatings.length; i++) {
      if (Average_Ratings === filteredRatings[i]) {
        inFilter = true;
      };
    };

    if (inFilter) {
      const newArray = filteredRatings.filter(item => item !== Average_Ratings);

      setFilteredRatings(newArray || []);
    } else {
      setFilteredRatings([...filteredRatings, Average_Ratings]);
    };
  };

  function filterRatings(item) {
    if (!filteredRatings.length) return true;

    for (let i = 0; i < filteredRatings.length; i++) {
      if (item === filteredRatings[i]) return true;
    };
    return false;
  };


  const CheckBox = ({ name, checked, progress, qtd, setValue }) => (
    <div className="mobile-customer-evaluation">
      <div className="custom-checkbox" onClick={setValue}>
        <input
          type="checkbox"
          checked={checked}
          id={name}
          onChange={() => setValue}
        />
        <label className="left-label" htmlFor="defaultChecked2">{name}</label>
      </div>
      <div className="customer-evaluation-progress">
        <ProgressBar width={progress + '%'} barWidth="150px" />
        <label className="left-label" >{qtd}</label>
      </div>
    </div>
  );

  const Evaluation = ({
    Name,
    Average_Ratings,
    Date_Evaluation,
    Would_Indicate,
    Rating_Price,
    Rating_Product_Service,
    Comment,
    Date_Sale
  }) => (
      <section style={{ borderBottom: '1px solid silver', marginBottom: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong style={{ marginRight: '5px', fontSize: '14px' }}>{Name}{/* - {Average_Ratings}*/}</strong>
            <Rating
              readOnly
              name="Average_Ratings"
              defaultValue={Number(Average_Ratings)}
              size="small" />
          </div>
          <span style={{ fontSize: '11px' }}>Publicado em {Date_Evaluation}</span>
        </div>
        <div className="mobile-details-score" style={{ padding: '0px', paddingTop: '10px', paddingBottom: '10px', borderBottom: 'none', marginBottom: '0px' }}>
          <section>
            <div>
              <FontAwesomeIcon icon="thumbs-up" />
              <span>Indicaria</span>
            </div>
            <main>
              <span
                style={{ color: `${Would_Indicate ? 'green' : 'red'}`, fontSize: '12px', fontWeight: 'bold' }}
              >{Would_Indicate ? 'Sim' : 'Não'}</span>
            </main>
          </section>
          <section>
            <div>
              <FontAwesomeIcon icon="dollar-sign" />
              <span style={{ marginLeft: '5px' }}>Preços</span>
            </div>
            <main>
              <Rating
                value={Number(Rating_Price) || 0}
                readOnly
                name="ratingPrice"
                defaultValue={2}
                size="small"
              />
            </main>
          </section>
          <section>
            <div>
              <FontAwesomeIcon icon="wrench" />
              <span>Produtos/Serviços</span>
            </div>
            <main>
              <Rating
                value={Number(Rating_Product_Service) || 0}
                readOnly
                name="ratingProductService"
                defaultValue={2}
                size="small"
              />
            </main>
          </section>
        </div>
        <div style={{ fontSize: '13px', maxWidth: '100%' }}>
          <span>{Comment?.trim().length ? <Quote label={Comment} /> : <span style={{ fontStyle: 'italic', color: 'silver' }}>Sem comentários.</span>}</span>
          <p style={{ marginTop: '5px' }}><b>Data da visita:</b> {Date_Sale}</p>
        </div>
      </section>
    );

  const Quote = ({ label }) => (
    <div className="mobile-details-quote">
      <FontAwesomeIcon icon="quote-left" />
      <span>{label}</span>
      <FontAwesomeIcon icon="quote-right" />
    </div>
  )

  return (
    <div className="mobile-customer-evaluation-container">
      <header>
        <CheckBox
          name="Excelente"
          checked={checkFive}
          setValue={() => {
            setCheckFive(!checkFive)
            handleFilter(5)
          }}
          progress={rating.progressFive}
          qtd={rating.five}
        />
        <CheckBox
          name="Muito bom"
          checked={checkFour}
          setValue={() => {
            setCheckFour(!checkFour)
            handleFilter(4)
          }}
          progress={rating.progressFour}
          qtd={rating.four}
        />
        <CheckBox
          name="Razoável"
          checked={checkThree}
          setValue={() => {
            setCheckThree(!checkThree)
            handleFilter(3)
          }}
          progress={rating.progressThree}
          qtd={rating.three}
        />
        <CheckBox
          name="Ruim"
          checked={checkTwo}
          setValue={() => {
            setCheckTwo(!checkTwo)
            handleFilter(2)
          }}
          progress={rating.progressTwo}
          qtd={rating.two}
        />
        <CheckBox
          name="Horrível"
          checked={checkOne}
          setValue={() => {
            setCheckOne(!checkOne)
            handleFilter(1)
          }}
          progress={rating.progressOne}
          qtd={rating.one}
        />
      </header>

      {rating.avaliacoes?.filter(item => filterRatings(item.Average_Ratings)).map((item, index) => <Evaluation key={index} {...item} />)}
    </div>
  );
};