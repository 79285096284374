import React from 'react'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faMotorcycle, faTruck, faCogs, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'

function Segments({ data, onClick, edit }) {
  // console.log(data)
  function checkeds(item) {
    if (!edit) return
    let checkedItens = []
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked) {
        checkedItens.push(data[i])
      }
    }

    if (checkedItens.length === 1 && checkedItens[0].id === item.id) {
      return toastr.warning('Necessário ao menos um Segmento selecionado')
    } else {
      return onClick({ ...item, checked: !item.checked })
    }
  }

  return (
    <div className="segments-container">
      {data.map(item => (
        <div
          key={item.id}
          className={`segments-list-item ${item.checked ? 'segments-list-item-selected' : ''}`}
          style={{cursor: !edit ? 'not-allowed' : 'pointer' }}
          onClick={() => checkeds(item)}
        >
          <FontAwesomeIcon icon={renderIcons(item.description)} />
          {item.description}
        </div>
      ))}
    </div>
  )
}

function renderIcons(segment) {
  switch (segment) {
    case "Carros":
      return faCar
    case "Motos":
      return faMotorcycle
    case "Pesados":
      return faTruck
    case "Outros":
      return faCogs
    default:
      return faQuestion
  }
}

export default Segments;