import React from 'react'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

export default function Welcome({ name }){
  return(
    <center>
      <h5>Bem-vindo(a)</h5>
      <div className='trading-name-div'>
        <FontAwesomeIcon icon={faUserCircle} />
        <small>{name}</small>
      </div>
    </center>
  )
}