import React, { useEffect } from 'react';
import './styles.css'
import Maps from '../../../components/maps'
import Header from '../../components/header'
import Footer from '../../components/footer'
import List from './List'
import Location from './Location'
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesByCity, handleFilters, removeFilter, openMap } from '../../../store/actions/provider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faTimes, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from '../../../components/LoadingSpinner'

function Companies() {
  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { selectedCity, companiesFiltered, filters, companies, filtersVisible, maps, loading, totalCount } = provider

  useEffect(() => {
    dispatch(getCompaniesByCity())
  }, [selectedCity, dispatch])

  const handleRemoveFilter = async (item, type) => {
    await dispatch(removeFilter(item, type, filters))

    dispatch(handleFilters(companies, filters))
  }

  async function showMap() {
    if (companiesFiltered.length === 0) {
      dispatch(openMap(!maps, 'maps'))
    }
    dispatch(openMap(maps, 'maps'))
  }

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <Location />
      <div className="grid-companies-container">
        <header className='card grid-companies-container-header'>
          {selectedCity?.Name && !loading &&
            <span 
              style={{ fontSize: '13px', marginLeft: 15, marginTop: 10 }}
            >Encontramos <strong>{totalCount}</strong> prestadores na cidade <strong>{selectedCity?.Name}</strong>, utilize o filtro para refinar a sua busca.</span>
          }
          {filtersVisible &&
            <div className={`displayed-filters ${maps && 'displayed-filters-map-opened'}`}>
              {Object.keys(filters).map(type => {

                return filters[type].map(item =>
                  <div key={item.id} >
                    <span>{item.description}</span>
                    <FontAwesomeIcon icon={faTimes} cursor='pointer' onClick={() => handleRemoveFilter(item, type)} />
                  </div>
                )

              })}
            </div>
          }
          {!maps &&
            <div id="exibirMapa">
              <span onClick={() => !!companiesFiltered.length && showMap()} style={{ cursor: 'pointer' }} title={companiesFiltered.length ? "" : "Não encontramos empresa para exibir a localização"}><FontAwesomeIcon icon={faMapMarkedAlt} /> Exibir mapa</span>
            </div>
          }
        </header>

        <main className='card grid-companies-container-main' style={{ display: !maps && 'flex' }}>
          <div id='companies-list-container' className={!maps && companiesFiltered.length > 0 ? `companies-list-container-map-close` : `companies-list-container ${maps && companiesFiltered.length > 0 && ' showing-map-container companies-list-container-overflow'}`}>
            <List list={companiesFiltered} />
          </div>
          {
            maps &&
            <div className="maps-container">
              <FontAwesomeIcon onClick={() => showMap()} icon={faTimes} style={{ cursor: 'pointer' }} />
              <Maps className="maps-container-map" list={companiesFiltered} city={selectedCity.Name} states={selectedCity.States.Initial} />
            </div>
          }
        </main>
      </div>

      <footer className='companies-page-footer' >
        <Footer />
      </footer>

      <LoadingSpinner loading={loading} icon={faSearchLocation} />
    </div>
  )
}

export default Companies;