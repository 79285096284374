import React, { useState } from 'react'
import ReactTable from 'react-table-6'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import './styles.css'

function Services({ servicesDB, services, onAdd, onRemove, edit }) {
  const [servId, setServId] = useState('')

  return (
    <div>
      <label>Selecione abaixo os serviços prestados pela sua empresa: <span style={{ color: 'red' }}>*</span></label>
      <div className="myconfig-services-header">
        <select
          className="form-control foco-input"
          disabled={!edit}
          style={{ cursor: !edit ? 'not-allowed' : 'pointer' }}
          onChange={event => setServId(event.target.value)}
          value={servId}
        >
          <option value={''}>Selecione</option>
          {servicesDB.map(item => (
            <option key={item.id} value={item.id}>{item.description}</option>
          ))}
        </select>
        <button
          className='btn btn-success btn-proceed'
          type='button'
          disabled={!servId}
          title={servId ? 'Clique para adicionar' : 'Selecione um Serviço'}
          onClick={() => {
            onAdd(servId)
            setServId('')
          }}>Adicionar</button>
      </div>
      <div>
        <ReactTable
          data={services}
          style={{
            width: 400
          }}
          columns={[
            {
              Header: 'Serviços Selecionados',
              accessor: 'description',
              width: 370,
              resizable: false,
              sortable: false
            },
            {
              accessor: 'id',
              width: 28,
              headerStyle: { display: 'none' },
              resizable: false,
              Cell: props => <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={() => onRemove(props.value)}
                style={{ color: 'red', cursor: edit ? 'pointer' : 'not-allowed', fontSize: 20 }}
              />
            }
          ]}
          showPagination={false}
          pageSize={services.length < 5 ? 5 : services.length}
          loadingText='Carregando...'
          noDataText='Não há informação'
        />
      </div>
    </div>
  )
}

export default Services