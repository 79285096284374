import React from 'react'
import { useEffect } from 'react';
import { Spinner } from "react-bootstrap";

import sobreRodasLogo from '../../../assets/img/sobre-rodas.png'
import services from "../../../services/api";



import './index.css'

export default function AutoLogin() {

  async function handeAutoLogin(cnpj, password, sourceId) {
    try {
      const company = await services.api.get(
        services.config.endpoint + "companies/by-cnpj/" + cnpj
      );

      if (!company) {
        throw new Error("Company not found");
      }

      if (company.data.password !== password) {
        throw new Error("Invalid password");
      }

      localStorage.setItem("ID_EMPRESA", company.data.id);
      localStorage.setItem("NOME_EMPRESA", company.data.tradingName);
      localStorage.setItem("SOURCE_ID", sourceId);
      localStorage.setItem("CNPJ", company.data.cpfCnpj);

      window.location.href = "/provider/home";
    } catch (err) {
      console.log(err);
      window.location.href = "/provider/login";
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    try {

      const cnpj = params.get("cnpj");
      const sourceId = params.get("sourceId");
      const password = params
        .get("password")
        .split("98LS34RiwAHw")[1]
        .split("vJab4lj58MBM")[0]
      
      handeAutoLogin(cnpj, password, sourceId);
    } catch (err) {
      console.log(err)
      window.location.href = "/provider/login";
    }
    
  }, [])

  return (
    <div className="auto-login">
      <img src={sobreRodasLogo} alt="Logo do Sobre Rodas" />
      <p>
        <Spinner animation="border" role="status" variant="success" /> <br/>
        <strong>Carregando... Não feche a página.</strong>
      </p>
    </div>
  );
}