import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import { milhar } from '../../../utils/normalize'
import CountUp from 'react-countup';

export default function Indicators({ value, message, icon }){
  const [realValue, setRealValue] = useState(0)

  return(
    <div className='container-indicators'>
      <FontAwesomeIcon icon={icon} color='#c9d3dd' />
      {!!value && realValue === 0
        ? <h5><CountUp start={0} end={value} duration={2} onEnd={() => setRealValue(value)} /></h5>
        : <h5>{milhar(realValue)}</h5>
      }
      <h6>{message}</h6>
    </div>

  )
}