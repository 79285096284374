import React, { useState } from 'react'
import './styles.css'
import Footer from '../../components/footer'
import Cnpj from './options/Cnpj'
import FirstLogin from './options/FirstLogin'
import Login from './options/Login'
import ForgotPassword from './options/ForgotPassword'
import { SOURCE_ID } from '../../../utils/constrants'
import Logo from '../../../assets/img/sobre-rodas.png'

export default function ProviderLogin() {
  const [option, setOption] = useState(0)
  const [company, setCompany] = useState({})

  const verifyCompany = data => {
    const { id, sourceId, password } = data

    setCompany(data)

    if (sourceId === SOURCE_ID.QUERIDO_CARRO && !id) {
      setOption(1)
    } else if (sourceId === SOURCE_ID.OS_DIGITAL && !password) {
      setOption(1)
    } else {
      setOption(2)
    }

  }

  const handleSubmit = async () => {
    
    if(company.id) {
      localStorage.setItem('ID_EMPRESA', company.id)
    }
    
    localStorage.setItem('NOME_EMPRESA', company.tradingName)
    localStorage.setItem('SOURCE_ID', company.sourceId)
    localStorage.setItem('CNPJ', company.cpfCnpj)

    window.location.href = '/provider/home'
  }

  return (
    <div className='container-provider'>
      <div>
        {option === 0 && <Cnpj nextOption={verifyCompany} logo={Logo} />}
        {option === 1 && <FirstLogin company={company} handleSubmit={handleSubmit} logo={Logo} />}
        {option === 2 && <Login previousOption={() => setOption(0)} forgotPassword={() => setOption(3)} logo={Logo} company={company} handleSubmit={handleSubmit} />}
        {option === 3 && <ForgotPassword previousOption={() => setOption(2)} company={company} logo={Logo} />}
      </div>
      <div className='provider-login-div-footer'>
        <Footer />
      </div>
    </div>
  )
}