import React, { useState } from 'react'
import '../styles.css'
import Welcome from '../Welcome'
import services from '../../../../services/api'
import { toastr } from 'react-redux-toastr'
import { validadeEmail } from '../../../../utils/validation'

export default function CnpjStep({ previousOption, company, logo }) {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    if (email !== company.email) {
      return toastr.warning('O e-mail informado não está cadastrado. Por favor, verifique.')
    }


    setLoading(true)

    try {
      await services.api.post(services.config.endpoint + 'email/restore-company-password', {
        companyId: company.id,
        email,
        location: window.location.origin,
        sourceId: company.sourceId
      })

      setEmailSent(true)
    } catch (err) {
      toastr.warning('Ocorreu um erro ao encaminhar o email de recuperação', 'Por favor, tente novamente')
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='provider-login-step-3'>
      <center>
        <img src={logo} alt="Sobre Rodas" />
        <h4>Área do Prestador</h4>
      </center>
      <br />
      <Welcome name={!!company.tradingName ? company.tradingName : company.name} />
      {!emailSent ?
        <>
          <div>
            <center><small>Insira o e-mail abaixo para redefinir a senha</small></center>
            <small><strong>E-mail</strong></small>
            <input type='text' placeholder='Informe o e-mail cadastrado' value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <br />
          <button
            type='submit'
            className='btn btn-success'
            disabled={!validadeEmail(email) || loading}
            title={!validadeEmail(email) ? 'Informe um E-mail válido' : ''}
          >
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginRight: 5 }} />
            Próximo
          </button>
          <button type='button' className='btn btn-danger' disabled={loading} onClick={previousOption} >Voltar</button>
        </>
        :
        <>
          <center>
            <br />
            <small>E-mail para redefinição de senha enviado com sucesso.</small>
          </center>
          <button type='button' className='btn btn-danger' onClick={previousOption} >Voltar</button>
        </>
      }
    </form>
  )
}