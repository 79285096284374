import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { isMobile } from "./utils/validation";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { Provider } from "react-redux";
import store from "./store";
import Toastr from "./components/toastr/Toastr.jsx";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fas)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Toastr />
      {isMobile() ? <Mobile /> : <Desktop />}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
