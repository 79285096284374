import React from 'react'
import MainCard from '../../../../components/MainCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rating from '@material-ui/lab/Rating';

import './styles.css'

export function DashBoardCard({ title, icon, onClick, circleColor, counter, fontColor, percent, message }) {
    return (
        <MainCard onClick={onClick} style={{ cursor: 'pointer' }} >
            <div className='dashboard-card' >
                <span className='dashboard-card-span'>{title}</span>
                <h1 style={{ color: fontColor, marginBottom: '0px' }}>{counter}</h1>
                <div style={{ backgroundColor: circleColor }}>
                    <FontAwesomeIcon icon={icon} color='white' aria-hidden="false" />
                </div>
                <span style={{fontSize: '11px'}} className='dashboard-card-span'>
                    <strong style={{ color: fontColor }}>{percent}%</strong> {message}
                </span>
            </div>
        </MainCard>
    )
}

export function CardStar({ title, icon, onClick, circleColor, counter, fontColor, Value_Rating }) {
    return (
        <MainCard onClick={onClick} style={{ cursor: 'pointer' }} >
            <div className='dashboard-card' >
                <span className='dashboard-card-span'>{title}</span>
                <h1 style={{ color: fontColor, marginBottom: '0px' }}>{counter}</h1>
                <div style={{ backgroundColor: circleColor }}>
                    <FontAwesomeIcon icon={icon} color='white' aria-hidden="false" />
                </div>
                <Rating value={Value_Rating} readOnly
                    name="Rating_Price" defaultValue={2} size="medium" />
            </div>
        </MainCard>
    )
}