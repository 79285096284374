import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { withRouter } from 'react-router-dom';
import {
  getItemsMaintances,
  getHistoricMaintances,
} from '../../../../../utils/request';

class Details extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      query: '',
      columnToSort: '',
      sortDirection: 'desc',
      columnToQuery: 'Description',
      showCheckboxes: false,
      loading: false,
      filtered: [],
      modal: false,
      idUsuario: localStorage.getItem('ID_USUARIO'),
      subTotal: 0,
      Discount_Value: 0,
      Amount: 0,
    };
  }

  componentDidMount() {
    this.loadDetalhes();
  }

  loadDetalhes = async () => {
    this.setState({ loading: true });
    const { idManutencao, Cpf_Cnpj, OS, Type, License_Plate } =
      this.props.values;

    let res;
    if (Type === 'os') res = await getItemsMaintances('', '', idManutencao);
    else res = await getItemsMaintances(OS, Cpf_Cnpj, idManutencao);
    const details = res.payload;

    let resManutencao = await getHistoricMaintances(License_Plate);
    let maintances = resManutencao.payload;

    let allManutencao = [];

    if (Type === 'os') {
      for (let i = 0; i < details.length; i++) {
        const item = !!details[i].Product_id
          ? details[i].Products
          : details[i].Services;
        const obj = {
          Description: details[i].Description,
          Quantity: details[i].Quantity,
          Warranty: !!item.Warranty
            ? `${item.Warranty.Kilometers} km ou ${item.Warranty.Months} meses`
            : '---',
          Review: !!item.Review
            ? `${item.Review.Km} km ou ${item.Review.Months} meses`
            : '---',
          Value_Unity: details[i].Unit_Value,
          Discount: details[i].Discount_Value,
          Value: details[i].Amount,
        };
        allManutencao.push(obj);
      }
      for (let i = 0; i < maintances.osSales.length; i++) {
        if (idManutencao === maintances.osSales[i].id) {
          this.setState({
            subTotal: maintances.osSales[i].SubTotal,
            Discount_Value: maintances.osSales[i].Discount_Value,
            Amount: maintances.osSales[i].Final_Value,
          });
        }
      }
    } else if (Type === 'qc') {
      for (let i = 0; i < details.length; i++) {
        const obj = {
          Description: details[i].Descricao,
          Quantity: details[i].Ociquanti,
          Warranty: !!details[i].OciGarKm
            ? `${details[i].OciGarKm || 0} km`
            : '---',
          Review: !!details[i].OciKmRevi
            ? `${details[i].OciKmRevi || 0} km`
            : '---',
          Value_Unity: details[i].OciprcTab,
          Discount: details[i].Ocidescto,
          Value: details[i].Ocisubtot,
        };
        allManutencao.push(obj);
      }
      for (let i = 0; i < maintances.qcSales.length; i++) {
        if (idManutencao === maintances.qcSales[i].sale.Orcodigo) {
          this.setState({
            subTotal:
              maintances.qcSales[i].sale.Orctotved +
              maintances.qcSales[i].sale.Orcdescto,
            Discount_Value: maintances.qcSales[i].sale.Orcdescto,
            Amount: maintances.qcSales[i].sale.Orctotved,
          });
        }
      }
    }

    await this.setState({ data: allManutencao, loading: false });
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <ReactTable
          style={{ textAlign: 'center' }}
          data={data}
          onClick={() => this.setState({ filtered: [] })}
          columns={[
            {
              Header: 'Descrição',
              accessor: 'Description',
              headerClassName: 'text-center',
              width: 290,
            },
            {
              Header: 'Quantidade',
              accessor: 'Quantity',
              headerClassName: 'text-center',
              width: 100,
            },
            {
              Header: 'Garantia',
              accessor: 'Warranty',
              headerClassName: 'text-center',
              width: 150,
            },
            {
              Header: 'Revisão',
              accessor: 'Review',
              headerClassName: 'text-center',
              width: 150,
            },
            {
              Header: 'Valor Unitário',
              accessor: 'Value_Unity',
              headerClassName: 'text-center',
              width: 150,
              Cell: (props) => (
                <span>
                  R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}
                </span>
              ),
            },
            {
              Header: 'Desconto(%)',
              accessor: 'Discount',
              headerClassName: 'text-center',
              width: 150,
              Cell: (props) => (
                <span>
                  {parseFloat(props.value).toFixed(2).replace('.', ',')}%
                </span>
              ),
            },
            {
              Header: 'Total',
              accessor: 'Value',
              headerClassName: 'text-center',
              width: 150,
              Cell: (props) => (
                <span>
                  R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}
                </span>
              ),
            },
          ]}
          defaultPageSize={5}
          className=""
          showPagination={true}
          loading={this.state.loading}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
        <Col className="footerManutencao" xs={12} sm={12} md={12} lg={12}>
          <Col md={2} style={{ justifyContent: 'flex-end' }}>
            <strong>Sub-Total: </strong>
            <span>
              {' '}
              R$ {parseFloat(this.state.subTotal).toFixed(2).replace('.', ',')}
            </span>
          </Col>
          <Col md={2} style={{ justifyContent: 'flex-end' }}>
            <strong>Desconto: </strong>
            <span>
              {' '}
              R${' '}
              {parseFloat(this.state.Discount_Value)
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </Col>
          <Col md={2} style={{ justifyContent: 'flex-end' }}>
            <strong>Total: </strong>
            <span>
              {' '}
              R$ {parseFloat(this.state.Amount).toFixed(2).replace('.', ',')}
            </span>
          </Col>
        </Col>
      </div>
    );
  }
}

export default withRouter(Details);
