import React from 'react'
import './styles.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons"

export default function Footer() {
  return (
  <div className='container-footer'>
    <div className='container-footer-social-medias'>
    </div>

    <div className='container-footer-logo' />
    <div className='container-footer-copy-right'>
      <p>
        <FontAwesomeIcon icon={faCopyright} color='#798da3' />
        {' '} 2020 Work Inteligência
      </p>
    </div>
  </div>
  )
}