import React from 'react'
import './styles.css'
import Input from '../../../../../components/reduxFormInput/ReduxFormInput'
import { Field } from 'redux-form'
import { createTextMask } from 'redux-form-input-masks'

const TimeMask = createTextMask({
  pattern: '99:99',
  stripMask: false
});

function Infos({ edit }) {
  return (
    <div className="myconfig-info-container">
      <div>
        <label>Horário de funcionamento:<span>*</span></label>
        <div className="myconfig-info-operation">
          <Field
            disabled={!edit}
            component={Input}
            name="openAt"
            {...TimeMask}
          />
          <Field
            disabled={!edit}
            component={Input}
            name="closesAt"
            {...TimeMask}
          />
        </div>
      </div>
      <div className="myconfig-info-site">
        <label>Site ou página da empresa:</label>
        <Field
          disabled={!edit}
          component={Input}
          name="site"
        />
      </div>
    </div>
  )
}

export default Infos;