import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import Rating from '@material-ui/lab/Rating';
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from "../customButton/CustomButton"
import { handleChange } from './reduxEvaluation/evaluationAction'

import './styles.css'

const Evaluation = () => {
  const dispatch = useDispatch()
  const { ratingProductService, ratingPrice, wouldIndicate, comments, disabled } = useSelector(state => state.evaluationReducer)

  function handleChangeValue(e, newValue) {
    dispatch(handleChange(newValue, e.target.name))
    if (e.target.name === "comments") {
      dispatch(handleChange(e.target.value, e.target.name))
    }
    if (e.target.name === "true") {
      dispatch(handleChange(true, "wouldIndicate"))
    } else if (e.target.name === "false") {
      dispatch(handleChange(false, "wouldIndicate"))
    }
  }

  return (
    <div id='submit-evaluation-modal'>
      <fieldset>
        <label>Qual a sua avaliação sobre a qualidade dos produtos e serviços ?</label>
        <Rating
          value={ratingProductService}
          disabled={disabled}
          onChange={handleChangeValue}
          name="ratingProductService"
          defaultValue={2}
          size="large"
        />
      </fieldset>
      <fieldset>
        <label>Qual a sua avaliação sobre o preço? </label>
        <Rating
          value={ratingPrice}
          disabled={disabled}
          onChange={handleChangeValue}
          name="ratingPrice"
          defaultValue={2}
          size="large"
        />
      </fieldset>
      <fieldset>
        <label>Você indicaria o fornecedor para seus amigos? </label>
        <div>
          <Button
            name="true"
            value={wouldIndicate}
            disabled={disabled}
            id="botaoReactTable"
            style={{
              boxShadow: wouldIndicate === true ? '0 0 0 0.2rem rgba(38,143,255,.5)' : ''
            }}
            onClick={handleChangeValue}
          >
            <FontAwesomeIcon icon={faThumbsUp} aria-hidden="false" />
              Sim
            </Button>
          <Button
            name="false"
            value={wouldIndicate}
            disabled={disabled}
            id="botaoReactTable"
            style={{
              boxShadow: wouldIndicate === false ? '0 0 0 0.2rem rgba(38,143,255,.5)' : ''
            }}
            onClick={handleChangeValue}
          >
            <FontAwesomeIcon icon={faThumbsDown} aria-hidden="false" />
              Não
            </Button>
        </div>
      </fieldset>
      <div>
        <label>Deixe um comentario</label>
        <textarea
          value={comments}
          maxLength="300"
          disabled={disabled}
          className="form-control foco-input"
          onChange={handleChangeValue}
          name="comments"
        />
      </div>
    </div >
  )
}

export default Evaluation
