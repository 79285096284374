import service from "../../../services/api";
import { toastr } from 'react-redux-toastr'

export function handleChange(value, type) {
    return {
        type: 'HANDLE_CHANGE',
        payload: value,
        payloadType: type
    }
}

export const create = (values, evaluation, customerCpfCnpj, modal) => async dispatch => {
    dispatch({ type: 'CREATE_EVALUATION_STARTED' })

    let { Cpf_Cnpj, idManutencao, Type } = values
    let { ratingProductService, ratingPrice, wouldIndicate, comments } = evaluation

    try {
        await service.api.post(service.config.endpoint + `satisfaction-survey`, {
            companyCpfCnpj: Cpf_Cnpj.trim(),
            salesCode: idManutencao,
            source: Type === 'os' ? 'OS*Digital' : 'Querido*Carro',
            // customerCpfCnpj,
            ratingProductService,
            ratingPrice,
            wouldIndicate,
            comments,
            userId: localStorage.getItem('ID_USUARIO')
        })
        toastr.success("Sucesso", `Avaliação salva com sucesso`)
        window.location.reload();
        return dispatch({
            type: 'HANDLE_CREATE',
            payload: false,
            modal: false
        })
    }
    catch (erro) {
        console.log(erro)
        toastr.error("Erro no salvamento", "Algo inesperado aconteceu. Por favor, repita o procedimento")

        return dispatch({ tyPE: 'CREATE_EVALUATION_FAILURE' })
    }
}


export const loadEvaluation = async (dados) => {
    return {
        type: 'LOAD_EVALUATION',
        payload: dados
    }
}

export function closeModal() {
    return {
        type: 'CLOSE_MODAL',
        payload: false,
    }
}