import React, { Component } from "react";
import Button from "../../../../../components/customButton/CustomButton"
import Avaliacoes from '../../../../../components/evaluation/Evaluation'
import { Modal } from "react-bootstrap";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange, create, loadEvaluation } from '../../../../../components/evaluation/reduxEvaluation/evaluationAction'
import { toastr } from "react-redux-toastr";
import AlertModal from '../../../../../components/alertModal/AlertModal'
import { loadSurvey } from '../../../../../utils/request'
import EvaluationModal from './EvaluationModal';
import { cpfMask, cnpjMask } from '../../../../../utils/normalize';

class EvaluationView extends Component {

    state = {
        modalAvaliar: false,
        modalCancelar: false
    }

    componentDidMount() {
        this.loadEvaluation()
    }

    loadEvaluation = async () => {
        // verifica se ja tem avaliação buscada da api 
        if (this.props.values.Avaliacao) {
            await this.props.loadEvaluation(this.props.values.Avaliacao);
            this.props.handleChange('disabled', "disabled")
        }

        // verificar esse metodo
        if (this.props.values.Avaliacao === true) {
            const { idManutencao } = this.props.values
            const res = await loadSurvey(idManutencao)
            const { ratingProductService, ratingPrice, wouldIndicate, comments } = res.payload
            const obj = {
                ratingProductService, ratingPrice, wouldIndicate, comments
            }
            await this.props.loadEvaluation(obj);
            this.props.handleChange('disabled', "disabled")
        }

    }

    submit = async () => {
        let { ratingProductService, ratingPrice, wouldIndicate } = this.props.evaluationReducer

        if (ratingProductService || ratingPrice || wouldIndicate) {
            await this.props.create(this.props.values, this.props.evaluationReducer)
        }
        else {
            return toastr.warning("Por favor, informe todos os campos.");
        }
    }

    verificarConfirmar() {
        const { ratingProductService, ratingPrice, wouldIndicate } = this.props.evaluationReducer
        if (ratingProductService === 0 || ratingPrice === 0 || wouldIndicate === null) {
            return toastr.warning("Por favor, informe todos os campos.");
        } else {
            this.setState({ modalAvaliar: true })
        }
    }

    verificarCancelar() {
        const { ratingProductService, ratingPrice, wouldIndicate, comments } = this.props.evaluationReducer
        if (ratingProductService !== 0 || ratingPrice !== 0 || wouldIndicate !== null || comments !== "") {
            this.setState({ modalCancelar: true })
        } else {
            this.props.close()
        }
    }

    renderNotRated = () => {
        const { disabled, loading } = this.props.evaluationReducer
        return (
            <Modal dialogClassName="" show={this.props.modalEvaluation} onHide={() => this.props.close()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>Avaliação</strong>
                        <p id='tittle-Detalhes'><strong>Data: </strong>{this.props.dataManutencao}</p>
                        <p id='tittle-Detalhes'><strong>Nome: </strong>{this.props.infoModalEvaluation.Company}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '90%', padding: '2' }}>
                    <div>
                        <Avaliacoes data={this.props} />
                        <div style={{
                            flexDirection: 'row-reverse',
                            display: 'flex',
                            marginTop: '12px'
                        }}>
                            <Button 
                                id="info" 
                                name="Avaliar" 
                                disabled={disabled || loading} 
                                onClick={() => this.verificarConfirmar()} 
                                pullRight 
                                fill 
                                style={{ marginLeft: '12px' }}
                            >
                                <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginRight: 5 }} />
                                Avaliar
                            </Button>
                            <Button id="danger" name="Cancelar" disabled={disabled || loading} onClick={() => this.verificarCancelar()} style={{ marginLeft: '15px' }} pullRight fill>Cancelar</Button>
                        </div>

                        <AlertModal
                            animation={true}
                            show={this.state.modalAvaliar && !loading}
                            onHide={() => this.setState({ modalAvaliar: false })}
                            message="Deseja confirmar a avaliação?"
                            onCancel={() => this.setState({ modalAvaliar: false })}
                            onSubmit={() => this.submit()}
                        />

                        <AlertModal
                            animation={true}
                            show={this.state.modalCancelar}
                            onHide={() => this.setState({ modalCancelar: false })}
                            message="Deseja cancelar a avaliação?"
                            onCancel={() => this.setState({ modalCancelar: false })}
                            onSubmit={() => this.props.close()}

                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    };

    renderRated = () => {
        const { modalEvaluation, values, evaluationReducer } = this.props;
        return (
            <Modal
                animation={true}
                show={modalEvaluation}
                onHide={() => this.props.close()}
                dialogClassName='modal-evaluation-details'
            >
                <Modal.Header>
                    <Modal.Title className='modal-evaluation-details-header'>
                        <h5>{values.Company}</h5>
                        <p><strong>Venda: {values.OS}</strong> (Realizada em {values?.Date && new Date(values.Date).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")
                            .split("/").reverse().join("/")})</p>
                        {values.Cpf_Cnpj &&
                            (
                                values.Cpf_Cnpj.length === 14
                                    ? <p><strong>CNPJ: </strong>{cnpjMask(values.Cpf_Cnpj)}</p>
                                    : <p><strong>CPF: </strong>{cpfMask(values.Cpf_Cnpj)}</p>
                            )
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EvaluationModal {...evaluationReducer} />
                </Modal.Body>
                <Modal.Footer>
                    <button className='myconfig-temp-buttons btn-mycfg-danger' onClick={() => this.props.close()}>Voltar</button>
                </Modal.Footer>
            </Modal>
        );
    };

    render() {
        const { values } = this.props;
        return values.Avaliacao ? this.renderRated() : this.renderNotRated()
    };

}


const mapStateToProps = state => ({
    evaluationReducer: state.evaluationReducer,
})

const mapDispatchToProps = dispatch => bindActionCreators({ handleChange, create, loadEvaluation }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationView)

