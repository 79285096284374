import React from "react";
import "./index.css";
import Login from "./pages/login";
import { Switch, Route } from "react-router-dom";
import ClientLayout from "./desktop/layouts/Client";
import ProviderLayout from './desktop/layouts/Provider'
import SignUp from "./pages/signUp";
import Companies from "./desktop/pages/Companies"
import ProviderLogin from "./desktop/pages/ProviderLogin"
import ProviderNewPassword from "./desktop/pages/ProviderNewPassword"
import DetailsCompany from "./desktop/pages/Companies/DetailsCompany"
import Home from "./desktop/pages/home";
import FirstAcess from "./desktop/layouts/Client/FirstClientAccess"
import AutoLogin from "./desktop/pages/AutoLogin";

const Desktop = () => (
  <Switch>

    <Route exact path="/" component={Home} />

    <Route exact path="/client/login" component={Login} />
    <Route exact path="/client/signup" component={SignUp} />
    <Route exact path="/client/newpassword" component={Login} />
    <Route exact path="/client/first-acess" component={FirstAcess} />
    <Route path="/client" render={props => <ClientLayout {...props} />} />

    <Route exact path="/auto-login" component={AutoLogin} />
    <Route exact path="/provider/login" component={ProviderLogin} />
    <Route exact path="/provider/newpassword" component={ProviderNewPassword} />
    <Route path="/provider" render={props => <ProviderLayout {...props} />} />
    <Route exact path="/companies" component={Companies} />
    <Route exact path="/companies/details/:sourceId/:id" component={DetailsCompany} />
    <Route path="*" component={() => <h1>Página não encontrada :(</h1>} />

  </Switch>
);
export default Desktop;
