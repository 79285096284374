import React, { Component } from "react";
import { Col, Modal } from "react-bootstrap";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import orderBy from "lodash/orderBy";
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb'
import { withRouter } from 'react-router-dom'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from "../../../../../components/customButton/CustomButton"
import { Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getVehicles, deleteVehicle } from '../../../../../utils/request'

class Vehicles extends Component {
  state = {
    data: [],
    query: '',
    columnToSort: '',
    sortDirection: 'desc',
    columnToQuery: 'Description',
    showCheckboxes: false,
    loading: false,
    filtered: [],
    modal: false,
    value: '',
    idUsuario: localStorage.getItem('ID_USUARIO')
  }

  componentDidMount() {
    this.loadVeiculos()
  }

  loadVeiculos = async () => {
    const idUsuario = this.state.idUsuario
    const response = await getVehicles(idUsuario)
    this.setState({ data: response.payload })
  }

  deleteVeiculo = async (value) => {
    const idVeiculo = value.original.id
    const vehicles = await deleteVehicle(idVeiculo)
    if (vehicles.status) {
      toastr.success('Veículo deletado com sucesso!')
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else {
      toastr.success('Não foi possível deletar o veículo. Tente novamente!')
    }

  }

  render() {
    const { data, query } = this.state
    const lowerCaseQuery = this.state.query.toLowerCase();
    return (
      <div>
        <BreadCrumb data={['Início', 'Veículos']}
          path={['dashboard', 'vehicles']} />
        <Col xs={12} sm={12} md={12} lg={12} id="homeHeader" className="row">
          <Link to='newvehicle'>
            <button id="btn-novo" className="btn btn-sucesso">
              + Novo Veículo
            </button>
          </Link>
          <Col md={6} id="input-search">
            <i id="iconepesquisar" className="fa fa-search"></i>
            <input type="text" style={{ paddingLeft: '30px', width: '69%' }}
              className="form-control foco-input"
              placeholder="Pesquisar por Placa, Modelo ou Marca"
              value={query}
              onChange={e => this.setState({ query: e.target.value })}
            />
          </Col>
        </Col>
        <ReactTable
          style={{ width: '100%', textAlign: 'center' }}
          data={orderBy(query ?
            data.filter(x =>
              x['licensePlate'].toString().toLowerCase().includes(lowerCaseQuery) ||
              x['model'].toString().toLowerCase().includes(lowerCaseQuery) ||
              x['brand']?.toString().toLowerCase().includes(lowerCaseQuery)
            )
            :

            data,
            this.state.columnToSort,
            this.state.sortDirection
          )}
          onClick={() => this.setState({ filtered: [] })}
          columns={[
            {
              Header: "Placa",
              accessor: "licensePlate",
              headerClassName: "text-center",
              width: 200,
            },
            {
              Header: "Modelo",
              accessor: "model",
              headerClassName: "text-center",
              width: 300,
            },
            {
              Header: "Marca",
              accessor: "brand",
              headerClassName: "text-center",
              width: 200,
            },
            {
              Header: "Ações",
              accessor: "Acoes",
              headerClassName: "text-center",
              width: 100,
              Cell: props => <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.setState({ modal: true, value: props })}
                style={{ cursor: 'pointer', color: 'red' }} aria-hidden="false" />
            },
          ]}
          defaultPageSize={5}
          className=""
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText='Anterior'
          nextText='Próximo'
          loadingText='Carregando...'
          noDataText='Não há informação'
          pageText='Página'
          ofText='de'
          rowsText='linhas'
        />
        <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })}>
          <Modal.Header closeButton>
            <Modal.Title><strong>Excluir Veículo</strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Deseja realmente excluir o veículo?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button id="danger" name="Não" onClick={() => this.setState({ modal: false })} fill>Não</Button>
            <Button id="info" name="Sim" onClick={() => this.deleteVeiculo(this.state.value)} fill>Sim</Button>
          </Modal.Footer>
        </Modal>
      </div>



    );
  }
}

export default withRouter(Vehicles);
