import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { phoneMask } from '../../../utils/normalize'
import { isMobile } from '../../../utils/validation'

let Confirmation = ({ name, email, phone, licensePlate, brand, model, loading, previousPage, handleSubmit,  }) => {
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}><b>Confirmação de Dados</b></h4>
                <p style={{ fontSize: 'small' }}>
                    {window.location.href.includes('newvehicle')
                    ? 'Confirme os seus dados para criação do veículo' 
                    : 'Confirme os seus dados para criação da conta'}
                
                </p>
            </div>
            <div className='signup-confirmation'>
                {window.location.href.includes('signup') &&
                    <div style={{ borderTop: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                        <span><b>Nome:</b> {name} </span>
                        <span><b>E-mail:</b> {email}</span>
                        <span><b>Telefone: </b>{phoneMask(phone)}</span>
                    </div>
                }
                <div style={window.location.href.includes('newvehicle') ? { borderTop: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' } : { borderBottom: '1px solid #d8d8d8' }}>
                    <span><b>Placa: </b> {licensePlate} </span>
                    <span><b>Modelo: </b> {model}</span>
                    <span><b>Marca: </b> {brand}</span>
                </div>
            </div>
            <br />
            {
                isMobile() ?
                    <div className='signup-form-div-buttons'>
                        <button
                            id='btnAvancar' 
                            type="submit" 
                            disabled={loading} 
                            onClick={() => handleSubmit()} 
                            className="btn btn-success"
                        >
                            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
                            Concluir
                        </button>
                        <button 
                            id='btnAvancar'
                            onClick={() => previousPage()} 
                            disabled={loading} 
                            className="btn btn-danger"
                        >
                            Voltar
                        </button>
                    </div>
                    :
                    <div className='signup-form-div-buttons'>
                        <button 
                            id='btnAvancar' 
                            onClick={() => previousPage()} 
                            disabled={loading} 
                            className="btn btn-danger" 
                            style={window.location.href.includes('signup') ? {} : { marginRight: '10px' }} 
                        >
                            Voltar
                        </button>
                        <button 
                            id='btnAvancar' 
                            type="submit" 
                            onClick={() => handleSubmit()} 
                            disabled={loading} 
                            className="btn btn-success"
                        >
                            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginRight: 5 }} />
                            Concluir
                        </button>
                    </div>
            }

        </>
    )
}

Confirmation = reduxForm({
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // a unique name for the form
    form: 'signUpForm'
})(Confirmation)

const selector = formValueSelector('signUpForm')

const mapStateToProps = state => ({
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    phone: selector(state, 'phone'),
    licensePlate: selector(state, 'licensePlate'),
    model: selector(state, 'model'),
    brand: selector(state, 'brand'),
})

export default connect(mapStateToProps)(Confirmation)
