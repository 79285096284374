import { toastr } from "react-redux-toastr";
import services from "../../../../../../services/api";

export const handleChange = (value, type) => {
  return {
    type: "HANDLE_CHANGE",
    payloadType: type,
    payload: value,
  };
};

export const fetchProviderPersonalData =
  (sourceId, companyId, companyCNPJ) => async (dispatch) => {
    dispatch({ type: "FETCH_PROVIDER_PERSONAL_DATA_STARTED" });

    try {
      const res = await services.api.get(
        services.config.endpoint +
          `companies/get-my-data/${sourceId}/${companyId}`,
        {
          params: {
            companyCNPJ: companyCNPJ,
          },
        }
      );
      dispatch(fetchProviderPersonalDataSuccess(res.data));
    } catch (err) {
      dispatch(fetchProviderPersonalDataFailure(err));
    }
  };

const fetchProviderPersonalDataSuccess = (data) => {
  let providerData = data;

  if (providerData.logo) {
    providerData.logoURL = providerData.logo;

    providerData.logo = {
      name: data.logo.split("amazonaws.com/")[1],
      preview: data.logo,
      uploaded: true,
    };
  }

  providerData.images = Array(5)
    .fill()
    .map((value, i) => {
      return { id: i };
    });

  data.Files.forEach((c, i) => {
    providerData.images[i] = {
      queridoCarroImage: i.queridoCarroImage ? i.queridoCarroImage : null,
      filesId: c.id,
      name: c.name,
      id: i,
      preview: c.file,
      uploaded: true,
    };
  });
  return {
    type: "FETCH_PROVIDER_PERSONAL_DATA_SUCCESS",
    payload: providerData,
  };
};

const fetchProviderPersonalDataFailure = (err) => {
  console.log(err);
  toastr.warning(
    "Ops, ocorreu um erro ao carregar os dados da empresa",
    "Por favor, tente novamente"
  );

  return { type: "FETCH_PROVIDER_PERSONAL_DATA_FAILURE" };
};

export const fetchCep = (zipcode) => async (dispatch) => {
  fetch("https://viacep.com.br/ws/" + zipcode + "/json/")
    .then((response) => response.json())
    .then((data) => {
      const { bairro, localidade, logradouro, uf } = data;
      dispatch({
        type: "FETCH_CEP_SUCCESS",
        payload: {
          street: logradouro,
          neighborhood: bairro,
          state: uf,
          city: localidade,
          disabled: true,
        },
      });
    })
    .catch((err) => {
      dispatch({ type: "FETCH_CEP_FAILURE" });
    });
};

export const updateProviderData =
  (sourceId, companyId, history) => async (dispatch, getState) => {
    dispatch({ type: "UPDATE_PROVIDER_DATA_STARTED" });

    const { providerPersonalData } = getState();

    try {
      await services.api.put(
        services.config.endpoint + `companies/${sourceId}/${companyId}`,
        {
          ...providerPersonalData,
        }
      );
    } catch (err) {
      console.log(err);

      toastr.warning(
        "Ocorreu um erro ao salvar os dados",
        "Por favor, tente novamente"
      );

      return dispatch({ type: "UPDATE_PROVIDER_DATA_FAILURE" });
    }

    toastr.success("Alterações salvas com sucesso");

    localStorage.setItem("selectedPath", "provider/home");

    history.push("home");
  };

export const updateProviderImages =
  (sourceId, companyId, history) => async (dispatch, getState) => {
    dispatch({ type: "UPDATE_IMAGES_STARTED" });

    try {
      await dispatch(verifyLogoUpdate());

      const { images = [] } = getState().providerPersonalData;
      const imagesToUpload = [
        ...images.filter((img) => !img.uploaded && !!img.file),
      ];
      await dispatch(handleImages(sourceId, companyId, imagesToUpload));

      await toastr.success("Imagens atualizadas com sucesso");

      dispatch({ type: "UPDATE_PROVIDER_IMAGES_SUCCESS" });

      localStorage.setItem("selectedPath", "provider/home");

      history.push("home");
    } catch (err) {
      toastr.warning(
        "Ocorreu um erro ao atualizar as imagens. Por favor, tente novamente"
      );

      return dispatch({ type: "UPDATE_IMAGES_FAILURE" });
    }
  };

const verifyLogoUpdate = () => async (dispatch, getState) => {
  const { logo } = getState().providerPersonalData;

  /// SE O LOGO NÃO ESTÁ UPADO E EXISTE O ARQUIVO FILE
  if (!logo?.uploaded && !!logo?.file) {
    dispatch({ type: "UPLOAD_LOGO_STARTED" });

    try {
      const imageURL = await uploadImageToS3(logo);

      dispatch({ type: "UPLOAD_LOGO_SUCCESS", payload: imageURL });
    } catch (err) {
      toastr.warning(
        "Ocorreu um erro ao salvar o logo",
        "Por favor, tente novamente"
      );

      dispatch({ type: "UPLOAD_LOGO_FAILURE" });
    }
  } else if (!logo?.uploaded) {
    dispatch({ type: "HANDLE_CHANGE", payload: null, payloadType: "logo" });
    dispatch({ type: "HANDLE_CHANGE", payload: null, payloadType: "logoURL" });
  }
};

const handleImages =
  (sourceId, companyId, imagesToUpload) => async (dispatch, getState) => {
    const {
      logoURL = "",
      imagesToDelete = [],
      logoToDelete = {},
    } = getState().providerPersonalData;
    if (imagesToUpload.length > 0) {
      dispatch({ type: "UPLOAD_IMAGES_STARTED" });
      for (let i in imagesToUpload) {
        imagesToUpload[i].URL = await uploadImageToS3(imagesToUpload[i]);
        imagesToUpload[i].uploaded = true;
      }

      await dispatch({
        type: "UPLOAD_IMAGES_SUCCESS",
        payload: imagesToUpload,
      });
    }

    const { images } = getState().providerPersonalData;

    await services.api.put(
      services.config.endpoint +
        `companies/my-personal-data-files/${sourceId}/${companyId}`,
      {
        imagesToInsert: images.filter((image) => !!image.URL),
        imagesToDelete: imagesToDelete,
        logo: logoURL,
      }
    );

    if (imagesToDelete.length > 0 || !!logoToDelete.name) {
      dispatch({ type: "DELETE_IMAGES_STARTED" });

      const myImagesToDelete = [...imagesToDelete];

      if (!!logoToDelete.name) {
        myImagesToDelete.push(logoToDelete);
      }
      try {
        await deleteImagesFromS3(myImagesToDelete);
      } catch (err) {
        dispatch({ type: "DELETE_IMAGES_FAILURE" });
      }

      dispatch({ type: "DELETE_IMAGES_SUCCESS" });
    }

    dispatch({ type: "UPDATE_IMAGES_SUCCESS" });
  };

const uploadImageToS3 = async (image) => {
  let form = new FormData();
  form.append("image", image.file, image.name);

  const res = await services.api.post(services.config.endpoint + "s3", form);

  return res.data.imageURL;
};

const deleteImagesFromS3 = async (images) => {
  await services.api.post(services.config.endpoint + "s3/delete", { images });
};

export const addImagesToDelete = (file, items) => {
  let imagesToDelete = items;

  imagesToDelete.push(file);

  return {
    type: "HANDLE_CHANGE",
    payload: imagesToDelete,
    payloadType: "imagesToDelete",
  };
};
