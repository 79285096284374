import React, { Component } from "react";
import Rating from '@material-ui/lab/Rating';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange } from '../../../store/actions/provider'
import { toastr } from "react-redux-toastr";

class CustomerEvaluation extends Component {

    setValue = async (value, filtro) => {
        let { filtroAvaliacoes, companyEvaluation } = this.props.provider
        let obj = []
        let todos = []
        switch (filtro) {
            case "Excelente":
                obj = {
                    estrelas: 5,
                    descricao: filtro
                }
                break;
            case "MuitoBom":
                obj = {
                    estrelas: 4,
                    descricao: filtro
                }
                break;
            case "Razoavel":
                obj = {
                    estrelas: 3,
                    descricao: filtro
                }
                break;
            case "Ruim":
                obj = {
                    estrelas: 2,
                    descricao: filtro
                }
                break;
            case "Horrivel":
                obj = {
                    estrelas: 1,
                    descricao: filtro
                }
                break;
            default:
                break;
        }
        todos.push(obj)
        if (value) {
            await this.props.handleChange([...this.props.provider.filtroAvaliacoes, ...todos], 'filtroAvaliacoes')
            this.filtrarAvaliacoes(this.props.provider.filtroAvaliacoes, this.props.provider.companyEvaluation);
        } else {
            let filtered = filtroAvaliacoes.filter(s => s.descricao !== filtro);
            this.props.handleChange(filtered, 'filtroAvaliacoes')
            this.excluirFiltro(filtered, companyEvaluation);
        }
    }

    excluirFiltro = async (filtroAvaliacoes, companyEvaluation) => {
        if (filtroAvaliacoes.length <= 0) {
            await this.props.handleChange(companyEvaluation, 'currencyEvaluation')
        } else {
            this.filtrarAvaliacoes(filtroAvaliacoes, companyEvaluation);
        }

    }

    filtrarAvaliacoes(filtroAvaliacoes, companyEvaluation) {
        let avaliacoesFiltradas = []
        let filtro = []
        for (let i = 0; i < filtroAvaliacoes.length; i++) {
            filtro = companyEvaluation.filter(x => x.Average_Ratings === filtroAvaliacoes[i].estrelas);
            avaliacoesFiltradas.push(...filtro)
        }
        // avaliacoes current
        this.props.handleChange(avaliacoesFiltradas, 'currencyEvaluation')
    }

    render() {

        return (
            <div id="filtro-avaliacoes-clientes">
                <div className="custom-checkbox">
                    <input type="checkbox" checked={this.props.checked} id={this.props.check} onChange={() => { this.setValue(!this.props.checked, this.props.check) }}
                        onClick={() => {
                            if (this.props.provider.companyEvaluation.length) {
                                if (this.props.checked) {
                                    this.props.handleChange(false, this.props.check)
                                } else {
                                    this.props.handleChange(true, this.props.check)
                                }
                            } else {
                                this.props.handleChange(false, this.props.check)
                                return toastr.warning('Nenhuma avaliação disponível')
                            }
                        }}></input>
                    <label className="left-label" htmlFor="defaultChecked2">{this.props.filtro}</label>
                </div>
                <Rating readOnly
                    name="Average_Ratings" defaultValue={this.props.estrelas} size="medium" />
                <div className="progress">
                    <div className="progress-bar bg-success" role="progressbar"
                        aria-valuemin="0" aria-valuemax="100" style={{ width: this.props.width }}>
                    </div>
                </div>
                <label className="left-label" >{this.props.evaluationQtd}</label>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider
})
const mapDispatchToProps = dispatch => bindActionCreators({ handleChange }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEvaluation)
