import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import SearchInput from '../../../desktop/components/search-input';
import SearchLocationModal from '../../../desktop/components/search-location-modal';
import Categories from './Categories';
import Indicators from './Indecator';
import Footer from '../../components/Footer';

import { getCounters, getTopServices } from '../../../utils/request'
import { handleChange, addFilters, clearFilters } from '../../../store/actions/provider';

import services from '../../../services/api';

export default function Home() {
  const [indicators, setIndicators] = useState({});
  const [serviceTypes, setServiceTypes] = useState([]);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { provider } = useSelector(state => state);
  const { selectedCity, filters } = provider;

  useEffect(() => {
    const loadServiceTypes = async () => {
      const { data } = await services.api.get(services.config.endpoint + 'branches?description=Autopeças')
      const branch = data[0]
      const res = await getTopServices();
      const servicesSorted = [
        ...res.payload.map(item => {
          if (item.Services.description === branch.description) { //TRATATIVA PARA ADICIONAR BRANCH DE AUTOPEÇAS
            return {
              ...item,
              Services: branch,
              isBranch: true
            }
          } else return item
        }),
        {
          Services: {
            description: 'Mais'
          },
          icon: "ellipsis-h",
          more: true
        },
      ];
      setServiceTypes(servicesSorted);
    }
    loadServiceTypes();
    dispatch(clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const loadCounters = async () => {
      const res = await getCounters();
      setIndicators(res.payload);
    }
    loadCounters();
  }, []);

  const handleCity = city => {
    dispatch(handleChange(city, 'selectedCity'));

    setOpenLocationModal(false);

    if (selectedCategorie) {
      history.push('/companies');
    };
  };

  const handleCategorie = async categorie => {
    /// SE A CATEGORIA SELECIONADA FOR BRANCH (EX: AUTO PEÇAS)
    if (categorie.isBranch) {
      dispatch(addFilters(categorie.Services, 'branchs', filters))
    }
    else 
    if (!categorie.more) { // SE NÃO FOR A OPÇÃO 'MAIS'
      dispatch(addFilters(categorie.Services, 'services', filters));
    } else {
      dispatch(handleChange(true, 'more'));
    };

    setSelectedCategorie(true);

    if (!selectedCity?.id) {
      setOpenLocationModal(true);
    } else {
      history.push('/companies');
    };
  };

  return (
    <>
      <Header />
      <div className="mobile-home-banner">
        <SearchInput
          placeholder={selectedCity?.id ? `Você está em: ${selectedCity.Name + ', ' + selectedCity.States.Initials}` : 'Aonde você está?'}
          onClick={() => {
            setOpenLocationModal(true)
          }}
          disabled={true}
        />
      </div>

      <div className="mobile-home-topservices">
        <Categories data={serviceTypes} onSelectCategorie={handleCategorie} />
      </div>

      <div className="mobile-home-indicators">
        <center>
          <h5>Informações dos nossos clientes</h5>
          <p>Atualizado em {`${indicators.updatedAt?.toString().split('T')[0].split('-').reverse().join('/')}`}</p>
        </center>

        <section>
          <Indicators
            icon="tools"
            message='Oficinas e autopeças disponíveis em todo território nacional'
            value={indicators.companies}
          />
          <Indicators
            icon="car"
            message='Veículos atendidos'
            value={indicators.vehicles}
          />
          <Indicators
            icon="file-invoice-dollar"
            message='Vendas e ordens de serviços realizados'
            value={indicators.sales}
          />
          <Indicators
            icon="dolly"
            message='Itens vendidos'
            value={indicators.items}
          />
        </section>
      </div>

      <Footer />

      {openLocationModal &&
        <SearchLocationModal
          style={{
            position: 'fixed',
            top: '30%'
          }}
          show={openLocationModal}
          onHide={() => setOpenLocationModal(false)}
          onSelectCity={handleCity}
        />
      }
    </>
  );
};