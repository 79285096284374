import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "../../../../components/customButton/CustomButton"
import "./styles.css";
import ClickOutside from "./ClickOutSide";
import AlertModal from '../../../../components/alertModal/AlertModal'
import { getHistoricMaintances, getVehicles } from '../../../../utils/request'

function HeaderNavClient({ history }) {
  const [modal, setModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [evaluated, setEvaluated] = useState(0)
  const userName = useState(localStorage.getItem('NOME_USUARIO')?.split(" ").slice(0, 1).join(" "))
  const userId = localStorage.getItem("ID_USUARIO")

  useEffect(() => {
    const getCount = async () => {
      const res = await getVehicles(userId)
      const vehicles = res.payload
      let count = 0
      for (let i = 0; i < vehicles.length; i++) {
        let maintances = await getHistoricMaintances(vehicles[i].licensePlate)
        count += maintances.payload.evaluatedCount
      }

      setEvaluated(count === undefined ? 0 : count)
    }
    getCount()
  }, [userId])



  const logout = () => {
    localStorage.clear()
    window.location.href = '/client/login'
  }

  return (
    <div className='principal'>
      <div id='div-degustation-message' style={{ display: evaluated === 0 ? 'none' : '' }}>
        <Button id="danger" onClick={() => history.push('maintances')} fill>Você possui {evaluated} avaliações pendentes.</Button>
      </div>
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <div
          className="perfilbtn"
          onMouseEnter={() => setOpen(true)}
          onClick={() => setOpen(true)}
        >
          <p id="nomeUsuarioLogado">
            Olá,
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                marginLeft: "2%"
              }}
            >
              {" "}
              {userName}
            </span>
          </p>
        </div>
        {open && (
          <div
            id="Itens"
            className="Itens"
            onMouseLeave={() => setOpen(false)}
          >
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => setModal(true)}>
              <i className="fa fa-sign-out"></i>
              Sair
            </span>
          </div>
        )}
      </ClickOutside>

      <AlertModal show={modal}
        animation={false}
        message='Deseja realmente sair ?'
        onHide={() => setModal(false)}
        onCancel={() => setModal(false)}
        onSubmit={logout}
      />
    </div>
  );
}

export default withRouter(HeaderNavClient);
