import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'

export default function Categories({ data, onSelectCategorie }) {
  return (
    <>
      {data.map(c => (
        <div key={c.Services.description} className="mobile-home-categorie" onClick={() => onSelectCategorie(c)}>
          <FontAwesomeIcon icon={c.icon} />
          <span>{c.Services.description}</span>
        </div>
      ))}
    </>
  )
}