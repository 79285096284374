import crypto from "crypto";

export default function decrypt(data) {
  const resizedIV = Buffer.allocUnsafe(16)

  const iv = crypto.createHash("sha256").update("myHashedIV").digest();

  iv.copy(resizedIV);

  const key = crypto.createHash("sha256").update('companyIdHash').digest()

  try {
    let decipher = crypto.createDecipheriv("aes256", key, resizedIV)
  
    let msg = decipher.update(data.toString(), "hex", "binary")
    msg = decipher.final("binary")
  
    return msg
  } catch(err) {
    throw new Error()
  }
};