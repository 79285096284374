import React, { useState } from 'react'
import '../styles.css'
import { toastr } from 'react-redux-toastr'
import PasswordInput from '../../../../components/passwordInput/PasswordInput'
import Welcome from '../Welcome'

export default function CnpjStep({ previousOption, forgotPassword, company, handleSubmit, logo }){
  const [password, setPassword] = useState('')

  const verifyPassword = e => {
    e.preventDefault()
    
    if(password !== company.password) {
      return toastr.warning('Senha incorreta, por favor, verifique.')
    }

    handleSubmit()
  }
  return(
    <form onSubmit={verifyPassword} className='provider-login-step-2'>
      <center>
        <img src={logo} alt="Sobre Rodas" />
        <h4>Área do Prestador</h4>
      </center>
      <br/>
      <div>
        <Welcome name={!!company.tradingName ? company.tradingName : company.name} />
        <div className='provider-login-step-2-password-div'>
          <small><strong>Digite sua senha</strong></small>
          <PasswordInput maxLength="18" value={password} placeholder='Informe a senha cadastrada.' onChange={e => setPassword(e.target.value) }/>
          <center>
          <small>
            <button type='button' className='btn btn-link btn-forgot-password' onClick={forgotPassword}>
              Esqueci a senha
            </button>
          </small>
          </center>
        </div>
      </div>

      <button type='submit' className='btn btn-success' disabled={password === ''} >Próximo</button>
      <button type='button' className='btn btn-danger' onClick={previousOption} >Voltar</button>
    </form>
  )
}