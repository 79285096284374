import React, { memo } from 'react'

function ListProducts(props) {
    const { servicos } = props
    return (
        <>
            {servicos?.map((p, index) => (
                <div key={index} className='cards-mobile' style={{ marginTop: '6%' }}>
                    <div className='cards-mobile-body'>
                        <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'center', float: 'left' }}>
                            <div id="div-circle" style={{ backgroundColor: `${"#" + ((1 << 24) * Math.random() | 0).toString(16)}` }}>
                                <span id="span-circle"><strong>{p.descricao.charAt([0])}</strong></span>
                            </div>
                        </div>
                        <div style={
                            { display: 'flex', flexDirection: 'column', width: '100%' }
                        } >
                            <span><strong> {p.descricao} </strong></span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span> {p.quantidade} x {p.valorUnitario ? p.valorUnitario.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '0,00'} </span>
                                <span> < strong > {p.total ? p.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '0,00'} </strong></span >
                            </div>
                        </div>
                    </div>
                    <div className="cards-mobile-footer">
                        <span>Garantia: {p.warranty || 'Não Informado'}</span>
                        <span>Revisão: {p.review || 'Não Informado'}</span>
                    </div>
                </div>
            ))
            } </>
    )
}

export default memo(ListProducts)