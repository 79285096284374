import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceTypes, getBranchs, getSegments } from '../../../utils/request'
import { addFilters, removeFilter, handleFilters, clearFilters } from '../../../store/actions/provider'
import { toastr } from 'react-redux-toastr'


const Filters = ({ onCancel, onsubmit }) => {
  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { serviceTypes, branchs, segments, filters, companies } = provider

  useEffect(() => {
    dispatch([getServiceTypes(), getSegments(), getBranchs()])

  }, [dispatch])

  const handleCheckBoxes = (object, checked, type) => {

    if (checked) {
      dispatch(addFilters(object, type, filters))
    }
    else {
      dispatch(removeFilter(object, type, filters))
    }
  }

  const handleRemoveFilter = async (item, type) => {
    await dispatch(removeFilter(item, type, filters))

    dispatch(handleFilters(companies, filters))
  }

  const handleApplyFilters = () => {

    if (filters.branchs.length === 0 && filters.segments.length === 0 && filters.services.length === 0) {
      return toastr.warning('Por favor, selecione ao menos um filtro para prosseguir.')
    }

    dispatch(handleFilters(companies, filters))
    onsubmit()
  }



  return(
    <div id='filters-mobile-container'>
      <header>
        <button className='btn btn-link' onClick={() => dispatch(clearFilters())}>Limpar filtros</button>
        <div>
          <FontAwesomeIcon icon={faTimes} onClick={onCancel}/>
        </div>
      </header>

      <main>
        <section>
          <legend>Segmento</legend>
          <div className='container-segments-grid containers-grid'>
            {segments.map(c => {
              return (
                <div key={c.id}>
                  <input
                    type='checkbox'
                    checked={!!filters.segments.find(segment => segment.id === c.id)}
                    onChange={e => handleCheckBoxes(c, e.target.checked, 'segments')}
                    id={c.description}
                  />
                  <label htmlFor={c.description}>{c.description}</label>
                </div>
              )
            })}
          </div>
        </section>


        <section>
          <legend>Ramo</legend> 
          <div className='container-branch-grid containers-grid'>
            {branchs.map((c, i) => {
              return (
                <div key={c.id}>
                  <input
                    type='checkbox'
                    checked={!!filters.branchs.find(branch => branch.id === c.id)}
                    onChange={e => handleCheckBoxes(c, e.target.checked, 'branchs')}
                    id={c.description+c.id}
                  />
                  <label htmlFor={c.description+c.id}>{c.description}</label>
                </div>
              )
            })}
          </div>
        </section>

        <section>
          <legend>Serviços</legend>
          <div className='container-services-grid containers-grid'>
            {serviceTypes.filter(s => s.description !== 'Autopeças').map(c => {
              return (
                <div key={c.id} style={{ justifyContent: 'left' }}>
                  <input
                    type='checkbox'
                    checked={!!filters.services.find(service => service.id === c.id)}
                    onChange={e => handleCheckBoxes(c, e.target.checked, 'services')}
                    id={c.description}
                  />
                  <label htmlFor={c.description}>{c.description}</label>
                </div>
              )
            })}
          </div>
        </section>
      </main>

      <footer>
        <div className='display-filters-mobile'>
          {Object.keys(filters).map(type => {
            return filters[type].map(item =>
              <div key={item.id} >
                <span>{item.description}</span>
                <FontAwesomeIcon icon={faTimes} cursor='pointer' onClick={() => handleRemoveFilter(item, type)} />
              </div>
            )
          })}
        </div>

        <button onClick={handleApplyFilters}>Mostrar resultados</button>
      </footer>
    </div>
  )
}

export default Filters