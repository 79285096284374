import React from "react";
import SideNav from './SideNav'
import HeaderNav from './HeaderNav'
import { withRouter } from "react-router-dom";
import { useEffect } from "react";


function Client({ history }) {

    useEffect(() => {
        if (
            !localStorage.getItem('ID_USUARIO') ||
            localStorage.getItem('ID_USUARIO') === '' ||
            !localStorage.getItem('NOME_USUARIO') ||
            localStorage.getItem('NOME_USUARIO') === ''
            ) {
              history.push('/client/login')
            }
    }, [history])

    return (
        <div className="wrapper">
            <SideNav />
            <HeaderNav />
        </div>
    );
}

export default withRouter(Client)
