import Dashboard from "./pages/dashboard/Dashboard";
import Maintances from "./pages/maintances/Maintances";
import Vehicles from "./pages/vehicles/Vehicles";
import NewVehicle from "./pages/vehicles/newVehicle";

const clientRoutes = [{
        path: "/dashboard",
        name: "Página Inicial",
        component: Dashboard,
        layout: "/client"
    },
    {
        path: "/maintances",
        name: "Minhas Manutenções",
        component: Maintances,
        layout: "/client"
    },
    {
        path: "/vehicles",
        name: "Veiculos",
        component: Vehicles,
        layout: "/client"
    },
    {
        path: "/newvehicle",
        name: "Novo Veículo",
        component: NewVehicle,
        layout: "/client"
    },
];

export default clientRoutes;