import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import './styles.css'

import ProgressBar from './ProgressBar'
import SelectLicensePlate from '../../../../pages/signUp/steps/SelectLicensePlate'
import VehicleData from '../../../../pages/signUp/steps/VehicleData'
import Confirmation from '../../../../pages/signUp/steps/Confirmation'

function WizardForm({ onSubmit }) {

    const [page, setPage] = useState(1)

    function nextPage() {
        setPage(page + 1)
    }

    function previousPage() {
        setPage(page - 1)
    }

    return (
        <div className="new-vehicle-mobile">
            <div className="new-vehicle-mobile-progressbar">
                <section>
                    <ProgressBar percent={page === 1 ? 0 : page === 2 ? 50 : 100} />
                </section>
            </div>
            {page === 1 && <SelectLicensePlate onSubmit={nextPage} />}
            {page === 2 && <VehicleData onSubmit={nextPage} previousPage={previousPage} />}
            {page === 3 && <Confirmation onSubmit={onSubmit} previousPage={previousPage} />}
        </div>
    )
}

WizardForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

export default reduxForm({
    destroyOnUnmount: true, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // a unique name for the form
    form: 'signUpForm'
})(WizardForm)