import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import ClickOutside from "./ClickOutSide";
import AlertModal from '../../../../components/alertModal/AlertModal'

function HeaderNav({ history }) {
  const [modal, setModal] = useState(false)
  const [open, setOpen] = useState(false)
  const userName = useState(localStorage.getItem('NOME_EMPRESA')?.split(" ").slice(0, 1).join(" "))

  const logout = () => {
    localStorage.clear()
    window.location.href = '/provider/login'
  }

  return (
    <div className='provider'>
      <ClickOutside onClickOutside={() => setOpen(false)}>
        <div
          className="perfilbtn"
          onMouseEnter={() => setOpen(true)}
          onClick={() => setOpen(true)}
        >
          <p id="nomeUsuarioLogado">
            Olá,
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                marginLeft: "2%"
              }}
            >
              {userName}
            </span>
          </p>
        </div>
        {open && (
          <div
            id="ItensProvider"
            className="ItensProvider"
            onMouseLeave={() => setOpen(false)}
          >
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => setModal(true)}>
              <i className="fa fa-sign-out"></i>
              Sair
            </span>
          </div>
        )}
      </ClickOutside>

      <AlertModal show={modal}
        animation={false}
        message='Deseja realmente sair ?'
        onHide={() => setModal(false)}
        onCancel={() => setModal(false)}
        onSubmit={logout}
      />
    </div>
  );
}

export default withRouter(HeaderNav);
