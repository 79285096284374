import { createStore, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import promise from "redux-promise";
import multi from "redux-multi";
import thunk from "redux-thunk";
import evaluationReducer from "../components/evaluation/reduxEvaluation/evaluationReducer"
import provider from './reducers/provider'
import evaluated from '../desktop/layouts/Provider/pages/Evaluated/redux/reducers/evaluated'
import providerPersonalData from '../desktop/layouts/Provider/pages/MyData/redux/reducer'

const rootReducer = combineReducers({
  toastr: toastrReducer,
  form: formReducer,
  evaluationReducer: evaluationReducer,
  provider: provider,
  providerPersonalData: providerPersonalData,
  evaluated: evaluated
});

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = applyMiddleware(thunk, multi, promise)(createStore)(
  rootReducer,
  devTools
);

export default store;
