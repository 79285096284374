import React, { Component } from 'react'
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb'
import { Col, Modal } from "react-bootstrap";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import orderBy from "lodash/orderBy";
import Button from "../../../../../components/customButton/CustomButton"
import './styles.css'
import { faSearch, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange, getCompanySatisfactionSurveys, clearState } from './redux/actions/evaluated'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { cpfMask, cnpjMask, currency } from '../../../../../utils/normalize'

import EvaluationModal from './EvaluationModal'
import EvaluationDetail from './EvaluationDetail'
import { toastr } from 'react-redux-toastr';

class Evaluated extends Component {
    state = {
        query: '',
        columnToSort: '',
        sortDirection: 'desc',
        columnToQuery: 'Description',
        showCheckboxes: false,
        loading: false,
        filtered: [],
        idCompany: localStorage.getItem('ID_EMPRESA'),
        sourceId: localStorage.getItem('SOURCE_ID'),
        showItens: false,
        showAvaliacao: false,
        evaluationDetails: {},
        arrayEvl: []
    }
    

    componentDidMount = async () => {
        this.setState({ loading: true })
        try {
            await this.props.getCompanySatisfactionSurveys(this.state.sourceId, this.state.idCompany)
            if (this.props.evaluated.tipo !== '' || this.props.evaluated.indica !== '') {
                await this.handleFilter()
            }
        } catch (err) {
            console.log(err);
            toastr.warning('Ocorreu um erro ao buscar as avaliações. Por favor, tente novamente')
        }
        this.setState({ loading: false })
    }

    componentWillUnmount() {
        this.props.clearState()
    }

    filterList = async (type, value) => {
        await this.props.handleChange(value, type)
        this.handleFilter()
    }

    handleFilter = async () => {
        const { tipo, indica, data, sourceId } = this.props.evaluated
        let saleFilter = data

        if (tipo !== '') {
            saleFilter = saleFilter.filter(c => c.type === tipo)
        }

        if (indica !== '') {
            if (sourceId === 1) {
                const value2 = indica === 'sim' ? 1 : 0
                saleFilter = saleFilter.filter(c => c.indicate === value2)
            } else {
                const value2 = indica === 'sim' ? true : false
                saleFilter = saleFilter.filter(c => c.indicate === value2)
            }
        }
        await this.props.handleChange(saleFilter, 'dataFilter')
    }

    handleEvaluationDetail = async company => {
        await this.setState({ evaluationDetails: company })
        this.setState({ showItens: true })
    }

    showModalEvl = async (evaluated) => {
        await this.setState({ showAvaliacao: true, arrayEvl: evaluated })
    }
    

    render() {
        const { query, showItens, showAvaliacao, loading, evaluationDetails, arrayEvl } = this.state
        const { dataFilter, tipo, indica } = this.props.evaluated
        const lowerCaseQuery = this.state.query.toLowerCase();
        return (
            <div>
                <BreadCrumb data={['Início', 'Minhas Avaliações']}
                    path={['home', 'evaluated']} />
                <Col xs={12} sm={12} md={12} lg={12} id="homeHeader">
                    <Col md={6} id="input-search" style={{ marginRight: '10px' }}>
                        <i id="iconepesquisar" className="fa fa-search"></i>
                        <input type="text" style={{ paddingLeft: '30px', width: '69%' }}
                            className="form-control foco-input"
                            placeholder="Pesquisar por Cliente ou Placa"
                            value={query}
                            onChange={e => this.setState({ query: e.target.value })}
                        />
                    </Col>
                    <Col md={4} className='comboHeader'>
                        <span style={{ marginRight: '10px' }}>Tipo: </span>
                        <select value={tipo} onChange={e => this.filterList('tipo', e.target.value)} className='form-control foco-input col-md-6'>
                            <option value=''>Ambos</option>
                            <option value='Venda'>Venda</option>
                            <option value='Ordem de Serviço'>Ordem de Serviço</option>
                        </select>
                    </Col>
                    <Col md={3} className='comboHeader' style={{ right: '22%' }}>
                        <span style={{ marginRight: '10px' }}>Indicação: </span>
                        <select value={indica} onChange={e => this.filterList('indica', e.target.value)} className='form-control foco-input col-md-6'>
                            <option value=''>Ambos</option>
                            <option value='sim'>Sim</option>
                            <option value='não'>Não</option>
                        </select>
                    </Col>
                </Col>
                <ReactTable
                    style={{ width: '100%', textAlign: 'center' }}
                    data={orderBy(query ?
                        dataFilter.filter(x =>
                            x['client'].toString().toLowerCase().includes(lowerCaseQuery) ||
                            x['vehicle'].toString().toLowerCase().includes(lowerCaseQuery) ||
                            x['value']?.toString().toLowerCase().includes(lowerCaseQuery)
                        )
                        :

                        dataFilter,
                        this.state.columnToSort,
                        this.state.sortDirection
                    )}
                    onClick={() => this.setState({ filtered: [] })}
                    columns={[
                        {
                            Header: "Tipo",
                            accessor: "type",
                            headerClassName: "text-center",
                            width: 150,
                            Cell: props =>
                                props.value !== "-" ?
                                    <Button id='btnTipo' style={{ backgroundColor: props.value === 'Venda' ? 'green' : 'red' }} >
                                        {props.value}</Button> :
                                    "-"
                        },
                        {
                            Header: "Nº",
                            accessor: "code",
                            headerClassName: "text-center",
                            width: 100,
                        },
                        {
                            Header: "Data",
                            accessor: "date",
                            headerClassName: "text-center",
                            width: 100,
                            Cell: props => <span>{new Date(props.value).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")
                            .split("/").reverse().join("/")}</span>

                        },
                        {
                            Header: "Cliente",
                            accessor: "client",
                            headerClassName: "text-center",
                            width: 300,
                        },
                        {
                            Header: "Veículos",
                            accessor: "vehicle",
                            headerClassName: "text-center",
                            width: 150,
                        },
                        {
                            Header: "Valor",
                            accessor: "value",
                            headerClassName: "text-center",
                            width: 100,
                            Cell: props => <span>{currency(props.value)}</span>,
                        },
                        {
                            Header: "Detalhes",
                            accessor: "Avaliacao",
                            headerClassName: "text-center",
                            width: 100,
                            Cell: props => <FontAwesomeIcon icon={faSearch} onClick={() => this.handleEvaluationDetail(props.original)} />
                        },
                        {
                            Header: "Avaliações",
                            accessor: "Avaliacao",
                            headerClassName: "text-center",
                            width: 100,
                            Cell: props => <FontAwesomeIcon icon={faStar} style={{ color: '#ffc107' }} onClick={() => this.showModalEvl(props.original)} />
                        },
                    ]}
                    defaultPageSize={5}
                    className=""
                    loading={this.state.loading}
                    showPagination={true}
                    sortable={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    previousText='Anterior'
                    nextText='Próximo'
                    loadingText='Carregando...'
                    noDataText='Não há informação'
                    pageText='Página'
                    ofText='de'
                    rowsText='linhas'
                />

                <Modal
                    animation={true}
                    show={showItens}
                    onHide={() => this.setState({ showItens: false })}
                    onCancel={() => this.setState({ showItens: false })}
                    dialogClassName='modal-evaluation-details'
                >
                    <Modal.Header>
                        <Modal.Title className='modal-evaluation-details-header'>
                            <h5>{evaluationDetails.client}</h5>
                            <p><strong>Venda: {evaluationDetails.code}</strong> (Realizada em {evaluationDetails?.date && new Date(evaluationDetails.date).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")
                            .split("/").reverse().join("/")})</p>
                            {evaluationDetails.customerCpfCnpj &&
                                (
                                    evaluationDetails.customerCpfCnpj.trim().length < 14
                                        ? <p><strong>CPF: </strong>{cpfMask(evaluationDetails.customerCpfCnpj)}</p>
                                        : <p><strong>CNPJ: </strong>{cnpjMask(evaluationDetails.customerCpfCnpj)}</p>
                                )
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EvaluationDetail onCancel={() => this.setState({ showItens: false })} company={evaluationDetails} />
                    </Modal.Body>
                </Modal>

                <Modal
                    animation={true}
                    show={showAvaliacao}
                    onHide={() => this.setState({ showAvaliacao: false })}
                    onCancel={() => this.setState({ showAvaliacao: false })}
                    dialogClassName='modal-evaluation-details'
                >
                    <Modal.Header>
                        <Modal.Title className='modal-evaluation-details-header'>
                            <h5>{arrayEvl.client}</h5>
                            <p><strong>Venda: {arrayEvl.code}</strong> (Realizada em {arrayEvl?.date && new Date(arrayEvl.date).toISOString().substring(0, 10).replace("-", "/").replace("-", "/")
                            .split("/").reverse().join("/")})</p>
                            {arrayEvl.customerCpfCnpj &&
                                (
                                    arrayEvl.customerCpfCnpj.length === 14
                                        ? <p><strong>CNPJ: </strong>{cnpjMask(arrayEvl.customerCpfCnpj)}</p>
                                        : <p><strong>CPF: </strong>{cpfMask(arrayEvl.customerCpfCnpj)}</p>
                                )
                            }
                            {/* {arrayEvl.Phone &&
                                <p><strong>Telefone: </strong>{phoneMask(arrayEvl.Phone)}</p>
                            } */}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EvaluationModal company={arrayEvl} />

                    </Modal.Body>
                    <Modal.Footer>
                        <button className='myconfig-temp-buttons btn-mycfg-danger' onClick={() => this.setState({ showAvaliacao: false })}>Voltar</button>

                    </Modal.Footer>
                </Modal>

                {loading && <LoadingSpinner loading={loading} icon={faStar} />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    evaluated: state.evaluated
})

const mapDispatchToProps = dispatch => bindActionCreators({ handleChange, getCompanySatisfactionSurveys, clearState }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Evaluated)