import MyData from "./pages/MyData";
import Home from "./pages/Home/Dashboard"
import MyConfig from "./pages/MyConfig"
import Evaluated from "./pages/Evaluated/Evaluated"

const providerRoutes = [
  {
    path: "/my-data",
    name: "Página Inicial",
    component: MyData,
    layout: "/provider"
  },
  {
    path: "/home",
    name: "Página Inicial",
    component: Home,
    layout: "/provider"
  },
  {
    path: "/my-config",
    name: "Minhas Configurações",
    component: MyConfig,
    layout: "/provider"
  },
  {
    path: "/evaluated",
    name: "Minhas Avaliações",
    component: Evaluated,
    layout: "/provider"
  },
];

export default providerRoutes;