import React from "react";
import "./index.css";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import ClientLayout from "./mobile/layouts/Client";
import SignUp from "./pages/signUp";
import Companies from './mobile/pages/Companies'
import Home from "./mobile/pages/Home";
import Details from "./mobile/pages/Details";

const Mobile = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/client/login" component={Login} />
    <Route exact path="/client/signup" component={SignUp} />
    <Route exact path="/client/newpassword" component={Login} />
    <Route path="/client" render={props => <ClientLayout {...props} />} />

    <Route exact path="/provider/login" component={Login} />
    <Route exact path="/provider/newpassword" component={Login} />
    <Route path="/provider" render={props => <ClientLayout {...props} />} />
    <Route exact path="/companies" component={Companies} />
    <Route exact path="/companies/details/:sourceId/:id" component={Details} />

  </Switch>
);

export default Mobile;
