export const FILES_TYPE_ID = {
  IMAGE: 1
}

export const PHONES_TYPE_ID = {
  WHATSAPP: 5,
  CELL_PHONE: 4,
  RESIDENTIAL: 2
}

export const SOURCE_ID = {
  QUERIDO_CARRO: 1,
  OS_DIGITAL: 2
}