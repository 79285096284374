import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'

import WizardForm from './WizardForm'

import service from '../../../../services/api'
import { vehiclesByLicensePlate } from '../../../../utils/request'

function newVehicle({ history }) {

    async function checkVehicle(License_Plate) {
        const response = await vehiclesByLicensePlate(License_Plate);
        let check;
        try {
            if (response.payload.length > 0) {
                return (check = true);
            }
        } catch {
            return (check = false);
        }
        return check;
    }

    async function submit(values) {
        const { licensePlate, brand, model } = values
        const check = await checkVehicle(licensePlate);

        if (check) return toastr.warning("Veículo já cadastrado");

        try {
            await service.api.post(service.config.endpoint + `vehicles`, {
                    licensePlate,
                    brand,
                    model,
                    userId: localStorage.getItem("ID_USUARIO")
                }
            );
            history.push("vehicles");
            return toastr.success("Veículo cadastrado com sucesso!");
        } catch (err) {
            console.log(err)
            return toastr.error("Erro ao cadastrar veículo");
        }
    }

    return <WizardForm onSubmit={submit} />
}

export default withRouter(newVehicle)