import React from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDd_wUrgYH9Bu2l8LU1Mi7LSoX62yDqsW8",
    loadingElement: < div style={{ height: `100%` }} />,
    containerElement: < div style={{ height: `100%` }} />,
    mapElement: < div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }}
    center={{ lat: parseFloat(props.lat), lng: parseFloat(props.lng) }}
  >

    {props.isMarkerShown && props.markers.map((marker, index) => (
      <Marker key={index} position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }} title={marker.tradingName} />
    ))}

  </GoogleMap>
)

class MyFancyComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMarkerShown: true,
      Cidade: this.props.city,
      States: this.props.states,
      latitude: 0.0,
      longitude: 0.0
    }
  }

  componentDidMount() {
    this.getLatLong()
  }

  getLatLong = async () => {
    var _this = this
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.Cidade}+${this.state.States}&key=AIzaSyDd_wUrgYH9Bu2l8LU1Mi7LSoX62yDqsW8`)
      .then(function (response) {
        return response.json();
      }).then(function (data) {
        _this.setState({ latitude: data.results[0].geometry.location.lat, longitude: data.results[0].geometry.location.lng })
      })
  }

  render() {
    return (
      <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
        markers={this.props.list}
        defaultCenter={this.props.list[0]}
        lat={this.state.latitude}
        lng={this.state.longitude}
      />
    )
  }
}

export default MyFancyComponent