import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
  return (
    <>
      <div className="mobile-footer-container" />
      <div className="mobile-footer-copy-right">
        <span>
          <FontAwesomeIcon icon="copyright" color="#798da3" />
          {' '} 2020 Work Inteligência
      </span>
        <span>Todos os direitos reservados</span>
      </div>
    </>
  );
};