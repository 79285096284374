import React from "react";
import SideNav from './SideNav'
import HeaderNav from './HeaderNav'
import { withRouter } from "react-router-dom";
import { useEffect } from "react";


function Provider({ history }) {

    useEffect(() => {
        if (
            !localStorage.getItem('ID_EMPRESA') ||
            localStorage.getItem('ID_EMPRESA') === '' ||
            !localStorage.getItem('NOME_EMPRESA') ||
            localStorage.getItem('NOME_EMPRESA') === ''
        ) {
            history.push('/provider/login')
        }
    }, [history])

    return (
        <div className="wrapper">
            <SideNav />
            <HeaderNav />
        </div>
    );
}

export default withRouter(Provider)
