import React from 'react'
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { createTextMask } from 'redux-form-input-masks';
import ReduxFormInput from '../../../components/reduxFormInput/ReduxFormInput'
import validate from '../../../utils/validateFormSignUp'
import { connect } from 'react-redux'
import { vehiclesByLicensePlate } from '../../../utils/request'
import { searchVehicleByLicensePlate } from '../../../utils/request'
import { isMobile } from '../../../utils/validation'

let SelectLicensePlate = (props) => {
    const myCustomMaskDefinitions = {
        9: {
            regExp: /[0-9]/,
        },
        A: {
            regExp: /[A-Za-z]/,
            transform: char => char.toUpperCase(),
        },
        f: {
            regExp: /^[a-z0-9]+$/i,
            transform: char => char.toUpperCase(),
        }
    }

    const PlacaMask = createTextMask({
        pattern: 'AAA9f99',
        guide: false,
        maskDefinitions: myCustomMaskDefinitions,
    })

    const verifyLicensePlate = async event => {
        const { licensePlate } = props
        if (licensePlate?.length === 7) {

            const response = await vehiclesByLicensePlate(licensePlate)

            if (response.payload.length > 0) {
                props.dispatch(change('signUpForm', 'LicensePlateExist', props.licensePlate))
            }
            else getVehicleData()
        }
    }

    const getVehicleData = async () => {
        const { licensePlate } = props

        if (licensePlate?.toString().length === 7) {

            const res = await searchVehicleByLicensePlate(props.licensePlate)

            if (res.payload) {
                props.dispatch(change('signUpForm', 'brand', res.payload.brand))
                props.dispatch(change('signUpForm', 'model', res.payload.model))
                props.dispatch(change('signUpForm', 'foundLicensePlate', true))
            }
            else props.dispatch(change('signUpForm', 'foundLicensePlate', false))

        }

        props.handleSubmit()

    }

    return (
        <>
            <div style={{ textAlign: 'center', alignItems: 'center' }}>
                <h4 style={{ margin: 0 }}><b>Placa do Veículo</b></h4>
                <p style={{ fontSize: 'small' }}>Informe a placa do seu veículo</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Field
                    name="licensePlate"
                    component={ReduxFormInput}
                    onChangeCapture={() => props.dispatch(change('signUpForm', 'LicensePlateExist', false))}
                    {...PlacaMask}
                />
            </div>
            <br />
            {
                isMobile() ?
                    <div className='signup-form-div-buttons'>
                        <button id='btnAvancar' type="submit" onClick={verifyLicensePlate} className="btn btn-success" >Avançar</button>
                        {window.location.href.includes('signup') &&
                            <button id='btnAvancar' onClick={props.previousPage} className="btn btn-danger" >Voltar</button>
                        }
                    </div>
                    :
                    <div className='signup-form-div-buttons'>
                        {window.location.href.includes('signup') &&
                            <button id='btnAvancar' onClick={props.previousPage} className="btn btn-danger" >Voltar</button>
                        }
                        <button id='btnAvancar' type="submit" onClick={verifyLicensePlate} className="btn btn-success" >Avançar</button>
                    </div>
            }
        </>
    )
}

SelectLicensePlate.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

SelectLicensePlate = reduxForm({
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // a unique name for the form
    form: 'signUpForm',
    validate
})(SelectLicensePlate)

const selector = formValueSelector('signUpForm')

const mapStateToProps = state => ({
    licensePlate: selector(state, 'licensePlate')
})

export default connect(mapStateToProps)(SelectLicensePlate)