const INITIAL_STATE = {

  selectedCity: {},
  serviceTypes: [],
  segments: [],
  branchs: [],
  more: false,
  loading: false,
  appendLoading: false,
  resultSearchEnd: false,
  openFilterModal: false,
  offset: 0,
  totalCount: 0,

  companies: [],
  companiesFiltered: [],

  filters: {
    segments: [],
    branchs: [],
    services: [],
  },

  filtersVisible: false,
  maps: false,


  Excelente: false,
  MuitoBom: false,
  Razoavel: false,
  Ruim: false,
  Horrivel: false,
  companyEvaluation: [],
  currencyEvaluation: [],
  filtroAvaliacoes: [],
  filtroSelecionado: []
}

const changeProvider = {
  Excelente: false,
  MuitoBom: false,
  Razoavel: false,
  Ruim: false,
  Horrivel: false,
  companyEvaluation: [],
  currencyEvaluation: [],
  filtroAvaliacoes: [],
  filtroSelecionado: [],

  openFilterModal: false
}




export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'HANDLE_CHANGE':
      return { ...state, [action.payloadType]: action.payload }

    case 'HANDLE_CHANGE_FILTERS':
      return { ...state, [action.payloadType]: action.payload, filtersVisible: false }

    case 'HANDLE_CHANGE_CITY':
      return {...state, selectedCity: action.payload, offset: 0, resultSearchEnd: false, totalCount: 0, appendLoading: false, loading: false, companies: [], companiesFiltered: [] }

    case 'LOAD_SERVICE_TYPES_SUCCESS':
      return { ...state, serviceTypes: action.payload }


    case 'LOAD_SEGMENTS_SUCCESS':
      return { ...state, segments: action.payload }

    case 'LOAD_BRANCH_SUCCESS':
      return { ...state, branchs: action.payload }

    case 'LOAD_COMPANIES_BY_CITY_STARTED':
      return {...state, loading: true, maps: false, companies: [], companiesFiltered: [] }

    case 'APPEND_COMPANIES_BY_CITY_STARTED':
      return {...state, appendLoading: true }

    case 'APPEND_COMPANIES_BY_CITY_SUCCESS':
      return {...state, loading: false, companies: state.companies.concat(action.payload), appendLoading: false }

    case 'SEARCH_RESULT_END':
      return {...state, resultSearchEnd: true, appendLoading: false }

    case 'LOAD_COMPANIES_BY_CITY_SUCCESS':
      return { 
        ...state, 
        companies: state.companies.concat(action.payload),
        loading: false
      }

    case 'LOAD_COMPANIES_BY_CITY_FAILURE':


      return { ...state, loading: false }

    case 'FINISH_FILTER_COMPANIES':
      return { ...state, companiesFiltered: action.payload.companies, filtersVisible: true, offset: state.offset + 10 }

    case 'HANDLE_FILTERS_FAILURE':
      const clearedFilters = {
        segments: [],
        branchs: [],
        services: [],
      }

      return {...state, openFilterModal: true, filters: clearedFilters, companiesFiltered: state.companies, more: false }

    case 'CLEAR_FILTERS':
      const clearFilters = {
        segments: [],
        branchs: [],
        services: [],
      }

      return { ...state, filters: clearFilters, companiesFiltered: state.companies, filtersVisible: false, more: false }

    case 'OPEN_MAP':
      return { ...state, [action.payloadType]: action.payload }

    case 'CHANGE_PROVIDER':
      return { ...state, ...changeProvider }

    default:
      return state
  }
}
