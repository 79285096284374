import React, { useState, useEffect } from 'react'
import Welcome from '../Welcome'
import PasswordInput from '../../../../components/passwordInput/PasswordInput'
import services from '../../../../services/api'
import { toastr } from 'react-redux-toastr'

export default function FirstLogin({ company, handleSubmit, logo }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [companyId, setcompanyId] = useState('')
  const [loading, setLoading] = useState(false)

  const [messageConfirmPassword, setMessageConfirmPassword] = useState(null)

  const { cpfCnpj, name, tradingName, sourceId, sourceCompanyId, street, streetNumber, neighborhood, city, state, country, zipcode,
    latitude, longitude } = company

  useEffect(() => {
    if (company.email) {
      setEmail(company.email)
    }
    if (company.sourceId === 2) {
      setcompanyId(company.id)
    }
  }, [])

  const verifyEqualsPassword = () => {
    if (confirmPassword !== password) {
      setMessageConfirmPassword('As senhas não coincidem, por favor, verifique.')
    }
  }

  const updateCompany = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      if (sourceId === 1) {
        const company = await services.api.post(services.config.endpoint + `companies`, {
          cpfCnpj, name, tradingName, email, password, sourceId, sourceCompanyId,
          street, streetNumber, neighborhood, city, state, country, zipcode,
          latitude, longitude
        })

        localStorage.setItem('ID_EMPRESA', company.data.id)
      } else if (sourceId === 2) {
        let newPassword = true
        await services.api.post(services.config.endpoint + `companies/update-pass-os/${companyId}/${cpfCnpj}`, {
          email, password, newPassword
        })
      }

      handleSubmit()
    } catch (err) {
      toastr.warning('Ops! ocorreu um erro ao salvar os dados', 'Por favor, tente novamente')
    } finally{
      setLoading(false)
    }
  }

  return (
    <form onSubmit={updateCompany} className='provider-login-step-1'>
      <div>
        <center>
          <img src={logo} alt="Sobre Rodas" />
          <Welcome name={!!company.tradingName ? company.tradingName : company.name} />
          <small>Preencha os dados abaixo para acessar</small>
        </center>

        <small><strong>E-mail</strong></small>
        <input type='text' placeholder='Informe o e-mail da sua empresa.' value={email} onChange={e => setEmail(e.target.value)} />

        <small><strong>Senha</strong></small>
        <PasswordInput maxLength="18" value={password} placeholder='Digite a senha.' onChange={e => setPassword(e.target.value)} />

        <small><strong>Confirme a Senha</strong></small>
        <PasswordInput
          value={confirmPassword}
          placeholder='Confirme a senha.'
          onChange={e => setConfirmPassword(e.target.value)}
          onFocus={() => setMessageConfirmPassword(null)}
          onBlur={verifyEqualsPassword}
          meta={{ touched: !!messageConfirmPassword, error: messageConfirmPassword }}
          maxLength="18"
        />
      </div>
      <br />

      <button 
        type='submit' 
        className='btn btn-success' 
        disabled={password === '' || email === '' || confirmPassword === '' || password !== confirmPassword || loading}
      >
        <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} style={{ marginRight: 5 }} />
        Próximo
      </button>
    </form>
  )
}