import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { withRouter } from 'react-router-dom';
import {
  getItemsMaintances,
  getHistoricMaintances,
} from '../../../../../utils/request';
import { currency } from '../../../../../utils/normalize';
import { SOURCE_ID } from '../../../../../utils/constrants';

const Details = ({ company, onCancel }) => {
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getEvaluationDetails = async () => {
      setLoading(true);
      let res;
      if (company.sourceId === SOURCE_ID.OS_DIGITAL) {
        res = await getItemsMaintances('', '', company.id);

        let items = res.payload;

        let resManutencao = await getHistoricMaintances(company.vehicle);
        let maintances = resManutencao.payload;

        items = items.map((item) => ({
          Description: item.Description,
          Quantity: item.Quantity,
          Value_Unity: item.Unit_Value,
          Discount: item.Discount_Value,
          Value: item.Amount,
        }));

        let maintance = maintances.osSales.find(
          (sale) => sale.id === company.id
        );

        if (maintance) {
          setSubTotal(maintance.SubTotal);
          setTotal(maintance.Final_Value);
          setDiscount(maintance.Discount_Value);
        }

        setTableData(items);

        setLoading(false);
      } else if (company.sourceId === SOURCE_ID.QUERIDO_CARRO) {
        res = await getItemsMaintances(
          company.code,
          company.cpfCnpj,
          company.id
        );

        let items = res.payload;

        items = items.map((item) => ({
          Description: item.Descricao,
          Quantity: item.Ociquanti,
          Value_Unity: item.OciprcTab,
          Discount: item.Ocidescto,
          Value: item.Ocisubtot,
        }));

        let arrayTotals = items.map((item) => item.Value);
        let arraySubTotal = 0;

        if (!!arrayTotals.length) {
          arraySubTotal = items
            .map((item) => item.Value_Unity * item.Quantity)
            .reduce((accumulated, actual) => accumulated + actual);
        }

        setSubTotal(arraySubTotal);
        setTotal(company.value);
        setDiscount(arraySubTotal - company.value);

        setTableData(items);

        setLoading(false);
      }
    };

    getEvaluationDetails();
  }, [
    company.code,
    company.cpfCnpj,
    company.id,
    company.sourceId,
    company.value,
    company.vehicle,
  ]);

  return (
    <div>
      <ReactTable
        style={{ textAlign: 'center' }}
        data={tableData}
        onClick={() => this.setState({ filtered: [] })}
        columns={[
          {
            Header: 'Descrição',
            accessor: 'Description',
            headerClassName: 'text-center',
            width: 'fit-content',
          },
          {
            Header: 'Quantidade',
            accessor: 'Quantity',
            headerClassName: 'text-center',
            width: 100,
          },
          {
            Header: 'Valor Unitário',
            accessor: 'Value_Unity',
            headerClassName: 'text-center',
            width: 150,
            Cell: (props) => <span>{currency(props.value)}</span>,
          },
          {
            Header: 'Desconto(%)',
            accessor: 'Discount',
            headerClassName: 'text-center',
            width: 150,
            Cell: (props) => (
              <span>
                {parseFloat(props.value).toFixed(2).replace('.', ',')}%
              </span>
            ),
          },
          {
            Header: 'Total',
            accessor: 'Value',
            headerClassName: 'text-center',
            width: 150,
            Cell: (props) => <span>{currency(props.value)}</span>,
          },
        ]}
        loading={loading}
        defaultPageSize={8}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
      <div className="modal-evaluation-details-footer">
        <div>
          <p>
            <strong>Sub-Total: </strong>
            {currency(subTotal)}
          </p>
          <p>
            <strong>Desconto: </strong>
            {currency(discount)}
          </p>
          <p>
            <strong>Valor Total: </strong>
            {currency(total)}
          </p>
        </div>

        <button className="btn btn-danger" onClick={onCancel}>
          Voltar
        </button>
      </div>
    </div>
  );
};

export default withRouter(Details);
