import React, { useEffect, useRef } from 'react'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'

export default function SearchInput(props){
  const searchInput = useRef(null);

  useEffect(()=>{
    // current property is refered to input element
    searchInput.current.focus();
 },[])

  return(
    <div className='search-homepage-input' {...props}>
      <input type='text' ref={searchInput} {...props} />
      <FontAwesomeIcon icon={faSearchLocation} />
    </div>
  )
}