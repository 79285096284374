import React from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserShield, faCar, faSearch, faUserCheck } from '@fortawesome/free-solid-svg-icons'

export default function ProgressBarComponent(props){
    return (
        <ProgressBar
            percent={props.percent}
            filledBackground="rgb(4, 182, 4)"
            unfilledBackground='linear-gradient(to right, rgb(214, 255, 220), rgb(177, 250, 188))'
            style={{ height: '5px' }}
        >
            <Step transition="scale">
                {({ accomplished }) => (
                    <div
                        id={accomplished ? 'div-step-success' : 'div-step'} className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* ICON */}
                        <FontAwesomeIcon  id={accomplished ? 'icon-step-success' : 'icon-step'} icon={faUserShield} className="iconeMenu" aria-hidden="false" />
                    </div>
                )}
            </Step>

            <Step transition="scale">
                {({ accomplished }) => (
                    <div
                        id={accomplished ? 'div-step-success' : 'div-step'} className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* ICON */}
                        <FontAwesomeIcon  id={accomplished ? 'icon-step-success' : 'icon-step'} icon={faSearch} className="iconeMenu" aria-hidden="false" />
                    </div>
                )}
            </Step>

            <Step transition="scale">
                {({ accomplished }) => (
                    <div
                        id={accomplished ? 'div-step-success' : 'div-step'} className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* ICON */}
                        <FontAwesomeIcon id={accomplished ? 'icon-step-success' : 'icon-step'} icon={faCar} className="iconeMenu" aria-hidden="false" />
                    </div>
                )}
            </Step>

            <Step transition="scale">
                {({ accomplished }) => (
                    <div
                        id={accomplished ? 'div-step-success' : 'div-step'} className={`indexedStep ${accomplished ? "accomplished" : null}`}
                    >
                        {/* ICON */}
                        <FontAwesomeIcon id={accomplished ? 'icon-step-success' : 'icon-step'} icon={faUserCheck} className="iconeMenu" aria-hidden="false" />
                    </div>
                )}
            </Step>
        </ProgressBar>
    )
}