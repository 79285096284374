import React from 'react';
import './index.css';
import ListMaintances from './ListMaintances';
import Details from './Details';
import { Modal } from 'react-bootstrap';
import { getVehicles, getHistoricMaintances } from '../../../utils/request';

class Maintances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: '',
      vehicles: [],
      filtered: [],
      maintances: [],
      modalFotos: false,
      modal: false,
      dados: '',
      loading: false,
      message: '',
    };
  }
  async componentDidMount() {
    await this.vehicles();
    this.getMaintances();
  }

  async getMaintances() {
    const { vehicles } = this.state;
    let data = [];

    for (let i = 0; i < vehicles.length; i++) {
      let maintances = await getHistoricMaintances(vehicles[i].licensePlate);

      data.push(maintances.payload);
    }

    let realData = [];

    for (let i = 0; i < data.length; i++) {
      // LAÇO PARA CADA VEÍCULO

      let qcsales = data[i].qcSales;
      let ossales = data[i].osSales;

      for (let j = 0; j < qcsales.length; j++) {
        const phone = `${qcsales[j].Company.OfDDD.toString().trim()}${qcsales[
          j
        ].Company.OfTel.toString().trim()}`;
        let obj = {
          id:
            qcsales[j].sale.Orcodigo +
            ' ' +
            qcsales[j].Company.OfCnpjCpf.toString().trim(),
          code: qcsales[j].sale.Orcodigo,
          date: qcsales[j].sale.Orcdata,
          licensePlate: qcsales[j].sale.DadChapa.toString().trim(),
          type: 'qc',
          evaluated: qcsales[j].evaluated,
          Kilometers: 0,
          satisfactionSurvey: {
            Company_Cpf_Cnpj: qcsales[j].Company.OfCnpjCpf.toString().trim(),
            Rating_Product_Service: qcsales[j].sale.notaProduto,
            Rating_Price: qcsales[j].sale.notaPreco,
            Would_Indicate: qcsales[j].sale.indicariaOficina,
            Comment: qcsales[j].sale.comentarioCliente,
            createdAt: qcsales[j].sale.notaData,
          },
          Company: {
            id: qcsales[j].Company.OfCnpjCpf.toString().trim(),
            name: qcsales[j].Company.OfRzSocia.toString().trim(),
            address: qcsales[j].Company.OfEndere.toString().trim(),
            number: qcsales[j].Company.OfNumero.toString().trim(),
            cpfCnpj: qcsales[j].Company.OfCnpjCpf.toString().trim(),
            neighborhood: qcsales[j].Company.OfBairro.toString().trim(),
            phone,
          },
          Payments: {
            Amount: qcsales[j].sale.Orctotved,
          },
        };

        realData.push(obj);
      }

      for (let j = 0; j < ossales.length; j++) {
        let obj = {
          id: ossales[j].id,
          code: ossales[j].Code,
          date: ossales[j].Date,
          licensePlate: ossales[j].License_Plate,
          type: 'os',
          evaluated: ossales[j].evaluated,
          satisfactionSurvey: ossales[j].satisfactionSurvey,
          User_id: ossales[j].User_id,
          Kilometers: ossales[j].Kilometers,
          Company: {
            id: ossales[j].Company.id,
            name: ossales[j].Company.Trading_Name,
            address: '',
            number: '',
            cpfCnpj: ossales[j].Company.Cpf_Cnpj,
            phone: ossales[j].Company?.Phones[0]
              ? ossales[j].Company?.Phones[0].Number_Phone1 ||
                ossales[j].Company?.Phones[0].Number_Phone2
              : '',
          },
          // salesPhotos:data[i].osSales[x].SalesPhotos
          salesPhotos: ossales[j].SalesPhotos,
          Payments: ossales[j].Payments,
        };

        realData.push(obj);
      }
    }

    // ORDENANDO POR DATA E INVERTENDO
    realData = realData.sort((a, b) => a.date - b.date).reverse();

    this.setState({ maintances: realData });
    await this.setState({ filtered: realData });
    this.setState({ loading: false, message: 'Nenhuma informação encontrada' });
  }

  async vehicles() {
    this.setState({ loading: true });
    let user = await localStorage.getItem('ID_USUARIO');
    if (user) {
      const res = await getVehicles(user);

      this.setState({ vehicles: res.payload });
    }
  }

  handleChange = (select) => {
    const { maintances } = this.state;
    this.setState({ select });

    if (select !== '') {
      /// FILTRA POR PLACA
      let list = maintances.filter((c) => c.licensePlate === select);

      this.setState({ filtered: list });
    } else this.setState({ filtered: maintances }); // se selecionar TODOS VEICULOS, retorna todas vendas buscada
  };

  showDetalhes = (dadosVenda) => {
    this.setState({ modal: true });
    this.setState({ dados: dadosVenda });
  };

  showSurvey = async (sale) => {
    await localStorage.setItem('SALE', JSON.stringify(sale));

    this.props.history.push('satisfaction-survey');
  };

  render() {
    const { vehicles, dados, modal, filtered, select, loading, message } =
      this.state;

    return (
      <>
        <div className="container-maintance">
          <div>
            <center>
              <h3>
                <b>Minhas Manutenções</b>
              </h3>
            </center>
            <select
              className="foco-input form-control"
              value={select}
              onChange={(e) => this.handleChange(e.target.value)}
            >
              <option value="">Todos os veículos</option>
              {vehicles.map((v) => (
                <option key={v.id} value={v.licensePlate}>
                  {v.licensePlate}
                </option>
              ))}
            </select>
            <br />
          </div>
        </div>
        <div className="maintances">
          {loading ? (
            <span id="loading" className="fa fa-spinner fa-pulse fa-1x" />
          ) : (
            ''
          )}
          {filtered.length === 0 ? (
            <h5 id="msg-maitances">{message}</h5>
          ) : (
            <ListMaintances
              data={filtered}
              showDetalhes={this.showDetalhes}
              showSurvey={this.showSurvey}
            />
          )}
        </div>
        <Modal
          show={modal}
          onHide={() => this.setState({ modal: false })}
          animation={true}
        >
          <Modal.Body>
            {
              <Details
                dados={dados}
                fecharModal={() => this.setState({ modal: false })}
              />
            }
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default Maintances;
