import React from 'react'
import './styles.css'
import Logo from '../../../assets/img/sobre-rodas.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from 'react-router-dom'


function Header({ history }) {
  return (
    <div className='container-header'>
      <div className='container-header-logo' onClick={() => history.push('/')}>
        <img src={Logo} alt='Sobre Rodas' style={{ height: '56px', width: '100px' }} />
      </div>

      <div className='container-header-provider-area'>
        <button onClick={() => history.push('/client/login')}>
          <FontAwesomeIcon icon={faWrench} />HISTÓRICO DE MANUTENÇÃO
      </button>

        <button className="manutencao" onClick={() => history.push('/provider/login')}>
          <FontAwesomeIcon icon={faUser} />ÁREA DO PRESTADOR
      </button>
      </div>
    </div>
  )
}

export default withRouter(Header)