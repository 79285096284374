import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../customButton/CustomButton";
import '../../index.css'

export default function AlertModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      animation={props.animation}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Sobre Rodas</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <Button

            id="danger"
            name="Não"
            onClick={e => props.onCancel(e)}
            onTouchStart={e => props.onCancel(e)}
            fill
          >
            Não
          </Button>
          <Button
            className="marginBotaoAlerta"
            id="info"
            name="Sim"
            fill
            onClick={e => props.onSubmit(e)}
            onTouchStart={e => props.onSubmit(e)}
          >
            Sim
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
