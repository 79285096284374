import React from 'react';
import { reduxForm, formValueSelector, Field, change } from 'redux-form'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import PasswordInput from '../../../components/passwordInput/PasswordInput'
import { forName, maxLength } from '../../../utils/normalize'
import ReduxFormInput from '../../../components/reduxFormInput/ReduxFormInput'
import validate from '../../../utils/validateFormSignUp'
import services from '../../../services/api'
import { phoneMask } from '../../../utils/normalize'

let PersonalData = props =>{

  const validation = async (e) => {
    e.preventDefault();
    
    const verificarEmail = await services.api.get(services.config.endpoint + "users?email=" + props.email)
    
    if (verificarEmail.data.length!==0) return props.dispatch(change('signUpForm', 'emailDuplicado', true))

    props.handleSubmit()
  }

  return (
    <>
        <div style={{textAlign: 'center'}}>
          <h4 style={{margin: 0}}><b>Dados Pessoais</b></h4>
          <p style={{fontSize: 'small'}}>Informe os seus dados pessoais para a criação da conta</p>
        </div>
        <div style={{flexDirection: 'column', display: 'flex'}}>
            <label>Nome<span style={{ color: 'red' }}>*</span></label>
            <Field
                name="name"
                type="text"
                placeholder="Seu Nome"
                component={ReduxFormInput}
                normalize={forName}
            />
            <label>Email<span style={{ color: 'red' }}>*</span></label>
            <Field 
                name="email"
                type="email"
                onBlur={()=> props.dispatch(change('signUpForm', 'emailDuplicado', false))}
                placeholder="email@gmail.com"
                component={ReduxFormInput}
            />

            <label>Telefone<span style={{ color: 'red' }}>*</span></label>
            <Field
                name="phone"
                type="tel"
                component={ReduxFormInput}
                normalize={(value)=>phoneMask(value)}
            />
            
            <label>Senha<span style={{ color: 'red' }}>*</span></label>
            <span style={{fontSize: '10px'}}>Mínimo 6, máximo 18 caracteres</span>
            <Field
              name="password"
              component={PasswordInput}
              normalize={value => maxLength(value, 18)}
            />
            
            <label>Confirme a Senha<span style={{ color: 'red' }}>*</span></label>
            <Field
              name="password2"
              component={PasswordInput}
              normalize={value => maxLength(value, 18)}
            />

            <br/>
            <button type="submit"  style={{width:'100%'}} onClick={(e)=>validation(e)} className="btn btn-success" >Avançar</button>
            <span style={{textAlign: 'center'}} id='link' onClick={()=>props.history.push('/client/login')}>Já possui conta? Ir para Login</span>
        </div>
    </>
    )
}

PersonalData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

PersonalData = reduxForm({
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // a unique name for the form
    form: 'signUpForm',
    validate
  })(PersonalData)

  const selector = formValueSelector('signUpForm')

  const mapStateToProps = state => ({
      email: selector(state, 'email')
  })

export default connect(mapStateToProps)(withRouter(PersonalData)) 
