import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'

import './styles.css'
import ProgressBarComponent from './progressBar/ProgressBar'
import PersonalData from './steps/PersonalData.js'
import SelectLicensePlate from './steps/SelectLicensePlate.js'
import VehicleData from './steps/VehicleData.js'
import Confirmation from './steps/Confirmation.js'
import LoginFooter from '../../components/LoginFooter'

const FormSignUp = ({ onSubmit, loading }) => {
  const [step, setStep] = useState(1)

  const nextPage = () => setStep(step + 1)

  const previousPage = () => setStep(step - 1)

  const selector = useSelector(state => state.form.signUpForm);

  return (
    <>
      <div className="sign-up-container">
        <Col xs={10} sm={8} md={8} lg={4} className='sign-up-main-container'>
          <div className='sign-up-main'>
            <div>
              <ProgressBarComponent percent={step === 1 ? 0 : step === 2 ? 33.33 : step === 3 ? 66.66 : 100} />
              <br />
              <div className='sign-up-form'>
                {step === 1 && <PersonalData onSubmit={nextPage} />}
                {step === 2 && <SelectLicensePlate onSubmit={nextPage} previousPage={previousPage} />}
                {step === 3 && <VehicleData onSubmit={nextPage} previousPage={previousPage} />}
                {step === 4 && <Confirmation onSubmit={onSubmit} loading={loading} previousPage={previousPage} />}
              </div>
            </div>
          </div>
        </Col>
      </div>
      <LoginFooter active={selector?.active} />
    </>
  )
}

FormSignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // a unique name for the form
  form: 'signUpForm'
})(FormSignUp)

