import React, { Component } from 'react'
import { Col } from "react-bootstrap";
import 'react-table-6/react-table.css'
import './styles.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange, getCompanySatisfactionSurveys, clearState } from './redux/actions/evaluated'
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rating from '@material-ui/lab/Rating';


class Evaluated extends Component {
    state = {
        query: '',
        columnToSort: '',
        sortDirection: 'desc',
        columnToQuery: 'Description',
        showCheckboxes: false,
        loading: false,
        filtered: [],
        idCompany: localStorage.getItem('ID_EMPRESA'),
        showItens: false,
        showAvaliacao: false,
        evaluationDetails: {}
    }

    render() {
        const company = this.props.company
        return (
            <div style={{ width: '550px' }}>
                <Col xs={12} sm={12} md={12} lg={12} id='colModalBody'>
                    <Col id='cardEvaluation'>
                        {
                            company.indicate ?
                                <>
                                    <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: '60px', color: 'green' }} />
                                    <span id='spanIndica' style={{ color: 'green' }}>Indicaria</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon={faThumbsDown} style={{ fontSize: '60px', color: 'red' }} />
                                    <span id='spanIndica' style={{ color: 'red' }}>Não indicaria</span>
                                </>
                        }
                    </Col>
                    <Col id='cardEvaluation'>

                        <h2 id='spanNumber'>{company.ratingProduct}</h2>
                        <Rating value={company.ratingProduct} readOnly
                            name="Rating_Price" defaultValue={2} size="medium" />
                        <span>Produto/Serviço</span>
                    </Col>
                    <Col id='cardEvaluation'>
                        <h2 id='spanNumber'>{company.ratingPrice}</h2>
                        <Rating value={company.ratingPrice} readOnly
                            name="Rating_Price" defaultValue={2} size="medium" />
                        <span>Preço</span>
                    </Col>
                </Col>
                <Col md={12} style={{ marginTop: '15px' }}>
                    <p>{company.comments}</p>
                </Col>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    evaluated: state.evaluated
})

const mapDispatchToProps = dispatch => bindActionCreators({ handleChange, getCompanySatisfactionSurveys, clearState }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Evaluated)