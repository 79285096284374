import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "./styles.css"
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { faCog, faFileAlt, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes from "../routes";

export default function SideNavProvider() {
  const [expanded, setExpanded] = useState(false)
  const [pathSelected, setPathSelected] = useState(localStorage.getItem('selectedPath'))

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/provider") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <div
      style={{
        marginLeft: expanded ? 155 : 0,
        padding: "0px 40px 0px 50px"
      }}
    >
      <div className='main-panel'>
        <Switch>{getRoutes(routes)}</Switch>
      </div>
      <Route render={({ location, history }) => (
        <React.Fragment >
          <SideNav
            id="colorMenu"
            expanded={expanded}
            onToggle={expanded => {
              if (expanded) {
                localStorage.setItem("EXPANDED", 2);
              } else {
                localStorage.setItem("EXPANDED", 1);
              }
              setExpanded(expanded);
            }}
            onSelect={selected => {
              if (selected !== "fixed") {
                const to = '/' + selected;
                if (location.pathname !== to) {
                  localStorage.setItem("selectedPath", selected)
                  history.push(to);

                  setPathSelected(selected)
                }
              }
            }}
          >
            <SideNav.Nav defaultSelected={pathSelected}>
              <NavItem id="colorMenu" eventKey="fixed">
                <NavIcon>
                  <SideNav.Toggle
                    id="side-toggle"
                    onClick={() => {
                      if (expanded) {
                        setExpanded(false);
                        localStorage.setItem("EXPANDED", 1);
                      } else {
                        setExpanded(true);
                        localStorage.setItem("EXPANDED", 2);
                      }
                    }}
                  />
                </NavIcon>
              </NavItem>

              <NavItem id="colorMenu" eventKey="provider/home" title="Visão Geral">
                <NavIcon>
                  <i className="fa fa-fw fa-home iconeMenu" />
                </NavIcon>
                <NavText style={{ color: "white" }}>Visão Geral</NavText>
              </NavItem>

              <NavItem id="colorMenu" eventKey="provider/my-data" title="Meus Dados">
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    className="iconeMenu"
                    aria-hidden="false"
                  />
                </NavIcon>
                <NavText style={{ color: "white" }}>Meus Dados
                  </NavText>
              </NavItem>

              <NavItem id="colorMenu" eventKey="provider/my-config" title="Minhas Configurações">
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faCog}
                    className="iconeMenu"
                    aria-hidden="false"
                  />
                </NavIcon>
                <NavText style={{ color: "white" }}>Minhas Configurações</NavText>
              </NavItem>

              <NavItem id="colorMenu" eventKey="provider/evaluated" title="Minhas Avaliações">
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faStar}
                    className="iconeMenu"
                    aria-hidden="false"
                  />
                </NavIcon>
                <NavText style={{ color: "white" }}>Minhas Avaliações</NavText>
              </NavItem>
            </SideNav.Nav>
          </SideNav>
        </React.Fragment>
      )}
      />
    </div>
  );
}
