import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import ReduxFormInput from '../../../components/reduxFormInput/ReduxFormInput'
import validate from '../../../utils/validateFormSignUp'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { getBrandsOSDigital, getBrandsByDescriptionOSDigital } from "../../../utils/request";
import { isMobile } from '../../../utils/validation';

const SelectBrand = props => (
    <>
        <select
            className="form-control foco-input"
            value={props.brand}
            {...props}
            {...props.select}
            onChange={(event) => props.handleSelect(event.target.value)}
            style={{ width: '50%' }}
        >
            <option value="">Selecione</option>
            {props.data.map(d => (
                <option value={d.Brand} key={d.Brand}>{d.Brand}</option>
            ))}
        </select>
        {props.meta.touched && props.meta.error && <span style={{
            color: 'red',
            textAlign: 'left',
            display: 'block',
            paddingLeft: '10px',
            fontSize: '12px'
        }}>{props.meta.error}</span>}
    </>
)

const SelectModel = props => (
    <>
        <select
            className="form-control foco-input"
            value={props.model}
            {...props}
            {...props.select}
            onChange={(event) => props.changeModel(event.target.value)}
        >
            <option value="">Selecione</option>
            {props.data.map(d => (
                <option value={d.Model} key={d.Model}>{d.Model}</option>
            ))}
        </select>
        {props.meta.touched && props.meta.error && <span style={{
            color: 'red',
            textAlign: 'left',
            display: 'block',
            paddingLeft: '10px',
            fontSize: '12px'
        }}>{props.meta.error}</span>}
    </>
)

let VehicleData = props => {
    const [models, setModels] = useState([])
    const [brands, setBrands] = useState([])


    useEffect(() => {

        const loadBrands = async () => {
            const brands = await getBrandsOSDigital();
            setBrands(brands.payload)
            if (props.brand) {
                handleSelect(props.brand)
            }
        }
        loadBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.brand])


    const handleSelect = async (value) => {
        const brand = value
        const models = await getBrandsByDescriptionOSDigital(brand)
        setModels(models.payload)
        props.dispatch(change('signUpForm', 'brand', value))
    }

    const changeModel = async (value) => {
        let model = value

        props.dispatch(change('signUpForm', 'model', model))
    }

    const handleEdit = () => {
        props.dispatch(change('signUpForm', 'foundLicensePlate', false))
        props.dispatch(change('signUpForm', 'brand', undefined))
        props.dispatch(change('signUpForm', 'model', undefined))
    }

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <h4 style={{ margin: 0 }}><b>Dados do Veículo</b></h4>
                <p style={{ fontSize: 'small' }}>{props.foundLicensePlate ? 'Altere ou confirme os dados do seu veículo para acompanhar o histórico' : 'Informe os dados do seu veículo para acompanhar o histórico.'}</p>
            </div>
            <div>
                <label>Placa<span style={{ color: 'red' }}>*</span></label>
                <Field
                    style={{ width: '50%' }}
                    name="licensePlate"
                    type="text"
                    component={ReduxFormInput}
                    disabled={true}
                />

                <label>Marca<span style={{ color: 'red' }}>*</span></label>
                {/* CHECAR SE OS DADOS DO VEÍCULO VEIO COM A PLACA */}
                {props.foundLicensePlate ?
                    <div style={{ position: 'relative', width: '50%' }}>
                        <Field
                            name="brand"
                            component={ReduxFormInput}
                            disabled={true}
                            type="text"
                        />
                        <span style={{ position: 'absolute', right: '6px', top: '6px' }}>
                            <FontAwesomeIcon
                                icon={faEdit}
                                style={{ cursor: 'pointer', color: 'black' }}
                                onClick={handleEdit}
                                aria-hidden="false"
                            />
                        </span>
                    </div>
                    :
                    <Field
                        name="brand"
                        brand={props.brand}
                        data={brands}
                        type="text"
                        component={SelectBrand}
                        handleSelect={handleSelect}
                    />
                }

                <label>Modelo<span style={{ color: 'red' }}>*</span></label>
                {props.foundLicensePlate ?
                    <Field
                        name="model"
                        component={ReduxFormInput}
                        disabled={true}
                        type="text"
                    />
                    :
                    <Field
                        name="model"
                        model={props.model}
                        data={models}
                        type="text"
                        component={SelectModel}
                        changeModel={changeModel}
                    />
                }


                <br />
                {
                    isMobile() ?
                        <div className='signup-form-div-buttons'>
                            <button id='btnAvancar' type="submit" onClick={props.handleSubmit} className="btn btn-success" >Avançar</button>
                            <button id='btnAvancar' onClick={props.previousPage} className="btn btn-danger" >Voltar</button>
                        </div>
                        :
                        <div className='signup-form-div-buttons' style={window.location.href.includes('signup') && !isMobile() ? {} : { justifyContent: 'center' }}>
                            <button id='btnAvancar' onClick={props.previousPage} className="btn btn-danger" style={window.location.href.includes('signup') ? {} : { marginRight: '10px' }} >Voltar</button>
                            <button id='btnAvancar' type="submit" onClick={props.handleSubmit} className="btn btn-success" >Avançar</button>
                        </div>
                }
            </div>
        </div>
    )

}

VehicleData.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

VehicleData = reduxForm({
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // a unique name for the form
    form: 'signUpForm',
    validate
})(VehicleData)

const selector = formValueSelector('signUpForm')

const mapStateToProps = state => ({
    model: selector(state, 'model'),
    brand: selector(state, 'brand'),
    licensePlate: selector(state, 'licensePlate'),
    foundLicensePlate: selector(state, 'foundLicensePlate')
})

export default connect(mapStateToProps)(VehicleData)
