import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceTypes, getBranchs, getSegments } from '../../../utils/request'
import { addFilters, removeFilter } from '../../../store/actions/provider'

export default function () {
  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { serviceTypes, branchs, segments, filters } = provider

  useEffect(() => {
    dispatch([getServiceTypes(), getSegments(), getBranchs()])

  }, [dispatch])

  const handleCheckBoxes = (object, checked, type) => {

    if (checked) {
      dispatch(addFilters(object, type, filters))
    }
    else {
      dispatch(removeFilter(object, type, filters))
    }
  }

  return (
    <div className='container-filters'>
      <section>
        <h6>Veículos</h6>
        <div className='container-filters-grid'>
          {segments.filter(c => c.description !== 'Outros').map(c => {
            return (
              <div key={c.id}>
                <input
                  type='checkbox'
                  checked={!!filters.segments.find(segment => segment.id === c.id)}
                  onChange={e => handleCheckBoxes(c, e.target.checked, 'segments')}
                  id={c.description}
                />
                <label htmlFor={c.description}>{c.description}</label>
              </div>
            )
          })}
        </div>
      </section>

      <section>
        <h6>Ramo de Atuação</h6>
        <div className='container-filters-grid'>
          {branchs.map((c, i) => {
            return (
              <div key={c.id}>
                <input
                  type='checkbox'
                  checked={!!filters.branchs.find(branch => branch.id === c.id)}
                  onChange={e => handleCheckBoxes(c, e.target.checked, 'branchs')}
                  id={c.description + c.id}
                />
                <label htmlFor={c.description + c.id}>{c.description}</label>
              </div>
            )
          })}
        </div>
      </section>

      <section>
        <h6>Serviços</h6>
        <div className='container-filters-grid'>
          {serviceTypes.filter(s => s.description !== 'Autopeças').map(c => {
            return (
              <div key={c.id} style={{ justifyContent: 'left' }}>
                <input
                  type='checkbox'
                  checked={!!filters.services.find(service => service.id === c.id)}
                  onChange={e => handleCheckBoxes(c, e.target.checked, 'services')}
                  id={c.description}
                />
                <label htmlFor={c.description}>{c.description}</label>
              </div>
            )
          })}
        </div>
      </section>

    </div>
  )
}