import React, { useState, useEffect, useRef } from 'react'
import services from '../../../services/api'

import { Modal } from 'react-bootstrap'
import SearchInput from '../search-input'
import './styles.css'

export default function SearchLocationModal(props) {
  const [cities, setCities] = useState([])
  const [query, setQuery] = useState('')
  const [resultQuery, setResultQuery] = useState([])
  const [citiesNode, setCitiesNode] = useState([])
  const [focusedCityIndex, setFocusedCityIndex] = useState(-2)

  const citySelector = useRef(null)

  useEffect(() => {
    const getCities = async () => {
      const res = await services.api.get(services.config.endpoint + 'cities')

      setCities(res.data)
    }
    getCities()
  }, [])

  useEffect(() => {
    if (query.length >= 3) {
      setResultQuery(cities.filter(c => c.Name.toString().toLowerCase().includes(query.toLowerCase())))
    }
    else if (resultQuery.length > 0) {
      setResultQuery([])
    }

    //Reseta o index quando a pesquisa muda
    setFocusedCityIndex(-2)
    setCitiesNode(citySelector.current.children)
  }, [query, cities, resultQuery.length])

  useEffect(() => {
    if (citiesNode.length > 0 && focusedCityIndex >= 0) {
      citiesNode[focusedCityIndex].focus()
    }
  }, [focusedCityIndex])

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (focusedCityIndex < citiesNode.length - 1) setFocusedCityIndex(i => i + 1)
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      if (focusedCityIndex > 0) setFocusedCityIndex(i => i - 1)
    }

    if (event.key === 'Enter') {
      if (focusedCityIndex >= 0) citiesNode[focusedCityIndex].click()
    }
  }

  const renderResults = () => {
    if (resultQuery.length === 0 && query.length >= 3) {
      return (
        <h6 style={{ color: 'red', padding: 10 }}>Nenhuma cidade encontrada.</h6>
      )
    }

    return resultQuery.map(c => (
      <div
        key={c.id}
        onClick={() => props.onSelectCity(c)}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
      >
        <h6>{c.Name} - {c.States.Initials}</h6>
      </div>
    ))
  }

  return (
    <Modal {...props} >
      <Modal.Body >
        <div style={{ position: 'relative' }}>
          <SearchInput
            list='datalist-locations'
            placeholder='Aonde você está?'
            value={query}
            onChange={e => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div id='datalist-locations-options' ref={citySelector}>
            {renderResults()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}