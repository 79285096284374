const INITIAL_STATE = {
    tipo: '',
    indica: '',
    data: [],
    dataFilter: [],
    sourceId: 0
}

const clearState = {
    tipo: '',
    indica: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'HANDLE_CHANGE':
            return { ...state, [action.payloadType]: action.payload }
        case 'GET_EVALUATED':
            return { ...state, data: action.payload, dataFilter: action.payload, sourceId: action.payloadSourceId }
        case 'CLEAR_STATE':
            return { ...state, ...clearState }
        default:
            return state
    }
}
