import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const PasswordInput = (props)=>{
    const [type, setType] = useState(0);
    const [typeInput, setTypeInput] = useState('password')
    const [icon, setIcon] = useState(faEye)

    useEffect(()=>{
        if(type===1){
            setTypeInput('text')
            setIcon(faEye)
        }
        else {
            setTypeInput('password')
            setIcon(faEyeSlash)
        }
    }, [type])

    return(
        <>
        <div style={{display: 'flex', position: 'sticky'}}>
            <input  type={typeInput} style={{width:'100%'}} {...props} {...props.input} name={props.name}  className='form-control foco-input' autoComplete="new-password" />
            <span style={{position:'absolute', right: '10px', alignItems: 'center', height: '100%', display: 'flex' }} onClick={()=>setType(type === 1 ? 0 : 1)} ><FontAwesomeIcon icon={icon}/></span>
        </div>
        {props.meta?.touched && props.meta?.error && <span style={{
            color: 'red',
            textAlign: 'left',
            display: 'block',
            paddingLeft: '10px',
            fontSize: '12px'
        }}>{props.meta?.error}</span>}
        </>
    )
}

export default PasswordInput