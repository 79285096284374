import React, { Component } from 'react';
import { Col, Modal } from 'react-bootstrap';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import orderBy from 'lodash/orderBy';
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb';
import { faEdit, faStar } from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../../components/customButton/CustomButton';
import Detalhes from './Details';
import EvaluationView from './EvaluationView';
import {
  getHistoricMaintances,
  getVehicles,
  getCompany,
} from '../../../../../utils/request';
import { phoneMask, cpfOrCnpjMask } from '../../../../../utils/normalize';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  handleChange,
  closeModal,
} from '../../../../../components/evaluation/reduxEvaluation/evaluationAction';
import MaintancesPhotos from './MaintancesPhotos';

class Manutencoes extends Component {
  state = {
    data: [],
    query: '',
    columnToSort: '',
    sortDirection: 'desc',
    columnToQuery: 'Description',
    showCheckboxes: false,
    loading: false,
    filtered: [],
    modal: false,
    isPhotos: true,
    modalFotos: false,
    idUsuario: localStorage.getItem('ID_USUARIO'),
    values: 0,
    Company_Name: '',
    Address: '',
    Order_Service: 10,
    Date: '10/10/2000',
    modalAvaliacao: true,
    avaliacao: 0,
    infoModalEvaluation: 0,
    dataManutencao: '',
    salesPhotos: [],
  };

  componentDidMount = async () => {
    this.loadManutencao();
  };

  loadManutencao = async () => {
    this.setState({ loading: true });
    const res = await getVehicles(this.state.idUsuario);
    const vehicles = res.payload;

    const data = [];
    for (let i = 0; i < vehicles.length; i++) {
      let maintances = await getHistoricMaintances(vehicles[i].licensePlate);
      data.push(maintances.payload);
    }

    const maintancesQc = [];
    const maintancesOs = [];
    const regex = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';

    for (let i = 0; i < data.length; i++) {
      if (data[i].qcSales) {
        for (let j = 0; j < data[i].qcSales.length; j++) {
          let obj = {
            idManutencao: data[i].qcSales[j].sale.Orcodigo,
            Date: data[i].qcSales[j].sale.Orcdata,
            OS: data[i].qcSales[j].sale.Orcodigo,
            License_Plate: data[i].qcSales[j].sale.DadChapa,
            Company: data[i].qcSales[j].Company?.OfNomeFan,
            Value: data[i].qcSales[j].sale.Orctotved,
            Type: 'qc',
            Cpf_Cnpj: data[i].qcSales[j].Company?.OfCnpjCpf,
            Address: data[i].qcSales[j].Company?.OfEndere,
            Number: data[i].qcSales[j].Company?.OfNumero,
            Neighborhood: data[i].qcSales[j].Company?.OfBairro,
            Company_id: data[i].qcSales[j].Company?.OfCnpjCpf,
            Avaliacao: data[i].qcSales[j].evaluated,
            Kilometers: data[i].qcSales[j].sale.DadKm,
            Vehicle: data[i].qcSales[j].sale.OrcObjeto,
          };
          maintancesQc.push(obj);
        }
      }

      if (data[i].osSales) {
        for (let x = 0; x < data[i].osSales.length; x++) {
          console.log(data[i].osSales[x]);
          let obj = {
            idManutencao: data[i].osSales[x].id,
            Date: data[i].osSales[x].Date,
            OS: data[i].osSales[x].Code,
            License_Plate: data[i].osSales[x].License_Plate,
            Company: data[i].osSales[x].Company.Trading_Name.toUpperCase(),
            Value: data[i].osSales[x].Final_Value,
            Type: 'os',
            Cpf_Cnpj: data[i].osSales[x].Company.Cpf_Cnpj,
            Company_id: data[i].osSales[x].Company.id,
            Avaliacao: data[i].osSales[x].evaluated,
            Kilometers: data[i].osSales[x].Kilometers,
            Vehicle: data[i].osSales[x].Vehicle?.Model.toUpperCase(),
            User_id: data[i].osSales[x].User_id,
            phone: data[i].osSales[x].Company?.Phones[0]
              ? data[i].osSales[x].Company?.Phones[0].Number_Phone1 ||
                data[i].osSales[x].Company?.Phones[0].Number_Phone2
              : '',
            salesPhotos: data[i].osSales[x].SalesPhotos,
          };
          maintancesOs.push(obj);
        }
      }
    }
    this.setState({ data: [...maintancesQc, ...maintancesOs], loading: false });
  };

  showModal = async (evt) => {
    const dados = evt.original;
    this.setState({ modal: true, values: evt.original });
    let idEmpresa = dados.Company_id;

    if (idEmpresa) {
      if (dados.Type === 'qc') {
        const data = new Date(dados.Date)
          .toISOString()
          .substring(0, 10)
          .replace('-', '/')
          .replace('-', '/')
          .split('/')
          .reverse()
          .join('/');
        this.setState({
          Company_Name: dados.Company,
          Address:
            dados.Address + ', ' + dados.Number + ' - ' + dados.Neighborhood,
          Date: data,
          Order_Service: dados.OS,
        });
      } else {
        const res = await getCompany(idEmpresa);
        const rua = res.payload.Address;
        const numero = res.payload.Address_Number;
        const bairro = res.payload.Neighborhood;
        const data = new Date(dados.Date)
          .toISOString()
          .substring(0, 10)
          .replace('-', '/')
          .replace('-', '/')
          .split('/')
          .reverse()
          .join('/');
        this.setState({
          phone: dados.phone,
          Cpf_Cnpj: dados.Cpf_Cnpj,
          Company_Name: dados.Company,
          Address: rua + ', ' + numero + ' - ' + bairro,
          Date: data,
          Order_Service: dados.OS,
          salesPhotos: dados.salesPhotos,
          isPhotos: dados.salesPhotos.length > 0 ? false : true,
        });
      }
    }
  };

  showModalEvaluation(evt) {
    const dados = evt.original;
    const data = new Date(evt.original.Date).toLocaleDateString();
    this.setState({ infoModalEvaluation: dados, dataManutencao: data });
    this.props.handleChange(true, 'modalEvaluation');
  }

  render() {
    const { data, query } = this.state;
    const lowerCaseQuery = this.state.query.toLowerCase();
    const { modalEvaluation } = this.props.evaluationReducer;
    console.log(data);
    return (
      <div>
        <BreadCrumb
          data={['Início', 'Minhas Manutenções']}
          path={['dashboard', 'minhasManutencoes']}
        />
        <Col xs={12} sm={12} md={12} lg={12} id="homeHeader">
          <Col md={6} id="input-search" style={{ marginRight: '10px' }}>
            <i id="iconepesquisar" className="fa fa-search"></i>
            <input
              type="text"
              style={{ paddingLeft: '30px', width: '69%' }}
              className="form-control foco-input"
              placeholder="Pesquisar por Placa, Oficina e Ordem de Serviço"
              value={query}
              onChange={(e) => this.setState({ query: e.target.value })}
            />
          </Col>
        </Col>
        <ReactTable
          style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}
          data={orderBy(
            query
              ? data.filter(
                  (x) =>
                    x['License_Plate']
                      .toString()
                      .toLowerCase()
                      .includes(lowerCaseQuery) ||
                    x['Company']
                      .toString()
                      .toLowerCase()
                      .includes(lowerCaseQuery) ||
                    x['OS']?.toString().toLowerCase().includes(lowerCaseQuery)
                )
              : data,
            this.state.columnToSort,
            this.state.sortDirection
          )}
          onClick={() => this.setState({ filtered: [] })}
          columns={[
            {
              Header: 'Data',
              accessor: 'Date',
              headerClassName: 'text-center',
              width: 90,
              Cell: (props) => (
                <div>
                  {new Date(props.value)
                    .toISOString()
                    .substring(0, 10)
                    .replace('-', '/')
                    .replace('-', '/')
                    .split('/')
                    .reverse()
                    .join('/')}{' '}
                </div>
              ),
            },
            {
              Header: 'OS',
              accessor: 'OS',
              headerClassName: 'text-center',
              width: 80,
            },
            {
              Header: 'Placa',
              accessor: 'License_Plate',
              headerClassName: 'text-center',
              width: 90,
            },
            {
              Header: 'Veículo',
              accessor: 'Vehicle',
              headerClassName: 'text-center',
              width: 210,
            },
            {
              Header: 'Oficina',
              accessor: 'Company',
              headerClassName: 'text-center',
              width: 210,
            },
            {
              Header: 'KM',
              accessor: 'Kilometers',
              headerClassName: 'text-center',
              width: 100,
            },
            {
              Header: 'Valor',
              accessor: 'Value',
              headerClassName: 'text-center',
              width: 100,
              Cell: (props) => (
                <span>
                  R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}
                </span>
              ),
            },
            {
              Header: 'Detalhes',
              accessor: 'Acoes',
              headerClassName: 'text-center',
              width: 100,
              Cell: (props) => (
                <FontAwesomeIcon
                  icon={faWindowRestore}
                  onClick={() => this.showModal(props)}
                  style={{ cursor: 'pointer' }}
                  aria-hidden="false"
                />
              ),
            },
            {
              Header: '',
              accessor: 'Avaliacao',
              headerClassName: 'text-center',
              width: 180,
              Cell: (props) =>
                props.value ? (
                  <Button
                    id="botaoReactTable"
                    style={{ backgroundColor: 'blue' }}
                    onClick={() => this.showModalEvaluation(props)}
                  >
                    <FontAwesomeIcon icon={faStar} aria-hidden="false" /> Ver
                    Avaliação
                  </Button>
                ) : props.original.Type === 'qc' ? (
                  <Button
                    id="botaoReactTable"
                    disabled
                    title="Avaliação indisponível"
                    style={{
                      backgroundColor: 'gray',
                      opacity: '50%',
                      fontSize: '12px',
                    }}
                    onClick={() => this.showModalEvaluation(props)}
                  >
                    <FontAwesomeIcon icon={faStar} aria-hidden="false" />{' '}
                    Avaliação Indisponível
                  </Button>
                ) : (
                  <Button
                    id="botaoReactTable"
                    style={{ backgroundColor: 'red', fontSize: '12px' }}
                    onClick={() => this.showModalEvaluation(props)}
                  >
                    <FontAwesomeIcon icon={faEdit} aria-hidden="false" />{' '}
                    Avaliar
                  </Button>
                ),
            },
          ]}
          defaultPageSize={5}
          className=""
          loading={this.state.loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'Date',
              desc: true,
            },
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
        <Modal
          dialogClassName="heightModal"
          show={this.state.modal}
          onHide={() => this.setState({ modal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{this.state.Company_Name}</strong>
              <p id="tittle-Detalhes">
                {cpfOrCnpjMask(this.state.Cpf_Cnpj || '')}
              </p>
              <p id="tittle-Detalhes">
                <strong>Telefone: </strong> {phoneMask(this.state.phone || '')}
              </p>
              <p id="tittle-Detalhes">
                <strong>Endereço: </strong> {this.state.Address}
              </p>
              <p id="tittle-Detalhes">
                <strong>Nº Ordem de Serviço: </strong>
                {this.state.Order_Service}
              </p>
              <p id="tittle-Detalhes">
                <strong>Data: </strong>
                {this.state.Date}
              </p>

              <Button
                disabled={this.state.isPhotos}
                id="primary"
                name="Não"
                onClick={() => this.setState({ modalFotos: true })}
                fill
              >
                Visualizar fotos
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '90%', padding: '2' }}>
            <Detalhes values={this.state.values} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="danger"
              name="Não"
              onClick={() => this.setState({ modal: false })}
              fill
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName="heightModal"
          show={this.state.modalFotos}
          onHide={() => this.setState({ modalFotos: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Fotos</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '90%', padding: '2' }}>
            <MaintancesPhotos values={this.state.salesPhotos} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="danger"
              name="Não"
              onClick={() => this.setState({ modalFotos: false })}
              fill
            >
              Voltar
            </Button>
          </Modal.Footer>
        </Modal>

        {modalEvaluation && (
          <EvaluationView
            modalEvaluation={modalEvaluation}
            values={this.state.infoModalEvaluation}
            close={() => this.props.closeModal()}
            dataManutencao={this.state.dataManutencao}
            infoModalEvaluation={{
              Company: this.state.infoModalEvaluation.Company,
            }}
          />
        )}
        {/* <Modal dialogClassName="" show={modalEvaluation} onHide={() => this.props.closeModal()}>
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Avaliação</strong>
              <p id='tittle-Detalhes'><strong>Data: </strong>{this.state.dataManutencao}</p>
              <p id='tittle-Detalhes'><strong>Nome: </strong>{this.state.infoModalEvaluation.Company}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '90%', padding: '2' }}>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  evaluationReducer: state.evaluationReducer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleChange, closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Manutencoes);
