import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';
import Logo from '../../../assets/img/sobre-rodas.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const history = useHistory();

  return (
    <div>
      <div className='mobile-header' >
        <img src={Logo} alt='Sobre Rodas' onClick={() => history.push('/')}/>
        <button className="manutencao" onClick={() => history.push('/client/login')}>
          <FontAwesomeIcon icon={faWrench} />HISTÓRICO DE MANUTENÇÃO
          </button>
      </div>
    </div>
  )
};