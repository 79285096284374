import React, { useState, useEffect } from 'react'
import './styles.css'
import Header from '../../components/header'
import Footer from '../../components/footer'
import banner from '../../../assets/img/banner_qc_homepage.png'
import Indicators from './Indicators'
import Categories from './Categories'
import SearchInput from '../../components/search-input'
import SearchLocationModal from '../../components/search-location-modal'
import { getCounters, getTopServices } from '../../../utils/request'
import { useDispatch, useSelector } from 'react-redux'
import { handleChange, addFilters, clearFilters } from '../../../store/actions/provider'

import services from '../../../services/api';

export default function Home({ history }) {

  const [indicators, setIndicators] = useState({})
  const [serviceTypes, setServiceTypes] = useState([])
  const [openLocationModal, setOpenLocationModal] = useState(false)
  const [selectedCategorie, setSelectedCategorie] = useState(false)
  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { selectedCity, filters } = provider

  useEffect(() => {
    const loadServiceTypes = async () => {
      const { data } = await services.api.get(services.config.endpoint + 'branches?description=Autopeças')
      const branch = data[0]

      branch.id = 12;

      const res = await getTopServices()

      let servicesSorted = [
        ...res.payload.map(item => {
          if (item.Services.description === branch.description) { //TRATATIVA PARA ADICIONAR BRANCH DE AUTOPEÇAS            
            return {
              ...item,
              Services: branch,
              isBranch: false
            }
          } else return item
        }),
        {
          Services: {
            description: 'Mais'
          },
          icon: "ellipsis-h",
          more: true
        },
      ]
      setServiceTypes(servicesSorted)
    }
    loadServiceTypes()
    dispatch(clearFilters())
  }, [dispatch])

  useEffect(() => {
    const loadCounters = async () => {
      const res = await getCounters()
      setIndicators(res.payload)
    }
    loadCounters()
  }, [])

  const handleCity = city => {
    dispatch(handleChange(city, 'selectedCity'))

    setOpenLocationModal(false)

    if (selectedCategorie) {
      history.push('/companies')
    }
  }

  const handleCategorie = async categorie => {
    /// SE A CATEGORIA SELECIONADA FOR BRANCH (EX: AUTO PEÇAS)
    if (categorie.isBranch) {
      dispatch(addFilters(categorie.Services, 'branchs', filters))
    }
    else 
    if (!categorie.more) { // SE NÃO FOR A OPÇÃO 'MAIS'
      dispatch(addFilters(categorie.Services, 'services', filters))
    }

    else dispatch(handleChange(true, 'more'))

    setSelectedCategorie(true)

    if (!selectedCity?.id) {
      setOpenLocationModal(true)
    }
    else {
      history.push('/companies')
    }
  }

  return (
    <div className='container-homepage'>
      <Header />
      <div className='div-banner-homepage' >
        <img src={banner} alt='Banner Sobre Rodas' />
        <section className='section-homepage-input'>
          <SearchInput
            placeholder={selectedCity?.id ? `Você está em: ${selectedCity.Name + ', ' + selectedCity.States.Initials}` : 'Aonde você está?'}
            onClick={() => {
              setOpenLocationModal(true)
            }}
            disabled={true}
          />
        </section>
        <section className='section-homepage-services'>
          <div>
            <Categories data={serviceTypes} onSelectCategorie={handleCategorie} />
          </div>
        </section>
      </div>
      <br />
      <br />
      <br />
      <div className='indicators-section'>
        <center>
          <h2>Informações dos nossos clientes</h2>
          <p>Atualizado em {`${indicators.updatedAt?.toString().split('T')[0].split('-').reverse().join('/')}`}</p>
        </center>
        <div>
          <Indicators
            icon="tools"
            message='Oficinas e autopeças disponíveis em todo território nacional'
            value={indicators.companies}
          />
          <Indicators
            icon="car"
            message='Veículos atendidos'
            value={indicators.vehicles}
          />
          <Indicators
            icon="file-invoice-dollar"
            message='Vendas e ordens de serviços realizados'
            value={indicators.sales}
          />
          <Indicators
            icon="dolly"
            message='Itens vendidos'
            value={indicators.items}
          />
        </div>
      </div>
      <br />
      <br />
      <div>
        <Footer />
      </div>
      {openLocationModal &&
        <SearchLocationModal
          style={{
            position: 'fixed',
            top: '30%'
          }}
          show={openLocationModal}
          onHide={() => setOpenLocationModal(false)}
          onSelectCity={handleCity}
        />
      }
    </div>
  )
}