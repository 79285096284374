import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../../components/breadCrumb/BreadCrumb";
import CardForm from "../../../../components/CardForm";
import MyPeronalData from "./MyPeronalData";
import MyPhotos from "./MyPhotos";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChange,
  updateProviderData,
  fetchProviderPersonalData,
  updateProviderImages,
} from "./redux/action";
import AlertModal from "../../../../../components/alertModal/AlertModal";
import { toastr } from "react-redux-toastr";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

export default function MyData({ history }) {
  const [myPersonalData, setMyPersonalData] = useState(true);
  const [myFotos, setMyFotos] = useState(false);
  const [cancelEditModal, setCancelEditModal] = useState(false);
  const companyId = localStorage.getItem("ID_EMPRESA");
  const sourceId = localStorage.getItem("SOURCE_ID");
  const companyCNPJ = localStorage.getItem("CNPJ");
  const dispatch = useDispatch();
  const { providerPersonalData } = useSelector((state) => state);
  const { editData, loading } = providerPersonalData;

  useEffect(() => {
    dispatch({ type: "INITIAL_STATE" });

    dispatch(fetchProviderPersonalData(sourceId, companyId, companyCNPJ));

    return () => dispatch({ type: "INITIAL_STATE" });
  }, [companyCNPJ, companyId, dispatch, sourceId]);

  const validate = (e) => {
    e.preventDefault();
    const {
      name,
      tradingName,
      email,
      zipcode,
      street,
      streetNumber,
      neighborhood,
      city,
      state,
      phone,
    } = providerPersonalData;
    if (
      tradingName === "" ||
      email === "" ||
      zipcode === "" ||
      street === "" ||
      streetNumber === "" ||
      neighborhood === "" ||
      city === "" ||
      state === "" ||
      phone === "" ||
      name === ""
    ) {
      return toastr.warning(
        "Por favor, verifique os campos obrigatórios antes de salvar."
      );
    }
    dispatch(updateProviderData(sourceId, companyId, history));
  };

  return (
    <form onSubmit={validate}>
      <BreadCrumb path={["home", null]} data={["Início", "Meus Dados"]} />
      <CardForm
        show={myPersonalData}
        title="Meus Dados"
        onClick={() => setMyPersonalData(!myPersonalData)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MyPeronalData />
          <div className="myconfig-footer">
            {editData && (
              <button
                type="submit"
                disabled={loading}
                className="myconfig-temp-buttons btn-mycfg-success"
              >
                Salvar
              </button>
            )}
            {!editData && (
              <button
                type="button"
                className="myconfig-temp-buttons btn-mycfg-info"
                disabled={loading}
                onClick={() => dispatch(handleChange(true, "editData"))}
              >
                Editar Dados
              </button>
            )}
          </div>
        </div>
      </CardForm>
      <CardForm
        show={myFotos}
        title="Minhas Fotos"
        onClick={() => setMyFotos(!myFotos)}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MyPhotos />
          <div className="myconfig-footer">
            <button
              type="button"
              onClick={() =>
                dispatch(updateProviderImages(sourceId, companyId, history))
              }
              disabled={loading}
              className="myconfig-temp-buttons btn-mycfg-success"
            >
              Salvar
            </button>
          </div>
        </div>
      </CardForm>

      <AlertModal
        show={cancelEditModal}
        message="Deseja realmente cancelar as alterações? Esta ação não salvará as informações alterada em tela."
        onHide={() => setCancelEditModal(false)}
        onCancel={() => setCancelEditModal(false)}
        onSubmit={() => history.push("home")}
      />

      {loading && <LoadingSpinner loading={loading} icon={faFileAlt} />}
    </form>
  );
}
