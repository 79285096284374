
const validate = values => {
    const errors = {}

    if (!values.password) {
        errors.password = 'Obrigatório!'
    } else if (values.password.length < 6) {
        errors.password = 'Senha menor que 6 carácteres!'
    }

    if (!values.password2) {
        errors.password2 = 'Obrigatório!'
    } else if (values.password2 && values.password2 !== values.password) {
        errors.password2 = 'As Senhas não coincídem'
        errors.styles = { borderColor: "#d9534f" }
    }

    return errors
}

export default validate