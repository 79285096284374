import React, { useState } from 'react';
import '../styles.css';
import {
  onlyNumbers,
  maxLength,
  cpfOrCnpjMask,
} from '../../../../utils/normalize';
import services from '../../../../services/api';
import { toastr } from 'react-redux-toastr';

export default function CnpjStep({ nextOption, logo }) {
  const [cnpj, setCnpj] = useState('');
  const [loading, setLoading] = useState(false);

  const searchCnpj = async (e) => {
    e.preventDefault();
    setLoading(true);

    let cnpjClean = cnpj.replace(/\D/g, '');

    try {
      const res = await services.api.get(
        services.config.endpoint + `companies/by-cnpj/${cnpjClean}`
      );

      if (res.data.cpfCnpj.length === 11) {
        console.log('Passou aqui CPF!');
        nextOption(res.data);
      } else {
        console.log('Passou aqui CNPJ!');
        nextOption(res.data);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        toastr.warning(
          'O CNPJ não foi encontrado em nossa base dados, por favor, verifique.'
        );
      } else {
        console.log(err.message);
        toastr.warning('Ocorreu um erro ao realizar a busca');
      }
    }

    setLoading(false);
  };

  return (
    <form onSubmit={searchCnpj} className="provider-login-step-0">
      <center>
        <img src={logo} alt="Sobre Rodas" />
        <h4>Área do Prestador</h4>
      </center>
      <div>
        <small>Insira o seu CPF/CNPJ abaixo para acessar</small>
        <input
          type="text"
          placeholder="Informe o CPF/CNPJ da sua empresa"
          value={cnpj}
          onChange={(e) =>
            setCnpj(cpfOrCnpjMask(onlyNumbers(maxLength(e.target.value, 18))))
          }
        />
      </div>

      <button
        type="submit"
        className="btn btn-success"
        disabled={cnpj === '' || loading}
      >
        Próximo
        <span
          className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
          style={{ marginLeft: '5px' }}
        />
      </button>
    </form>
  );
}
