import React from 'react';
import './styles.css';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HeaderDetail() {
  const history = useHistory();

  return (
      <div className="mobile-detail-header-container">
        <FontAwesomeIcon icon="home" onClick={() => history.push('/')} />
        <a href="#overview">Visão Geral</a>
        <a href="#details">Detalhes</a>
        <a href="#rating">Avaliações</a>
      </div>
  );
};