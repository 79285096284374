import React, { useState, useEffect } from 'react';
import './index.css';
import { Col, Tabs, Tab } from 'react-bootstrap';
import ListProducts from './ListProducts';
import ListServices from './ListServices';
import { withRouter } from 'react-router-dom';
import { getItemsMaintances, getCompany } from '../../../utils/request';
import { cpfOrCnpjMask, phoneMask } from '../../../utils/normalize';
import { Modal } from 'react-bootstrap';
import MaintancesPhotos from './MaintancesPhotos';

function Details(props) {
  const { dados } = props;
  const [key, setKey] = useState('oficina');
  const [endereco, setEndereco] = useState([]);
  const [produtos, setProduto] = useState([]);
  const [servicos, setServico] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isPhotos, setIsPhotos] = useState(false);
  const [modalPhotos, setModalPhotos] = useState(false);
  const [totalProduto, setTotalProduto] = useState(0);
  const [totalServico, setTotalServico] = useState(0);
  let data = dados.date
    .toString()
    .substring(0, 10)
    .replace('-', '/')
    .replace('-', '/')
    .split('/')
    .reverse()
    .join('/');

  useEffect(() => {
    const getDadosEmpresa = async () => {
      let idEmpresa = dados.Company.id;

      if (idEmpresa) {
        if (dados.type === 'qc') {
          setEndereco(
            dados.Company.address +
              ' ' +
              dados.Company.number +
              ' - ' +
              dados.Company.neighborhood
          );
        } else {
          const res = await getCompany(idEmpresa);
          const rua = res.payload.Address;
          const numero = res.payload.Address_Number;
          const bairro = res.payload.Neighborhood;
          setEndereco(rua + numero + ' - ' + bairro);
        }
      }
    };
    getDadosEmpresa();
  }, [
    dados.Company.address,
    dados.Company.id,
    dados.Company.neighborhood,
    dados.Company.number,
    dados.type,
  ]);

  useEffect(() => {
    const getItens = async () => {
      let idVenda = dados.id;
      let data = [];
      let realData = [];

      if (idVenda) {
        if (dados.type === 'qc') {
          const qcItem = await getItemsMaintances(
            dados.code,
            dados.Company.cpfCnpj
          );
          data.push(qcItem.payload);
        } else {
          const osItem = await getItemsMaintances('', '', dados.id);
          data.push(osItem.payload);
        }
        for (let i = 0; i < data.length; i++) {
          // LAÇO PARA CADA ITEM
          // For para "unir" os dados em um unico objeto para ter os mesmos campos
          for (let j = 0; j < data[i].length; j++) {
            if (data[i][j].TipoItem) {
              let obj = {
                id: data[i][j].Orcodigo,
                descricao: data[i][j].Descricao,
                quantidade: data[i][j].Ociquanti,
                valorUnitario: data[i][j].OciprcTab,
                total: data[i][j].Ocisubtot,
                type: data[i][j].TipoItem === 1 ? 'Produto' : 'Serviço',
                review: `${data[i][j].OciKmRevi || 0} km` || '',
                warranty: `${data[i][j].OciGarKm || 0} km` || '',
              };
              realData.push(obj);
            } else {
              const item = !!data[i][j].Product_id
                ? data[i][j].Products
                : data[i][j].Services;

              let obj = {
                id: data[i][j].id,
                descricao: data[i][j].Description,
                quantidade: data[i][j].Quantity,
                valorUnitario: data[i][j].Unit_Value
                  ? data[i][j].Unit_Value
                  : data[i][j].Value_Cost,
                total: data[i][j].Amount,
                type: data[i][j].Type,
                review: !!item.Review
                  ? `${item.Review.Km} km ou ${item.Review.Months} meses`
                  : '',
                warranty: !!item.Warranty
                  ? `${item.Warranty.Kilometers} km ou ${item.Warranty.Months} meses`
                  : '',
              };
              realData.push(obj);
            }
            // separando produto e serviço
            let produtos = realData.filter((p) => p.type === 'Produto');
            setProduto(produtos);
            let servicos = realData.filter((p) => p.type === 'Serviço');
            setServico(servicos);

            //Somando valor total de produtos
            let totalProdutos = produtos.map((x) => parseFloat(x.total));
            if (totalProdutos[0]) {
              setTotalProduto(
                totalProdutos.reduce((total, atual) => total + atual)
              );
            }

            //Somando valor total de serviços
            let totalServicos = servicos.map((x) => parseFloat(x.total));
            if (totalServicos[0]) {
              setTotalServico(
                totalServicos.reduce((total, atual) => total + atual)
              );
            }
          }
        }
      }
    };
    getItens();
    const temPhotos = dados.salesPhotos
      ? dados.salesPhotos.length > 0
        ? false
        : true
      : true;
    setIsPhotos(temPhotos);
    setPhotos(dados.salesPhotos);
  }, [dados, dados.Company.cpfCnpj, dados.code, dados.id, dados.type]);

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="oficina" title="Oficina">
          <Col style={{ marginTop: '6%' }}>
            <h5>
              <strong>{dados.Company.name}</strong>
            </h5>
            <label>{cpfOrCnpjMask(dados.Company.cpfCnpj || '')}</label>
            <br />
            <label>
              <strong>Telefone: </strong>
              {phoneMask(dados.Company.phone || '')}
            </label>
            <label>{endereco || 'Sem endereço informado'}</label>
            <hr className="margintopHr"></hr>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <p>
                <strong>Nº O.S.: </strong>
                {dados.code}
              </p>
              <p>
                <strong>Data: </strong>
                {data}
              </p>
            </div>
          </Col>
          <hr className="margintopHr"></hr>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="detalhesmanutencao">
              <p>Produtos</p>
              <p>
                {totalProduto.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            <div className="detalhesmanutencao">
              <p>Serviços</p>
              <p>
                {totalServico.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            <div className="detalhesmanutencao">
              <p>
                <strong>Total: </strong>
              </p>
              <p>
                {dados.Payments.Amount.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            <button
              disabled={isPhotos}
              id="primary"
              onClick={() => setModalPhotos(true)}
              className="btn btn-primary form-control"
            >
              Visualizar Fotos
            </button>
          </Col>
          <hr></hr>
        </Tab>
        <Tab eventKey="produto" title="Produtos" id="produto" key="produto">
          <ListProducts produtos={produtos} />
        </Tab>
        <Tab eventKey="servico" title="Serviços" id="servico" key="servico">
          <ListServices servicos={servicos} />
        </Tab>
      </Tabs>
      <div className="col-md-10" style={{ marginTop: '20%' }}>
        <button
          id="danger"
          onClick={() => props.fecharModal()}
          className="form-control"
        >
          Voltar
        </button>
      </div>
      <Modal
        show={modalPhotos}
        onHide={() => setModalPhotos(false)}
        animation={true}
      >
        <Modal.Body>
          <MaintancesPhotos values={photos} />
          <button
            id="danger"
            onClick={() => setModalPhotos(false)}
            className="form-control"
          >
            Voltar
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default withRouter(Details);
