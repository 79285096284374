import React from 'react';
import './styles.css';

export default function ProgressBar({ barWidth = '100%', width = '0%' }) {
  return (
    <div
      id="progress-bar-compoent-container"
      style={{ width: barWidth }}
    >
      <div
        id="progress-bar-componet-main"
        style={{ width, maxWidth: '100%' }}
      />
    </div>
  );
};