const INITIAL_STATE = {

    ratingProductService: 0,
    ratingPrice: 0,
    wouldIndicate: null,
    comments: '',
    modalEvaluation: false,
    disabled: '',
    loading: false
}

const closeModal = { 
    ratingProductService: 0,
    ratingPrice: 0,
    wouldIndicate: null,
    comments: '',
    modalEvaluation: false,
    disabled: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'HANDLE_CHANGE':
            return { ...state, [action.payloadType]: action.payload }
        case 'HANDLE_CREATE':
            // action.modal != 0 ? state.showModal = true : state.showModal = false
            return { ...state, modalEvaluation: action.payload, loading: false }
        case 'LOAD_EVALUATION':
            return { ...state, ...action.payload }
        case 'CLOSE_MODAL':
            return { ...state, ...action.payload, ...closeModal}

        case 'CREATE_EVALUATION_STARTED':
            return { ...state, loading: true }

        case 'CREATE_EVALUATION_FAILURE':
            return { ...state, loading: false }


        default:
            return state
    }
}
