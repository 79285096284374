import React, { Component } from "react";
import { toastr } from "react-redux-toastr";

import Input from "../../components/passwordInput/PasswordInput";
import { validadeEmail } from "../../utils/validation";

import services from "../../services/api";
import { withRouter } from "react-router-dom";

import CryptoJS from 'crypto-js'

class Login extends Component {
  state = {
    email: "",
    password: "",
    redirect: ""
  };

  componentDidMount() {
    this.checkURL();

    if (localStorage.getItem("ID_USUARIO")) {
      this.checkLoggedUser();
    }
  }

  checkLoggedUser = async () => {
    if (localStorage.getItem("ID_USUARIO")) {
      try {
        const response = await services.api.get(
          services.config.endpoint + "users/by-id",
          {
            headers: {
              id: localStorage.getItem("ID_USUARIO")
            }
          }
        );
        localStorage.setItem("NOME_USUARIO", response.data.name);
        this.props.history.push("/client/dashboard");
      } catch (err) {
        console.log(err);
      }
    }
  };


  checkURL = async () => { /// MÉTODO PARA CHECAR SE O LINK JÁ É COM O USUÁRIO LOGADO
    const params = new URLSearchParams(this.props.location.search) // classe para pegar queryString da URL
    let userEmail = params.get('email')
    let userPassword = params.get('pass')
    if (params.get('redirect')) {
      let location = params.get('redirect')
      this.setState({ redirect: location })
    }

    if (userEmail && userPassword) {
      localStorage.clear()
      /// TROCANDO ESPAÇO EM BRANCO POR + DO HASH
      while (userEmail.toString().includes(' ') || userPassword.toString().includes(' ')) {
        userEmail = userEmail.replace(' ', '+')
        userPassword = userPassword.replace(' ', '+')
      }

      let email = await CryptoJS.AES.decrypt(userEmail, "userEmail"); // MÉTODO PARA DECRIPTAR string hasheada 
      let password = await CryptoJS.AES.decrypt(userPassword, "userPassword"); // MÉTODO PARA DECRIPTAR string hasheada 

      email = email.toString(CryptoJS.enc.Utf8)
      password = password.toString(CryptoJS.enc.Utf8)

      if (email && password) {
        await this.setState({
          email,
          password
        })
        this.handleSubmit()
      }
    }

  }


  handleSubmit = async event => {
    if (event) event.preventDefault();

    const { email, password } = this.state;
    try {
      const response = await services.api.post(services.config.endpoint + "users/login", {
        email: email,
        password: password
      });

      localStorage.setItem("NOME_USUARIO", response.data.name);
      localStorage.setItem("ID_USUARIO", response.data.id);

      toastr.success("Login efetuado com sucesso!");
      if (this.state.redirect !== "") {
        setTimeout(() => this.props.history.push(`/client/${this.state.redirect}`), 2000);
      } else {
        //Redirect to Dashboard
        setTimeout(() => this.props.history.push("/client/dashboard"), 2000);
      }


    } catch (err) {
      return toastr.warning(
        "Dados incorretos. Por favor, verifique novamente o e-mail e senha"
      );
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: "20px",
            alignItems: "center"
          }}
        >
          <span style={{ marginTop: "10px" }}>Informe os dados abaixo</span>
        </div>
        <form>
          <div>
            <label>
              <strong>E-mail</strong>
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className="form-control foco-input"
              onChange={event =>
                this.setState({
                  email: event.target.value.replace(/\s/g, "").toLowerCase()
                })
              }
              value={email}
            />
          </div>
          <div>
            <label>
              <strong>Senha</strong>
              <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              autoComplete="new-password"
              className="form-control foco-input"
              onChange={event =>
                this.setState({
                  password: event.target.value.replace(/\s/g, "")
                })
              }
              value={password}
              maxLength="18"
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center"
            }}
          >
            <span id="link" onClick={() => this.props.handleOption(1)}>
              Esqueci minha senha
            </span>
            <button
              type="submit"
              onClick={this.handleSubmit}
              disabled={
                !email ||
                !password ||
                password.length < 6 ||
                !validadeEmail(email)
              }
              id="sign-in"
            >
              Entrar
            </button>
            <span id="link" onClick={() => this.props.history.push("signup")}>
              Não tem conta? Cadastre-se agora
            </span>
          </div>
        </form>
      </>
    );
  }
}

export default withRouter(Login);
