import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { handleChange, fetchCep } from './redux/action'
import { onlyNumbers, phoneMask, cnpjMask, cepMask } from '../../../../../utils/normalize'
import services from '../../../../../services/api'


export default function MyPersonalData() {
  const dispatch = useDispatch()

  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])

  const { cpfCnpj, name, tradingName, street, zipcode, streetNumber,
    editData, neighborhood, city, state, email, phone, disabled } = useSelector(state => state.providerPersonalData)

  useEffect(() => {
    loadCities()
    loadStates()
  }, [city])

  const loadCities = async () => {
    const res = await services.api.get(services.config.endpoint + 'cities')
    await setCities(res.data)
  }

  const loadStates = async () => {
    const response = await services.api.get(services.config.endpoint + `states`);
    setStates(response.data)
  }


  const handleSetStates = async event => {
    const { value } = event.target
    dispatch(handleChange(value.toString().toUpperCase(), 'state'))

    const state = states.find(state => state.Initials === value)

    if (!state) return

    setStateCities(state.id)

  }

  const setStateCities = async stateId => {
    const response = await services.api.get(services.config.endpoint + `cities/by-states/${stateId}`);
    setCities(response.data)
  }


  return (
    <Row >
      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{ display: 'flex' }}>

        <Col lg={2} md={3} sm={4} xs={12}>
          <label>CNPJ</label>
          <input
            className='form-control foco-input'
            disabled
            type="text"
            value={cnpjMask(cpfCnpj)}
          />
        </Col>
        <Col lg={3} md={3} sm={12} xs={12}>
          <label>Razão Social:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(e.target.value, 'name'))}
            value={name}
          />
        </Col>
        <Col lg={3} md={3} sm={12} xs={12}>
          <label>Nome Fantasia:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(e.target.value, 'tradingName'))}
            value={tradingName}
          />
        </Col>
        <Col lg={2}>
          <label>E-mail:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type='text'
            disabled={!editData}
            onChange={e => dispatch(handleChange(e.target.value, 'email'))}
            value={email}
          />
        </Col>
        <Col lg={2}>
          <label>Telefone:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type='text'
            disabled={!editData}
            onChange={e => dispatch(handleChange(onlyNumbers(e.target.value), 'phone'))}
            value={phoneMask(phone)}
          />
        </Col>
      </div>

      <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{ display: 'flex' }}>
        <Col lg={2}>
          <label>CEP:</label>
          <input
            maxLength="9"
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(cepMask(e.target.value), 'zipcode'))}
            value={zipcode}
            onBlur={() => zipcode.length >= 8 && dispatch(fetchCep(zipcode))}
          />
        </Col>
        <Col lg={4} md={3} sm={12} xs={12}>
          <label>Endereço:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(e.target.value, 'street'))}
            value={street}
          />
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <label>Número:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(onlyNumbers(e.target.value), 'streetNumber'))}
            value={streetNumber}
          />
        </Col>
        <Col xs={12} sm={1} md={1} lg={2}>
          <label>Bairro:<span style={{ color: 'red' }}>*</span></label>
          <input
            className='form-control foco-input'
            type="text"
            disabled={!editData}
            onChange={e => dispatch(handleChange(e.target.value, 'neighborhood'))}
            value={neighborhood}
          />
        </Col>
        <Col xs={12} sm={1} md={1} lg={1}>
          <label>Estado:<span style={{ color: 'red' }}>*</span></label>
          <select className="form-control foco-input" disabled={zipcode.length === 9 && disabled || !editData} value={state} name={state} onChange={handleSetStates}>
            <option value="">Selecione</option>
            {
              states.map(e => {
                return (
                  <option key={e.id} value={e.Initials}>{e.Initials}</option>
                );
              })
            }
          </select>
        </Col>
        <Col xs={12} sm={1} md={1} lg={2}>
          <label>Cidade:<span style={{ color: 'red' }}>*</span></label>
          <select className="form-control foco-input" disabled={zipcode.length === 9 && disabled || !editData} value={city} name={city} onChange={e => dispatch(handleChange(e.target.value, 'city'))}>
            <option value="">Selecione</option>
            {
              cities.map(c => {
                return (
                  <option key={c.id} value={c.Name}>{c.Name}</option>
                );
              })
            }
          </select>
        </Col>
      </div>
    </Row >
  )
}