import React, { memo } from 'react'
import Button from "../../../components/customButton/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faStar } from "@fortawesome/free-solid-svg-icons";

const ListMaintances = props => {
    const { data } = props
    return (
        <>
            {data.map(v => {
                let color;
                do {
                    color = "#" + ((1 << 24) * Math.random() | 0).toString(16)
                } while (color === '#fcfcfc' || color === '#ffffff' || color === 'white');

                return (
                    <div key={v.id}>
                        <div className='cards-mobile' style={{ display: 'block' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }} key={v.id} onClick={() => props.showDetalhes(v)}>
                                <div style={{ marginRight: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', float: 'left' }}>
                                    <div id="div-circle" style={{ backgroundColor: `${color}` }}>
                                        <span id="span-circle"><strong>{v.Company.name.charAt([0])}</strong></span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span><strong>{v.Company.name}</strong></span>
                                    <span><strong>Placa:</strong> {v.licensePlate}</span>
                                    <span><strong>R$ {v.Payments?.Amount ? parseFloat(v.Payments?.Amount).toFixed(2).toString().replace('.', ',') : '00,00'} </strong></span>
                                </div>
                            </div>
                            {
                                v.evaluated ?
                                    <div style={{ marginLeft: '57px' }}>
                                        <Button id="botaoAvaliarOficina" style={{ backgroundColor: 'blue' }} onClick={() => props.showSurvey(v)}>
                                            <FontAwesomeIcon icon={faStar} aria-hidden="false" /> Ver Avaliação</Button>
                                    </div>
                                    :
                                    v.type === 'qc' ?
                                        <div style={{ marginLeft: '57px' }}>
                                            <Button id="botaoAvaliarOficina" style={{ backgroundColor: 'gray', opacity: '50%' }}>
                                                <FontAwesomeIcon icon={faStar} aria-hidden="false" /> Avaliação Indisponível</Button>
                                        </div>
                                        :
                                        <div style={{ marginLeft: '57px' }}>
                                            <Button id="botaoAvaliarOficina" style={{ backgroundColor: 'red' }} onClick={() => props.showSurvey(v)}>
                                                <FontAwesomeIcon icon={faEdit} aria-hidden="false" /> Avaliar Oficina</Button>
                                        </div>
                            }




                        </div>

                    </div>

                )
            })}
        </>
    )
}

export default memo(ListMaintances)

