import React, { useEffect, useState } from 'react';
import services from '../../../services/api';
import './styles.css';

import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useHistory } from 'react-router-dom';
import Carousel from 'react-images';

import WithOutImage from '../../../assets/img/sem-imagem.png'
import Maps from '../../../components/maps';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Header from './Header';
import CustomerEvaluation from './CustomerEvaluation';

import { phoneMask } from '../../../utils/normalize';
import { getCountersEvaluation } from '../../../utils/request'

export default function Details({ match: { params } }) {
  const [loading, setLoading] = useState(true);
  const [respError, setRespError] = useState(false);
  const [provider, setProvider] = useState({});
  const [rating, setRating] = useState({});
  const [carouselIndex, setCarouselIndex] = useState(0);

  // const history = useHistory();

  useEffect(() => {
    async function loadPage() {
      const { sourceId, id } = params;

      try {
        const { data } = await services.api.get(services.config.endpoint + `companies/get-data-provider/${sourceId}/${id}`);
        setProvider(data);
        await loadRatings(data.cpfCnpj, sourceId);
      } catch (err) {
        setRespError(true);
      };
      setLoading(false);
    };

    loadPage();
  }, []);

  async function loadRatings(cnpj, sourceId) {
    const { data } = await services.api.get(services.config.endpoint + `/satisfaction-survey/by-company-source/${cnpj}/${sourceId}`);
    const { payload } = await getCountersEvaluation(cnpj, sourceId);
    setRating({ ...data, ...payload });
  };
  // console.log(rating)

  const ProviderInfo = ({ icon, label, arrow, onClick = () => { } }) => (
    <div className="mobile-provider-info-container" onClick={onClick}>
      <div className="mobile-provider-info">
        <FontAwesomeIcon icon={icon} />
        <span>{label}</span>
      </div>
      {arrow &&
        <FontAwesomeIcon icon="chevron-right" id="mobile-provider-info-svg-arrow" />
      }
    </div>
  );

  const ErrorScreen = () => (
    <div className="mobile-details-error">
      <span>Não foi possível carregar os dados da empresa</span>
    </div>
  );

  //Formata o array para o componente do Carousel
  const providerFiles = provider.Files?.map(item => {
    return { src: item.file }
  });

  function formatValueAfterDot(value) {
    if (!value) return value;

    if (value.split('.')[1] <= 0) return value.split('.')[0]
    return value
  };

  const NoInfo = () => (
    <span style={{ color: 'silver', fontStyle: 'italic' }}>Não informado.</span>
  );

  function incrementCarouselIndex() {
    if (carouselIndex === (providerFiles.length - 1) || !providerFiles?.length) return;
    setCarouselIndex(carouselIndex + 1);
  };

  function decrementCarouselIndex() {
    if (carouselIndex === 0) return;
    setCarouselIndex(carouselIndex - 1);
  };

  return (
    respError ? <ErrorScreen /> :
      <>
        <LoadingSpinner loading={loading} icon="car" />
        <Header />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="mobile-details-container" id="overview">
            <header>
              <h6>{provider.tradingName}</h6>
              <div>
                <Rating
                  value={Number(provider.rating) || 0}
                  readOnly
                  name="Provider_Rating"
                  defaultValue={2}
                  size="small"
                />
                <span style={{ marginLeft: 5 }}>{provider.amountOfSatisfactionSurvey} Avaliações</span>
              </div>
            </header>
            <p style={{ marginTop: 20 }}><b>Informações do estabelecimento</b></p>

            <ProviderInfo
              icon="map"
              label={`Rua ${provider.street}, nº ${provider.streetNumber}
          , ${provider.neighborhood} - ${provider.city}, ${provider.state}`}
            />
            <ProviderInfo
              icon="tv"
              label={provider.site?.length ? <a
                href={`${provider.site?.includes('http') ? provider.site : `http://${provider.site}`} `}
                target="_blank"
                rel="noopener noreferrer"
                data-toggle="tooltip"
                data-placement="bottom"
                style={{ color: 'black', textDecoration: 'none' }}
              >Visite o site</a> : <NoInfo />}
              arrow
            />
            <ProviderInfo
              icon="phone"
              label={provider.Phones?.length ?
                phoneMask(`${provider.Phones[0].ddd}${provider.Phones[0].number}`)
                : <NoInfo />}
            />
            <ProviderInfo
              icon="clock"
              label={<>
                <b>Horário de funcionamento:</b> {provider.openAt && provider.closesAt ?
                  `${provider.openAt} - ${provider.closesAt}`
                  : <NoInfo />}
              </>}
            />

            <div className="mobile-details-map">
              {!loading &&
                <Maps
                  list={[{
                    latitude: provider.latitude,
                    longitude: provider.longitude,
                    tradingName: provider.tradingName
                  }]}
                  city={provider.city}
                  states={provider.state}
                />
              }
            </div>

            <div className="mobile-details-images-container">
              <div className="mobile-details-images">
                <div className="carousel-arrow-left" onClick={decrementCarouselIndex}>
                  <FontAwesomeIcon
                    icon="chevron-left"
                    color={`${carouselIndex === 0 ? 'silver' : 'black'}`}
                  />
                </div>
                <Carousel
                  views={providerFiles?.length ? providerFiles : [{ src: WithOutImage }]}
                  currentIndex={carouselIndex}
                  trackProps={{
                    onViewChange: index => setCarouselIndex(index)
                  }}
                />
                <div className="carousel-arrow-right" onClick={incrementCarouselIndex}>
                  <FontAwesomeIcon
                    icon="chevron-right"
                    color={`${carouselIndex === (providerFiles?.length - 1) || !providerFiles?.length ? 'silver' : 'black'}`}
                  />
                </div>
              </div>
              <div style={{ textAlign: 'center', fontSize: '13px', fontWeight: '500' }}>
                <span>{carouselIndex + 1} de {providerFiles?.length || 1}</span>
              </div>
            </div>

            <span id="details"><b>Detalhes</b></span>
            <div style={{ paddingLeft: '10px', paddingBottom: '15px' }}>
              {!provider.Segments?.length ?
                <span style={{ color: 'silver', fontSize: '13px', fontStyle: 'italic' }}>Nenhum segmento especificado.</span>
                :
                provider.Segments?.map((item, index) => (
                  <span
                    key={item.id}
                    style={{ marginRight: '5px', fontSize: '13px' }}
                  >{item.description}{index !== (provider.Segments?.length - 1) ? ',' : '.'}</span>
                ))}
            </div>

            <div id="mobile-details-titles">
              <FontAwesomeIcon icon="briefcase" />
              <span>SERVIÇOS REALIZADOS</span>
            </div>
            <div className="mobile-details-services">
              {
                !provider.Services?.length ?
                  <span style={{ color: 'silver', fontSize: '13px', fontStyle: 'italic' }}>Nenhum serviço especificado.</span>
                  :
                  provider.Services?.map(item => (
                    <span key={item.id}>• {item.description}</span>
                  ))}
            </div>

            <span id="rating"><b>Avaliações</b></span>
            <div className="mobile-details-rating-container">
              <div className="mobile-details-rating-header">
                <span id="rating-header">{!!provider.rating ? formatValueAfterDot(String(provider.rating)) : '0'}</span>
                <Rating
                  value={Number(provider.rating) || 0}
                  readOnly
                  name="Provider_Rating"
                  defaultValue={2}
                  size="small"
                />
                <span style={{ marginLeft: 5 }}>{provider.amountOfSatisfactionSurvey} Avaliações</span>
              </div>

              <div id="mobile-details-titles">
                <FontAwesomeIcon icon="poll-h" />
                <span>PONTUAÇÕES DOS NOSSOS CLIENTES</span>
              </div>
              <div className="mobile-details-score">
                <section>
                  <div>
                    <FontAwesomeIcon icon="thumbs-up" />
                    <span>Indicaria</span>
                  </div>
                  <main>
                    <span
                      style={{ color: 'green', fontSize: '12px', fontWeight: 'bold' }}
                    >{String(rating.ratingRecommendation || 0.00).replace('.', ',')}%</span>
                  </main>
                </section>
                <section>
                  <div>
                    <FontAwesomeIcon icon="dollar-sign" />
                    <span style={{ marginLeft: '5px' }}>Preços</span>
                  </div>
                  <main>
                    <Rating
                      value={Number(rating.ratingPrice) || 0}
                      readOnly
                      name="ratingPrice"
                      defaultValue={2}
                      size="small"
                    />
                  </main>
                </section>
                <section>
                  <div>
                    <FontAwesomeIcon icon="wrench" />
                    <span>Produtos/Serviços</span>
                  </div>
                  <main>
                    <Rating
                      value={Number(rating.ratingProductService) || 0}
                      readOnly
                      name="ratingProductService"
                      defaultValue={2}
                      size="small"
                    />
                  </main>
                </section>
              </div>

              <div id="mobile-details-titles">
                <span>AVALIAÇÕES DOS CLIENTES</span>
              </div>
              <CustomerEvaluation rating={rating} />
            </div>

          </div>
        </div>
      </>
  );
};