import React, { Component } from 'react'
import Logo from '../../assets/img/sobre-rodas.png'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import NewPassword from './NewPassword'
import LoginFooter from '../../components/LoginFooter'
import './index.css'

export default class index extends Component {

    state = {
        option: 0
    }

    componentDidMount() {
        this.checkURL()
    }

    checkURL() {

        const params = new URLSearchParams(this.props.location.search) // classe para pegar queryString da URL
        let userID = params.get('id')
        let time = params.get('time')

        if (userID && time) {
            this.setState({ option: 2 })
        }
    }

    renderOption(option) {
        switch (option) {
            case 0:
                return <Login handleOption={this.handleOption} history={this.props.history} />
            case 1:
                return <ForgotPassword handleOption={this.handleOption} history={this.props.history} />
            case 2:
                return <NewPassword handleOption={this.handleOption} history={this.props.history} location={this.props.location} />
            default:
                return <Login handleOption={this.handleOption} history={this.props.history} />
        }
    }

    handleOption = option => {
        this.setState({ option })
    }

    render() {
        const { option } = this.state
        return (
            <>
                <div className="Login-container">
                    <div className="Login">
                        <div className="Login-card">
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                                <img className="img" src={Logo} alt="Sobre Rodas" />
                            </div>
                            {this.renderOption(option)}
                        </div>
                    </div>
                </div>
                <LoginFooter />
            </>
        )
    }
}