import services from '../../../../../../../services/api'

export const handleChange = (value, type) => {
    return {
        type: 'HANDLE_CHANGE',
        payload: value,
        payloadType: type
    }
}

export const getCompanySatisfactionSurveys = async (sourceId, companyId) => {

    try {
        
        const satisfactionSurveys = await services.api.get(services.config.endpoint + `satisfaction-survey/by-source/${sourceId}/${companyId}`)
        return {
            type: 'GET_EVALUATED',
            payload: satisfactionSurveys.data,
            payloadSourceId: sourceId
        }
    } catch(err) {
        return {
            type: 'CATCH_EVALUATED',
            payload: [],
        }
    }
}

export const clearState = () => {
    return {
        type: 'CLEAR_STATE'
    }
}