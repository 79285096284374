import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faWrench, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import BreadCrumb from '../../../../components/breadCrumb/BreadCrumb'
import '../../../../desktop.css'
import { getVehicles, countMaintances, countWorkshop } from '../../../../../utils/request'

class Dashboard extends Component {

  state = {
    qtdManutencao: 0,
    qtdOficina: 0,
    vehicles: [],
    loading: true
  };

  componentDidMount = async () => {
    await this.loadVehicles()
    this.countMaintancesAndWorkshop()
  }

  loadVehicles = async () => {
    const response = await getVehicles(localStorage.getItem('ID_USUARIO'))
    await this.setState({ vehicles: response.payload })
  }

  countMaintancesAndWorkshop = async () => {
    const { vehicles } = this.state
    let qtdMaintances = 0
    let qtdWorkshop = 0
    for (let i = 0; i < vehicles.length; i++) {
      const maintances = await countMaintances(vehicles[i].licensePlate)
      const workshop = await countWorkshop(vehicles[i].licensePlate)
      qtdMaintances = qtdMaintances + maintances.payload
      qtdWorkshop = qtdWorkshop + workshop.payload
    }
    this.setState({ qtdManutencao: qtdMaintances, qtdOficina: qtdWorkshop, loading: false })
  }

  render() {
    const { qtdManutencao, qtdOficina, vehicles, loading } = this.state;

    return (
      <div>
        <BreadCrumb data={['Início']}
          path={['dashboard']} />
        <Row>

          {/* MINHAS MANUTENÇÕES */}
          <Col md={4} >
            <Link id="card" to="maintances">
              <Button id='btn-card' className="painelCardManutencoes" >
                <Col md={2}>
                  <FontAwesomeIcon
                    icon={faWrench}
                    className="iconedashboard"
                    aria-hidden="false"
                  />
                </Col>
                <Col md={10} style={{ color: 'white' }}>
                  <h3><strong>{loading ? <span className="fa fa-spinner fa-pulse fa-1x" /> : qtdManutencao}</strong></h3>
                  <p style={{ fontSize: '15px' }}>Manutenções</p>
                </Col>
              </Button>
            </Link>
          </Col>

          {/* OFICINAS */}
          <Col md={4}>
            <Button id='btn-card' className="painelCardOficinas">
              <Col md={2} >
                <FontAwesomeIcon
                  icon={faToolbox}
                  className="iconedashboard"
                  aria-hidden="false"
                />
              </Col>
              <Col md={10} style={{ color: 'white' }}>
                <h3><strong>{loading ? <span className="fa fa-spinner fa-pulse fa-1x" /> : qtdOficina}</strong></h3>
                <p style={{ fontSize: '15px' }}>Oficinas</p>
              </Col>
            </Button>
          </Col>

          {/* VEICULOS */}
          <Col md={4} >
            <Link id="card" to="vehicles">
              <Button id='btn-card' className="painelCardVeiculos" >
                <Col md={2}>
                  <FontAwesomeIcon
                    icon={faCar}
                    className="iconedashboard"
                    aria-hidden="false"
                  />
                </Col>
                <Col md={10} style={{ color: 'white' }}>
                  <h3><strong>{vehicles.length ?? <span className="fa fa-spinner fa-pulse fa-1x" />}</strong></h3>
                  <p style={{ fontSize: '15px' }}>Veículos</p>
                </Col>
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
