import React from 'react'

const ReduxFormInput = (props) => (
  <div >
    <input className="form-control foco-input" autoComplete="new-password" {...props} {...props.input}  type={props.type} disabled={props.disabled}  />
    {props.meta.touched && props.meta.error && <span style={{
      color: 'red', 
      textAlign: 'left', 
      display: 'block',
      paddingLeft: '10px',
      fontSize: '12px'
      }}>{props.meta.error}</span>}
  </div>
)

export default ReduxFormInput