import React, { useState } from 'react'
import SearchLocationModal from '../../components/search-location-modal'
import './styles.css'
import { useSelector, useDispatch } from 'react-redux'
import { handleChange, handleFilters, clearFilters } from '../../../store/actions/provider'
import { Modal } from 'react-bootstrap'
import Filter from './Filter'
import { toastr } from 'react-redux-toastr'

export default function Location() {
  const [openLocationModal, setOpenLocationModal] = useState(false)
  const dispatch = useDispatch()
  const { provider } = useSelector(state => state)
  const { selectedCity, companies, filters, openFilterModal } = provider

  // useEffect(() => {
  //   if ((filters.services.length > 0 || filters.branchs.length > 0 || filters.segments.length > 0) && (companiesFiltered.length === 0 || more)) {
  //     setOpenFilterModal(true)
  //   }
  // }, [])

  const handleSelectedCity = city => {
    dispatch({ type: 'HANDLE_CHANGE_CITY', payload: city })
    setOpenLocationModal(false)
  }

  const handleApplyFilters = () => {

    if (filters.branchs.length === 0 && filters.segments.length === 0 && filters.services.length === 0) {
      return toastr.warning('Por favor, selecione ao menos um filtro para prosseguir.')
    }

    dispatch(handleChange(false, 'openFilterModal'))
    dispatch(handleFilters(companies, filters))
  }

  const handleClearFilters = () => { 
    dispatch(clearFilters())
  }


  return (
    <div className='container-location-companies'>
      <div className='container-location-city' onClick={() => setOpenLocationModal(true)}>
        {selectedCity?.Name ? (
          <h6>Você está em: {selectedCity.Name}, {selectedCity?.States.Initials}</h6>
        )
          : (
            <h6>Selecione a cidade</h6>
          )}
      </div>
      <div className='container-location-filter-button' onClick={() => dispatch(handleChange(true, 'openFilterModal'))}>
        <h6>Filtros</h6>
      </div>


      {openLocationModal &&
        <SearchLocationModal
          show={openLocationModal}
          onHide={() => setOpenLocationModal(false)}
          onCancel={() => setOpenLocationModal(false)}
          onSelectCity={handleSelectedCity}
        />
      }

      {openFilterModal &&
        <Modal
          show={openFilterModal}
          onHide={() => dispatch(handleChange(false, 'openFilterModal'))}
          onCancel={() => dispatch(handleChange(false, 'openFilterModal'))}
          dialogClassName="tamanho-modal"
        >
          <Modal.Title className='modal-filters-title' >
            <strong><h6>Filtros</h6></strong>
          </Modal.Title>
          <Modal.Body>
            <Filter />
          </Modal.Body>
          <Modal.Footer>
            <div className='modal-filters-footer'>
              <button className='btn btn-primary' onClick={handleClearFilters}>
                Limpar Filtros
              </button>

              <button className='btn btn-success' onClick={handleApplyFilters} >
                Aplicar filtros
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      }
    </div>
  )
}