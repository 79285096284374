import React, { Component } from "react";
import Header from "../../components/header/index"
import Footer from "../../components/footer/index"
import Rating from '@material-ui/lab/Rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faDesktop, faPollH, faDollarSign, faWrench, faCar, faToolbox } from "@fortawesome/free-solid-svg-icons";
import { faMap, faClock, faThumbsUp, faStar } from '@fortawesome/free-regular-svg-icons'
import { getCountersEvaluation } from '../../../utils/request'
import Maps from '../../../components/maps'
import Imagem from '../../../assets/img/sem-imagem.png'
import Carousel, { Modal, ModalGateway } from 'react-images';
import CustomerEvaluation from "./CustomerEvaluation";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { handleChange, changeProvider } from '../../../store/actions/provider'
import services from '../../../services/api'

class DetailsCompany extends Component {

    constructor() {
        super();
        this.state = {
            data: [],
            company_id: 0,
            telefone: '',
            modalIsOpen: false,
            display: 'block',
            images: [],
            ratings: [],
            company: [],

            allEvaluation: [],
            indexImage: 0,
            list: [],

            //estrelas
            five: 0,
            four: 0,
            three: 0,
            two: 0,
            one: 0,
            progressFive: 0,
            progressFour: 0,
            progressThree: 0,
            progressTwo: 0,
            progressOne: 0,

            counterMore: 5
        }
    }

    componentDidMount() {
        this.loadInfoCompany();
    }

    componentWillMount() {
        this.loadInfoCompany();
        this.props.changeProvider()
    }

    loadInfoCompany = async () => {
        const response = await services.api.get(services.config.endpoint + `/companies/get-data-provider/${this.props.match.params.sourceId}/${this.props.match.params.id}`)
        await this.setState({ company: response.data })
        console.log(this.state.company.site)
        if (this.state.company) {
            // carregar as imagens e espandir
            const imgs = []

            const { Files } = this.state.company

            if (Files.length !== 0) {
                let files = Files.map(x => x.file);
                for (let i = 0; i < files.length; i++) {
                    imgs.push({ src: files[i] })
                }
            }

            this.setState({ images: imgs })
            const { Phones } = this.state.company
            if (Phones.length > 0) {
                let tel = Phones[0].ddd + Phones[0].number;
                if (tel.length <= 10) {
                    tel = tel.replace(/(\d{0})(\d)/, '$1($2').replace(/(\d{2})(\d)/, '$1)$2').replace(/(\d{4})(\d)/, '$1-$2');
                }
                else if (tel.length === 11) {
                    tel = tel.replace(/(\d{0})(\d)/, '$1($2').replace(/(\d{2})(\d)/, '$1)$2').replace(/(\d{5})(\d)/, '$1-$2');
                }
                this.setState({ telefone: tel })
            }

            const resEvaluation = await services.api.get(services.config.endpoint + `satisfaction-survey/by-company-source/${this.state.company.cpfCnpj}/${this.props.match.params.sourceId}`)
            await this.setState({
                amountOfSatisfactionSurvey: resEvaluation.data.amountOfSatisfactionSurvey,
                five: resEvaluation.data.five,
                four: resEvaluation.data.four,
                three: resEvaluation.data.three,
                two: resEvaluation.data.two,
                one: resEvaluation.data.one,
                progressFive: resEvaluation.data.progressFive,
                progressFour: resEvaluation.data.progressFour,
                progressThree: resEvaluation.data.progressThree,
                progressTwo: resEvaluation.data.progressTwo,
                progressOne: resEvaluation.data.progressOne,
                allEvaluation: resEvaluation.data.avaliacoes,
            })

            //mostrar apenas 5 avaliações
            // let avaliacoes = resEvaluation.data.avaliacoes.slice(0, 5)

            await this.props.handleChange(resEvaluation.data.avaliacoes, 'companyEvaluation')
            await this.props.handleChange(resEvaluation.data.avaliacoes, 'currencyEvaluation')

            const resCounters = await getCountersEvaluation(this.state.company.cpfCnpj, this.props.match.params.sourceId)
            this.setState({ ratings: resCounters.payload })
        }

    }

    //Ação para apresentar +5 avaliações
    // btnLoadMore = async () => {
    //     let counter = await this.state.counterMore + 5
    //     await this.setState({ counterMore: counter })
    //     let avaliacoes = this.state.allEvaluation.slice(0, this.state.counterMore)
    //     await this.props.handleChange(avaliacoes, 'companyEvaluation')
    //     await this.props.handleChange(avaliacoes, 'currencyEvaluation')
    // }

    showModal(index) {
        if (this.state.images) {
            this.setState({ modalIsOpen: true, indexImage: index })
        }
    }

    render() {
        const { Excelente, MuitoBom, Razoavel, Ruim, Horrivel, currencyEvaluation, companyEvaluation } = this.props.provider
        const { telefone, modalIsOpen, ratings, images, progressFive, progressFour, progressThree, progressTwo, progressOne } = this.state
        const { Services, Segments, tradingName, rating, street, streetNumber,
            neighborhood, city, state, site, openAt, closesAt, Branches, amountOfSatisfactionSurvey } = this.state.company
        return (
            <>
                <div>
                    <Header />
                    <div className="card details-company-header">
                        <h3>{tradingName}</h3>
                        <div id="avali">
                            {
                                amountOfSatisfactionSurvey === 0 ?
                                    <FontAwesomeIcon icon={faStar} style={{ color: '#fec63d' }} />
                                    :
                                    <Rating value={rating ? rating : 0} readOnly
                                        onChange={(e, newValue) => this.handleChange(e, newValue)}
                                        name="Average_Ratings" defaultValue={2} size="small" />
                            }


                            <label className="details-company-label">{amountOfSatisfactionSurvey ? amountOfSatisfactionSurvey + " Avaliações" : "Novo"} </label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faMap} aria-hidden="false" />
                            <label className="details-company-label">{street}</label>
                            <label className="details-company-label">nº{streetNumber}</label>
                            <label className="details-company-label">{neighborhood} - {city}, {state}</label>

                            <label className="details-company-label">|</label>
                            <FontAwesomeIcon icon={faPhone} aria-hidden="false" />
                            <label className="details-company-label">+55 {telefone}</label>

                            <label className="details-company-label">|</label>
                            <FontAwesomeIcon icon={faDesktop} aria-hidden="false" />
                            <a href={site ? `${site.includes('http') ? site : `http://${site}`} ` : '#'} target={site ? "_blank" : ""} rel="noopener noreferrer" data-toggle="tooltip" data-placement="bottom" title={site ? "Click para acessar o site da empresa" : "Ops, desculpe, o prestador não possui site cadastrado."} className="details-company-label" id='link-site'>Site</a>

                            <label className="details-company-label">|</label>
                            <FontAwesomeIcon icon={faClock} aria-hidden="false" />
                            <label className="details-company-label">Horario de funcionamento:</label>
                            <label className="details-company-label">{openAt ? openAt.substring(0, 5) : '00:00'} - {closesAt ? closesAt.substring(0, 5) : "00:00"}</label>
                        </div>
                    </div>


                    <div className="grid-img">
                        <div className="card padding-info-company">
                            <img title={images.length ? "Clique aqui para ampliar" : "Sem fotos para visualização"} src={images[0] ? images[0].src : Imagem} alt="imagemOficina" className="img-thumbnail" style={{ height: '350px' }} onClick={() => images[0] ? this.showModal(0) : ""} />
                        </div>

                        <div className="card padding-info-company">
                            <img title={images.length ? "Clique aqui para ampliar" : "Sem fotos para visualização"} src={images[1] ? images[1].src : Imagem} alt="imagemOficina" className="img-thumbnail" style={{ height: '350px' }} onClick={() => images[1] ? this.showModal(1) : ""} />
                        </div>

                        <div className="card padding-info-company">
                            <img title={images.length ? "Clique aqui para ampliar" : "Sem fotos para visualização"} src={images[2] ? images[2].src : Imagem} alt="imagemOficina" className="img-thumbnail imagem-pequena" onClick={() => images[2] ? this.showModal(2) : ""} />
                            <img title={images.length ? "Clique aqui para ampliar" : "Sem fotos para visualização"} src={images[3] ? images[3].src : Imagem} alt="imagemOficina" className="img-thumbnail imagem-pequena" onClick={() => images[3] ? this.showModal(3) : ""} />
                            <img title={images.length ? "Clique aqui para ampliar" : "Sem fotos para visualização"} src={images[4] ? images[4].src : Imagem} alt="imagemOficina" className="img-thumbnail imagem-pequena" onClick={() => images[4] ? this.showModal(4) : ""} />
                        </div>
                    </div>
                    <div className="grid-info-company">
                        <div className="card padding-info-company">
                            <div>
                                <label><strong>Pontuação</strong></label>
                                <div id="conteudoLinha">
                                    <h4><strong>{amountOfSatisfactionSurvey === 0 ? 0 : rating ? rating : 0}</strong></h4>

                                    {amountOfSatisfactionSurvey === 0 ?
                                        <FontAwesomeIcon icon={faStar} style={{ color: '#fec63d' }} />
                                        :
                                        <Rating value={rating ? rating : 0} readOnly
                                            onChange={(e, newValue) => this.handleChange(e, newValue)}
                                            name="Average_Ratings" defaultValue={2} size="small" />

                                    }
                                    <label className="details-company-label">{amountOfSatisfactionSurvey ? amountOfSatisfactionSurvey + " Avaliações" : "Novo"}</label>
                                </div>
                                <hr></hr>
                                <FontAwesomeIcon icon={faPollH} aria-hidden="false" />
                                <label className="details-company-label"><span>Pontuações dos nossos clientes</span></label>
                                <div className="space-info-company">
                                    <label><FontAwesomeIcon icon={faThumbsUp} aria-hidden="false" className="details-company-label" />Indicaria</label>
                                    <label className="indicaria-info-company">{parseInt(ratings.ratingRecommendation) || 0}% </label>
                                </div>
                                <div className="space-info-company">
                                    <label><FontAwesomeIcon icon={faDollarSign} aria-hidden="false" className="details-company-label" />Preço</label>
                                    <Rating value={ratings.ratingPrice || 0} readOnly
                                        name="ratingPrice" size="medium" />
                                </div>
                                <div className="space-info-company">
                                    <label><FontAwesomeIcon icon={faWrench} aria-hidden="false" className="details-company-label" />Produtos/Serviços</label>
                                    <Rating value={ratings.ratingProductService || 0} readOnly
                                        name="ratingProductService" size="medium" />
                                </div>
                            </div>
                        </div>

                        <div className="card padding-info-company">
                            <div>
                                <label><strong>Detalhes</strong></label>
                                <div>
                                    <FontAwesomeIcon icon={faCar} aria-hidden="false" className="details-company-label" />
                                    <label><strong>Veiculos atendidos</strong></label>
                                </div>
                                {Segments ? Segments.length > 0 ?
                                    Segments.map(x => {
                                        return (
                                            <label className="details-company-label" name={this.state.valorSegmento} key={x.id} value={x.id}>{x.description} </label>
                                        )
                                    }) : "(Informações não disponíveis)"
                                    : "(Informações não disponíveis)"
                                }
                                <div>
                                    <FontAwesomeIcon icon={faToolbox} aria-hidden="false" className="details-company-label" />
                                    <label><strong>Serviços realizados</strong></label>
                                    <div className="vertical-line" style={{ display: Services ? Services.length <= 3 ? 'none' : 'block' : 'none' }}></div>

                                    <div className={Services ? Services.length > 0 ? "list-services" : "" : ""}>
                                        {
                                            Branches ? Branches.description === "Autopeças" ? "Venda de Peças" :
                                                !Services.length ?
                                                    "(Informações não disponíveis)" :
                                                    Services.map(x => {
                                                        return (
                                                            <label className="details-company-label" name={this.state.valorServiceType} key={x.id} value={x.id}>{x.description} </label>
                                                        )
                                                    })
                                                : "(Informações não disponíveis)"
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card padding-info-company">
                            <div style={{ height: '268px', marginBottom: '34px' }}>
                                <label><strong>Localização</strong></label>
                                {
                                    this.state.company && this.state.company.city && this.state.company.state ?
                                        <Maps list={[this.state.company]} city={this.state.company.city} states={this.state.company.state} />
                                        :
                                        "Sem localização para ser exibida"
                                }
                            </div>
                        </div>
                    </div>

                    <div className="grid-avaliacoes">
                        <div className="card padding-info-company-evaluation">
                            <h3>Avaliações      ({amountOfSatisfactionSurvey || 0})</h3>
                            <hr></hr>
                            <label><strong>Avaliações dos clientes</strong></label>
                            <CustomerEvaluation check="Excelente" checked={Excelente} filtro="Excelente" estrelas={5} width={progressFive + "%"} porcentagem={progressFive + "% Completo"} evaluationQtd={this.state.five} />
                            <CustomerEvaluation check="MuitoBom" checked={MuitoBom} filtro="Muito bom" estrelas={4} width={progressFour + "%"} porcentagem={progressFour + "% Completo"} evaluationQtd={this.state.four} />
                            <CustomerEvaluation check="Razoavel" checked={Razoavel} filtro="Razoável" estrelas={3} width={progressThree + "%"} porcentagem={progressThree + "% Completo"} evaluationQtd={this.state.three} />
                            <CustomerEvaluation check="Ruim" checked={Ruim} filtro="Ruim" estrelas={2} width={progressTwo + "%"} porcentagem={progressTwo + "% Completo"} evaluationQtd={this.state.two} />
                            <CustomerEvaluation check="Horrivel" checked={Horrivel} filtro="Horrível" estrelas={1} width={progressOne + "%"} porcentagem={progressOne + "% Completo"} evaluationQtd={this.state.one} />

                            <hr></hr>
                            {
                                companyEvaluation.length ?
                                    currencyEvaluation.length ?
                                        currencyEvaluation.map(a => {
                                            return (
                                                <div id="linha-bottom">
                                                    <div id="conteudoLinha">
                                                        <h4>{a.Name ? a.Name : "Cliente não informado "}</h4>
                                                        <h4 className="details-company-label"> - {a.Average_Ratings || 0}</h4>
                                                        <Rating readOnly value={a.Average_Ratings}
                                                            onChange={(e, newValue) => this.handleChange(e, newValue)}
                                                            name="Average_Ratings" defaultValue={5} size="medium" />
                                                        <label>Avaliado em {a.Date_Evaluation || "Data não registrada"}</label>
                                                    </div>
                                                    <div className="col-md-4 alinhamento-avaliacao">
                                                        <div className="space-info-company">
                                                            <label><FontAwesomeIcon icon={faThumbsUp} aria-hidden="false" className="details-company-label" />Indicaria</label>
                                                            <label className="indicaria-info-company" style={a.Would_Indicate ? { color: "#008000b0" } : { color: "red" }}>{a.Would_Indicate ? "Sim" : "Não"} </label>
                                                        </div>
                                                        <div className="space-info-company">
                                                            <label><FontAwesomeIcon icon={faDollarSign} aria-hidden="false" className="details-company-label" />Preço</label>
                                                            <Rating value={a.Rating_Price} readOnly
                                                                onChange={(e, newValue) => this.handleChange(e, newValue)}
                                                                name="Rating_Price" defaultValue={5} size="medium" />
                                                        </div>
                                                        <div className="space-info-company">
                                                            <label><FontAwesomeIcon icon={faWrench} aria-hidden="false" className="details-company-label" />Produtos/Serviços</label>
                                                            <Rating value={a.Rating_Product_Service} readOnly
                                                                onChange={(e, newValue) => this.handleChange(e, newValue)}
                                                                name="Rating_Product_Service" size="medium" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 alinhamento-avaliacao">
                                                        <p className="comentario-avaliacao">{a.Comment || ""}</p>
                                                        <label>Data da visita em {a.Date_Sale || "Data não registrada"}</label>

                                                    </div>
                                                </div>
                                                // : ""
                                            )
                                        })
                                        :
                                        "(Nenhuma avaliação disponível)"
                                    : "(Nenhuma avaliação disponível)"
                            }
                        </div>
                    </div>
                    <ModalGateway>
                        {modalIsOpen ? (
                            <Modal onClose={() => this.setState({ modalIsOpen: !this.state.modalIsOpen })}>
                                <Carousel views={this.state.images} currentIndex={this.state.indexImage} />
                            </Modal>
                        ) : null}
                    </ModalGateway>

                </div>

                <Footer />
            </>
        )
    }
}

const mapStateToProps = state => ({
    provider: state.provider
})
const mapDispatchToProps = dispatch => bindActionCreators({ handleChange, changeProvider }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(DetailsCompany)
