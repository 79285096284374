import React, { Component } from "react";
import Menu from "../components/menu/Menu";
import { withRouter } from "react-router-dom";

class Client extends Component {

  componentDidMount() {
    if (
      !localStorage.getItem('ID_USUARIO') ||
      localStorage.getItem('ID_USUARIO') === '' ||
      !localStorage.getItem('NOME_USUARIO') ||
      localStorage.getItem('NOME_USUARIO') === ''
      ) {
        this.props.history.push('/client/login')
      }
  }

  render() {
    return (
      <div className="wrapper">
        <Menu />
      </div>
    );
  }
}

export default withRouter(Client);
