import services from '../../services/api'
import { toastr } from 'react-redux-toastr'

export const handleChange = (value, type) => {
  return {
    type: 'HANDLE_CHANGE',
    payload: value,
    payloadType: type,
    show: value ? true : false
  }
}

export const getCompaniesByCity = () => (dispatch, getState) => {
  const { selectedCity } = getState().provider

  if (!selectedCity?.Name) return dispatch({ type: 'LOAD_COMPANIES_BY_CITY_FAILURE' })

  const { Name: city, States } = selectedCity

  const state = States.Initials

  try {
    dispatch(tryGetCompaniesByCity(city, state))
  } catch (err) {
    dispatch(loadCompaniesByCityFailure(err))
  }
}

const tryGetCompaniesByCity = (city, state) => async dispatch => {
  dispatch({ type: 'LOAD_COMPANIES_BY_CITY_STARTED' })

  const res = await services.api.get(services.config.endpoint + `providers/by-city/${city}/${state}`)

  dispatch({ type: 'HANDLE_CHANGE', payload: res.data.length, payloadType: 'totalCount' })

  dispatch(loadCompaniesByCitySuccess(res.data))
}

const loadCompaniesByCitySuccess = data => async (dispatch, getState) => {

  await dispatch(handleShowMap(data))

  await dispatch({
    type: 'LOAD_COMPANIES_BY_CITY_SUCCESS',
    payload: data
  })

  const { companies, filters } = getState().provider

  dispatch(handleFilters(companies, filters))

}

const handleShowMap = data => dispatch => {
  //Verifica se existe latitude e longitude para exibir mapa.
  let objAddress = []
  const obj = []
  for (let i = 0; i < data.length; i++) {
    objAddress =
    {
      Latitude: parseFloat(data[i].latitude),
      Longitude: parseFloat(data[i].longitude)
    }
    obj.push(objAddress)
  }

  dispatch({
    type: 'HANDLE_CHANGE',
    payload: obj.length > 0 ? true : false,
    payloadType: 'maps'
  })

}

const loadCompaniesByCityFailure = erro => {
  toastr.warning('Ocorreu um erro ao buscar prestadores. Por favor, tente novamente')

  return { type: 'LOAD_COMPANIES_BY_CITY_FAILURE' }
}

export const handleFilters = (companies, filters) => async (dispatch, getState) => {
  const { more } = getState().provider

  let companiesFiltered = companies.filter(c => {
    const { Segments, Branches, Services } = c
    let contains = false

    const servicesName = Services?.map(service => service.description)
    const segmentsName = Segments?.map(segment => segment.description)
    const branchesId = Branches?.id

    let filterServicesName = filters.services?.map(service => service.description)
    let filterSegmentsName = filters.segments?.map(segment => segment.description)
    let filterBranchesId = filters.branchs?.map(branch => branch.id)

    if (filterServicesName.length > 0) {
      for (let serviceFilter of filterServicesName) {
        if (servicesName.includes(serviceFilter)) {
          contains = true
        }
      }

      if (!contains) return false
      contains = false
    }

    if (filterSegmentsName.length > 0) {
      for (let segmentFilter of filterSegmentsName) {
        if (segmentsName.includes(segmentFilter)) {
          contains = true
        }
      }

      if (!contains) return false
      contains = false
    }

    if (filterBranchesId.length > 0) {
      for (let branchId of filterBranchesId) {
        if (branchesId === branchId) {
          contains = true
        }
      }

      if (!contains) return false
    }

    return true
  })

  if ((filters.services.length > 0 || filters.branchs.length > 0 || filters.segments.length > 0) && companiesFiltered.length === 0) {
    toastr.warning('Ops, infelizmente não encontramos prestadores, com os filtros selecionados por favor, altere os filtros e realize a pesquisa novamente.')
    return dispatch({ type: 'HANDLE_FILTERS_FAILURE' })

  } else if (more) {

    dispatch({ type: 'HANDLE_CHANGE', payload: true, payloadType: 'openFilterModal' })
    dispatch({ type: 'HANDLE_CHANGE', payload: false, payloadType: 'more' })
  }

  dispatch({
    type: 'FINISH_FILTER_COMPANIES',
    payload: {
      companies: companiesFiltered,
      filters: filters,
    },
  })
}

export const addFilters = (object, type, data) => {
  let filters = data

  filters[type].push(object)

  return { type: 'HANDLE_CHANGE_FILTERS', payload: filters, payloadType: 'filters' }
}

export const removeFilter = (object, type, data) => {

  let filters = data
  filters[type] = filters[type].filter(c => c.id !== object.id)

  return { type: 'HANDLE_CHANGE_FILTERS', payload: filters, payloadType: 'filters', show: filters.length ? true : false }
}

export const clearFilters = () => ({ type: 'CLEAR_FILTERS' })

export const openMap = (value, type) => {
  return {
    type: 'OPEN_MAP',
    payload: !value,
    payloadType: type
  }
}

export const changeProvider = () => {
  return {
    type: 'CHANGE_PROVIDER'
  }
}